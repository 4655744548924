import type { PropsWithChildren, ReactNode } from 'react';
import { Box, CapsuleTooltip } from '@meterup/metric';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import React from 'react';

import { colors, styled } from '../stitches';

const grayCircle = `url("data:image/svg+xml,%3Csvg xmlns=\\"http://www.w3.org/2000/svg\\" width=\\"4\\" height=\\"1\\"%3E%3Ccircle r=\\"0.5\\" cx=\\"0.5\\" cy=\\"0.5\\" fill=\\"%23818394\\"/%3E%3C/svg%3E")`;

const StyledBox = styled(Box, {
  backgroundImage: grayCircle,
  backgroundPosition: '0% 100%',
  backgroundRepeat: 'repeat-x',
});

export const Tooltip = ({
  align,
  side,
  sideOffset,
  showHint,
  children,
  content,
}: PropsWithChildren<{
  content: ReactNode;
  showHint?: Boolean;
  align?: 'start' | 'end' | 'center';
  side?: 'top' | 'right' | 'bottom' | 'left';
  sideOffset?: number;
}>) => (
  <TooltipPrimitive.Root delayDuration={200}>
    <TooltipPrimitive.Trigger>
      {showHint ? <StyledBox>{children}</StyledBox> : <Box>{children}</Box>}
    </TooltipPrimitive.Trigger>
    <TooltipPrimitive.Content
      side={side || 'top'}
      align={align || 'center'}
      sideOffset={sideOffset || 0}
      asChild
    >
      <CapsuleTooltip
        style={{
          color: colors['gray-700'].toString(),
          padding: '8px',
          maxWidth: 200,
        }}
      >
        {content}
      </CapsuleTooltip>
    </TooltipPrimitive.Content>
  </TooltipPrimitive.Root>
);
