import type { BitMatrix } from 'qrcode';
import type { SVGAttributes } from 'react';
import QRCode from 'qrcode';
import React from 'react';

const FINDER_PATTERN_SIZE = 7;

function getSymbolSize(version: number) {
  if (!version) throw new Error('"version" cannot be null or undefined');
  if (version < 1 || version > 40) throw new Error('"version" should be in range from 1 to 40');
  return version * 4 + 17;
}

function getPositions(version: number): [number, number, string][] {
  const size = getSymbolSize(version);

  return [
    // top-left
    [0, 0, 'tl'],
    // top-right
    [size - FINDER_PATTERN_SIZE, 0, 'tr'],
    // bottom-left
    [0, size - FINDER_PATTERN_SIZE, 'bl'],
  ];
}

function getFinderPatternCells(matrix: BitMatrix, version: number) {
  const { size } = matrix;
  const pos = getPositions(version);

  const data = new Uint8Array(size * size);

  for (let i = 0; i < pos.length; i += 1) {
    const row = pos[i][0];
    const col = pos[i][1];

    for (let r = -1; r <= 7; r += 1) {
      // eslint-disable-next-line no-continue
      if (row + r <= -1 || size <= row + r) continue;

      for (let c = -1; c <= 7; c += 1) {
        // eslint-disable-next-line no-continue
        if (col + c <= -1 || size <= col + c) continue;

        const index = (row + r) * size + (col + c);

        if (
          (r >= 0 && r <= 6 && (c === 0 || c === 6)) ||
          (c >= 0 && c <= 6 && (r === 0 || r === 6)) ||
          (r >= 2 && r <= 4 && c >= 2 && c <= 4)
        ) {
          data[index] = 1;
        } else {
          data[index] = 0;
        }
      }
    }
  }

  return data;
}

function arcParameter(
  rx: number,
  ry: number,
  xAxisRotation: number,
  largeArcFlag: number,
  sweepFlag: number,
  x: number,
  y: number,
) {
  return [rx, ',', ry, ' ', xAxisRotation, ' ', largeArcFlag, ',', sweepFlag, ' ', x, ',', y].join(
    '',
  );
}

function rectWithIndependentCorners(
  x: number,
  y: number,
  width: number,
  height: number,
  tr: number,
  br: number,
  bl: number,
  tl: number,
) {
  const data = [];

  data.push(`M${x + width / 2},${y}`);
  data.push(`H${x + width - tr}`);

  if (tr > 0) {
    data.push(`A${arcParameter(tr, tr, 0, 0, 1, x + width, y + tr)}`);
  }

  data.push(`V${y + height - br}`);

  if (br > 0) {
    data.push(`A${arcParameter(br, br, 0, 0, 1, x + width - br, y + height)}`);
  }

  data.push(`H${x + bl}`);

  if (bl > 0) {
    data.push(`A${arcParameter(bl, bl, 0, 0, 1, x, y + height - bl)}`);
  }

  data.push(`V${y + tl}`);

  if (tl > 0) {
    data.push(`A${arcParameter(tl, tl, 0, 0, 1, x + tl, y)}`);
  }

  data.push('Z');

  return data.join(' ');
}

export interface QRCodeSVGProps {
  value: string;
  size?: string | number;
  foregroundColor?: string;
  backgroundColor?: string;
}

const QRCodeSVG = React.forwardRef<SVGSVGElement, QRCodeSVGProps & SVGAttributes<SVGSVGElement>>(
  (
    {
      value,
      size = '100%',
      foregroundColor = '#000000',
      backgroundColor = 'transparent',
      ...props
    },
    ref,
  ) => {
    const code = QRCode.create(value, {
      errorCorrectionLevel: 'quartile',
    });

    const finder = getFinderPatternCells(code.modules, code.version);
    const finderPositions = getPositions(code.version);
    const finderPatternInnerCornerRadius = 1;
    const finderPatternOuterCornerRadius = 2;
    const finderDotInset = 3.25;
    const finderDotInnerRadius = Math.max(finderPatternInnerCornerRadius - 0.75, 0);
    const finderDotOuterRadius = Math.max(finderPatternOuterCornerRadius - 0.75, 0);

    return (
      <svg
        ref={ref}
        {...props}
        width={size}
        height={size}
        viewBox={`0 0 ${code.modules.size} ${code.modules.size}`}
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width={code.modules.size} height={code.modules.size} fill={backgroundColor} />
        {Array.from(code.modules.data).map((d, i) => {
          const x = Math.floor(i % code.modules.size);
          const y = Math.floor(i / code.modules.size);
          const isFinder = finder[i];

          const dotSize = 0.75;
          const p = (1 - dotSize) / 2;

          return d && !isFinder ? (
            <rect
              key={`${x},${y}`}
              width={dotSize}
              height={dotSize}
              x={x + p}
              y={y + p}
              fill={foregroundColor}
              rx={100}
              ry={100}
            />
          ) : null;
        })}
        {finderPositions.map(([x, y, pos]) => (
          <React.Fragment key={`${x},${y}`}>
            <path
              d={rectWithIndependentCorners(
                x + 0.5,
                y + 0.5,
                FINDER_PATTERN_SIZE - 1,
                FINDER_PATTERN_SIZE - 1,
                pos === 'bl' ? finderPatternInnerCornerRadius : finderPatternOuterCornerRadius,
                pos === 'tl' ? finderPatternInnerCornerRadius : finderPatternOuterCornerRadius,
                pos === 'tr' ? finderPatternInnerCornerRadius : finderPatternOuterCornerRadius,
                finderPatternOuterCornerRadius,
              )}
              fill="none"
              stroke={foregroundColor}
              strokeWidth={0.75}
              rx={1}
              ry={1}
            />
            <path
              key={`${x},${y}`}
              d={rectWithIndependentCorners(
                x + finderDotInset / 2,
                y + finderDotInset / 2,
                FINDER_PATTERN_SIZE - finderDotInset,
                FINDER_PATTERN_SIZE - finderDotInset,
                pos === 'bl' ? finderDotInnerRadius : finderDotOuterRadius,
                pos === 'tl' ? finderDotInnerRadius : finderDotOuterRadius,
                pos === 'tr' ? finderDotInnerRadius : finderDotOuterRadius,
                finderDotOuterRadius,
              )}
              fill={foregroundColor}
              strokeWidth={0.7}
            />
          </React.Fragment>
        ))}
      </svg>
    );
  },
);

export default QRCodeSVG;
