import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { checkDefinedOrThrow, DetailPageLayouts } from '@meterup/common';
import { Heading2, Tab, Tabs } from '@meterup/metric';
import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

import { DetailPageLayout } from '../../../../../components/DetailPageLayout';
import { paths } from '../../../../../constants';
import { useControllerConfig } from '../../../../../hooks/useControllerConfig';
import { useIsActiveMatcher } from '../../../../../hooks/useIsActive';
import { useVLANClients } from '../../../../../hooks/useVLANClients';
import { useVLANConfigModel } from '../../../../../hooks/useVLANConfigModel';
import { Nav } from '../../../../../nav';
import { useCurrentCompany } from '../../../../../providers/CurrentCompanyProvider';
import { useCurrentController } from '../../../../../providers/CurrentControllerProvider';
import { makeLink } from '../../../../../utils/main_and_drawer_navigation';

const VLANDetailPageHeader = () => {
  const controllerName = useCurrentController();
  const companyName = useCurrentCompany();
  const isActiveTest = useIsActiveMatcher();
  const { vlanName } = checkDefinedOrThrow(Nav.useRegionParams('root', paths.pages.VLANDetailPage));

  const config = useControllerConfig(controllerName);
  const vlan = useVLANConfigModel(controllerName, vlanName);
  const clients = useVLANClients(controllerName, vlanName);

  return (
    <DetailPageLayouts.Container>
      <DetailPageLayouts.HeaderContent>
        <DetailPageLayouts.HeadingAndTabs>
          <Heading2>{vlan.name}</Heading2>
          <Tabs>
            <Tab
              as={ReactRouterLink}
              to={makeLink(paths.pages.VLANDetailPage, {
                vlanName: vlan.name,
                controllerName,
                companyName,
              })}
              active={isActiveTest({ path: paths.pages.VLANDetailPage, end: true })}
            >
              Overview
            </Tab>
            <Tab
              as={ReactRouterLink}
              to={makeLink(paths.pages.VLANSSIDListPage, {
                vlanName: vlan.name,
                controllerName,
                companyName,
              })}
              active={isActiveTest({ path: paths.pages.VLANSSIDListPage, end: true })}
              icon="ssid"
              annotation={config.getServiceSetsByVLAN(vlanName).length}
            >
              SSIDs
            </Tab>
            <Tab
              as={ReactRouterLink}
              to={makeLink(paths.pages.VLANClientListPage, {
                vlanName: vlan.name,
                controllerName,
                companyName,
              })}
              active={isActiveTest({ path: paths.pages.VLANClientListPage, end: true })}
              annotation={clients.length}
              icon="client"
            >
              Clients
            </Tab>
            <Tab
              as={ReactRouterLink}
              to={makeLink(paths.pages.VLANStaticIPListPage, {
                vlanName: vlan.name,
                controllerName,
                companyName,
              })}
              active={isActiveTest({ path: paths.pages.VLANStaticIPListPage, end: true })}
              annotation={vlan.staticClients.length}
            >
              Static IPs
            </Tab>
          </Tabs>
        </DetailPageLayouts.HeadingAndTabs>
      </DetailPageLayouts.HeaderContent>
    </DetailPageLayouts.Container>
  );
};

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/controller/:controllerName/vlans/:vlanName',
  layout: 'SidebarLayout',
});

export default function VLANDetailLayout() {
  return <DetailPageLayout header={<VLANDetailPageHeader />} />;
}
