import type { api } from '@meterup/proto';
import { UserRoleBadge } from '@meterup/common';
import {
  BodyMono2,
  CopyCapsule,
  MinList,
  MinListItemHeader,
  MinListItemLabel,
  MinListItemPair,
  MinListItemValue,
  MinListTitle,
} from '@meterup/metric';

export const UserDetailWidget = ({ user }: { user: api.UserResponse }) => (
  <MinList>
    <MinListItemHeader icon="user">
      <MinListTitle>User</MinListTitle>
    </MinListItemHeader>
    <MinListItemPair>
      <MinListItemLabel>Name</MinListItemLabel>
      <MinListItemValue>
        {user.first_name ?? '-'} {user.last_name}
      </MinListItemValue>
    </MinListItemPair>
    <MinListItemPair>
      <MinListItemLabel>Email</MinListItemLabel>
      <MinListItemValue>
        <BodyMono2>
          <CopyCapsule
            textValue={user.email}
            aria-label="Copy user email"
            arrangement="leading-icon"
          >
            {user.email}
          </CopyCapsule>
        </BodyMono2>
      </MinListItemValue>
    </MinListItemPair>
    <MinListItemPair>
      <MinListItemLabel>Role</MinListItemLabel>
      <MinListItemValue>
        <UserRoleBadge value={user.company_role} />
      </MinListItemValue>
    </MinListItemPair>
  </MinList>
);
