import { ManufacturerIconSVG } from 'atto-svgs';

import { colors, darkThemeSelector, shadows, styled } from '../../stitches.config';

export const icons = {
  apple: ManufacturerIconSVG.Apple,
  chrome: ManufacturerIconSVG.Chrome,
  ecobee: ManufacturerIconSVG.Ecobee,
  lenovo: ManufacturerIconSVG.Lenovo,
  logitech: ManufacturerIconSVG.Logitech,
  microsoft: ManufacturerIconSVG.Microsoft,
  nest: ManufacturerIconSVG.Nest,
  samsung: ManufacturerIconSVG.Samsung,
  shure: ManufacturerIconSVG.Shure,
  sonos: ManufacturerIconSVG.Sonos,
  unknown: ManufacturerIconSVG.Unknown,
};

export type ManufacturerIconName = keyof typeof icons;

const BaseSVG = styled('svg', {
  display: 'inline-block',
  flexGrow: 0,
  flexShrink: 0,
  width: '100%',
});

const Container = styled('div', {
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '$4',
  background: colors.white,
  boxShadow: shadows.manufacturerLight,
  flexShrink: 0,

  [darkThemeSelector]: {
    boxShadow: shadows.manufacturerDark,
  },

  variants: {
    size: {
      small: {
        borderRadius: '$6',
        width: '$20',
        height: '$20',
      },
      medium: {
        borderRadius: '$8',
        width: '$28',
        height: '$28',
        padding: '$6',
      },
      large: {
        borderRadius: '12px',
        width: '$40',
        height: '$40',
      },
    },
  },
});

export interface ManufacturerIconProps {
  icon: ManufacturerIconName;
  className?: string;

  /**
   * Sets the size of the component.
   */
  size?: 'large' | 'medium' | 'small';
}

const ManufacturerIcon = ({ icon, className, size = 'medium' }: ManufacturerIconProps) => {
  const NamedIcon = icons[icon];

  if (!NamedIcon) {
    // eslint-disable-next-line no-console
    console.error(`Missing icon named ${icon}`);
    return null;
  }

  return (
    <Container className={className} size={size}>
      <BaseSVG as={NamedIcon} />
    </Container>
  );
};

export default ManufacturerIcon;
