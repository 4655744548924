import type { api } from '@meterup/proto';

import { logError } from '../utils/logError';
import { axiosInstanceJSON } from './axiosInstanceJSON';

export const sendSMS = async (smsMessage: api.SMSMessage): Promise<void> => {
  try {
    await axiosInstanceJSON.post(`/v1/sms-messages`, smsMessage);
  } catch (e) {
    logError(e);
    throw e;
  }
};
