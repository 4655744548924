import { Button, EmptyState, Header, HeaderLogo } from '@meterup/metric';
import React from 'react';

import { Container, ContentContainer, HeaderContainer } from '../../components/app_layout';
import { ReactRouterLink } from '../../components/ReactRouterLink';
import { paths } from '../../constants';
import { makeLink } from '../../utils/main_and_drawer_navigation';

export const Meta = () => ({
  path: '*',
  layout: null,
});

export default function NotFoundPage() {
  return (
    <Container>
      <HeaderContainer>
        <Header logo={<HeaderLogo />} />
      </HeaderContainer>
      <ContentContainer>
        <EmptyState
          icon="attention"
          heading="Page not found"
          action={
            <Button
              as={ReactRouterLink}
              to={makeLink(paths.pages.RootPage, {})}
              variant="tertiary"
              arrangement="leading-icon"
              icon="home"
            >
              Go home
            </Button>
          }
        />
      </ContentContainer>
    </Container>
  );
}
