import { observer } from 'mobx-react-lite';

import { Content } from './Content';
import { Events } from './Events';
import { GlobalEvents } from './GlobalEvents';
import { Input } from './Input';
import { Overlay } from './Overlay';
import { Portal } from './Portal';
import { Results } from './Results';

export const Renderer = observer(() => (
  <>
    <GlobalEvents />
    <Portal>
      {/** TODO: simplifiy events API  */}
      <Events />
      <Overlay />
      <Content>
        <Input />
        <Results />
      </Content>
    </Portal>
  </>
));
