import {
  Button,
  Drawer,
  DrawerContent,
  DrawerControls,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from '@meterup/metric';
import { api } from '@meterup/proto';
import { Form, Formik } from 'formik';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import type { FixMeLater } from '../../../../../types/fixMeLater';
import type { ValidContentFilterRuleData } from './form_data';
import { createRuleForCompany } from '../../../../../api/api';
import { CloseDrawerButton } from '../../../../../components/CloseDrawerButton';
import { notify } from '../../../../../components/Notifications';
import { paths } from '../../../../../constants';
import { useCloseDrawerCallback } from '../../../../../hooks/useCloseDrawerCallback';
import { useCurrentCompany } from '../../../../../providers/CurrentCompanyProvider';
import { useCurrentController } from '../../../../../providers/CurrentControllerProvider';
import { styled } from '../../../../../stitches';
import { makeDrawerLink } from '../../../../../utils/main_and_drawer_navigation';
import { withZodSchema } from '../../../../../utils/withZodSchema';
import { ActionField, ContentField, NO_CATEGORY_ID, PriorityField } from './fields';
import { toAPIData, validContentFilterRuleData } from './form_data';

const StyledForm = styled(Form, {
  display: 'contents',
});

export const Meta = () => ({
  path: '/org/:companyName/controller/:controllerName/content-filters/rules/create',
});

export default function ContentFilterRuleCreatePage() {
  const companyName = useCurrentCompany();
  const controllerName = useCurrentController();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const createRuleMutation = useMutation(
    (data: ValidContentFilterRuleData) => {
      const apiData = toAPIData(data);

      return createRuleForCompany(companyName, apiData);
    },
    {
      onSuccess: async (value) => {
        await queryClient.invalidateQueries(['content_filters', companyName]);
        if (value) {
          navigate(
            makeDrawerLink(window.location, paths.drawers.ContentFilterRuleEditPage, {
              id: value.id,
              companyName,
              controllerName,
            }),
          );

          notify('Rule created successfully', { variant: 'positive' });
        }
      },
      onError: () => {
        notify('Failed to create rule', { variant: 'negative' });
      },
    },
  );

  const closeDrawer = useCloseDrawerCallback();

  return (
    <Formik<ValidContentFilterRuleData>
      validate={withZodSchema(validContentFilterRuleData)}
      initialValues={{
        content: {
          type: 'domain',
          domain: '',
          categoryId: NO_CATEGORY_ID as FixMeLater,
        },
        precedence: 0,
        action: api.ContentFilterAction.CONTENTFILTER_ACTION_BLOCK,
      }}
      onSubmit={(values) => createRuleMutation.mutate(values)}
    >
      <StyledForm>
        <Drawer>
          <DrawerHeader>
            <DrawerTitle>Create rule</DrawerTitle>
            <DrawerControls>
              <CloseDrawerButton />
            </DrawerControls>
          </DrawerHeader>
          <DrawerContent>
            <ContentField />
            <ActionField />
            <PriorityField />
          </DrawerContent>
          <DrawerFooter>
            <DrawerControls>
              <Button type="button" variant="secondary" onClick={closeDrawer}>
                Cancel
              </Button>
              <Button type="submit">Save</Button>
            </DrawerControls>
          </DrawerFooter>
        </Drawer>
      </StyledForm>
    </Formik>
  );
}
