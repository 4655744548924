import type { PathPattern } from 'react-router';
import type { ParamParseKey } from 'react-router-dom';
import { matchPath, useLocation } from 'react-router';

/**
 * A hook that returns a function to determine whether a link is active.
 */
export const useIsActiveMatcher = () => {
  const currentLocation = useLocation();
  return <ParamKey extends ParamParseKey<Path>, Path extends string>(
    pattern: PathPattern<Path> | Path,
  ) => !!matchPath<ParamKey, Path>(pattern, currentLocation.pathname);
};
