import { action, makeObservable, observable, reaction, runInAction } from 'mobx';

import type { ActionConfig } from './nodes/action';
import type { DirectoryConfig } from './nodes/directory';
import type { Node } from './nodes/node';
import { Filter } from './filter';
import { Action } from './nodes/action';
import { Directory } from './nodes/directory';
import { Root } from './nodes/root';
import { Ui } from './ui';

export const ROOT_ID = 'root';

type NodeFactory = {
  action: (config: ActionConfig) => Action;
  directory: (config: DirectoryConfig) => Directory;
};

export class State {
  root: Root;

  currentRoot!: Root | Directory;

  nodeFactory: NodeFactory = {
    action: (config) => new Action(config, this),
    directory: (config) => new Directory(config, this),
  };

  ui = new Ui(this);

  filter = new Filter(this);

  dispose;

  constructor(createRoot?: (nodeFactory: NodeFactory) => Node | Node[]) {
    this.root = new Root(
      {
        id: ROOT_ID,
        label: 'Root',
        display: 'Root',
        children: [],
      },
      this,
    );

    if (createRoot) {
      const children = createRoot(this.nodeFactory);

      if (Array.isArray(children)) {
        this.root.addChildren(children);
      } else {
        this.root.addChildren([children]);
      }
    }

    this.currentRoot = this.root;

    makeObservable(this, {
      root: observable,
      currentRoot: observable,
      updateCurrentRoot: action,
    });

    const disposeDynamic = reaction(
      () => this.currentRoot,
      (currRoot, prevRoot) => {
        if (prevRoot) {
          prevRoot.disposeDynamic();
        }
        currRoot.onEnter();
      },
    );

    this.dispose = () => {
      disposeDynamic();
    };
  }

  updateCurrentRoot(root: Root | Directory) {
    runInAction(() => {
      this.currentRoot = root;
    });
  }
}
