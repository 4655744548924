import { checkDefinedOrThrow } from '@meterup/common';
import { Drawer, DrawerContent, DrawerHeader, DrawerTitle } from '@meterup/metric';
import React from 'react';

import { VLANClientsWidget, VLANDHCPWidget, VLANTaggingWidget } from '../../../../components/vlans';
import { paths } from '../../../../constants';
import { useVLANConfigModel } from '../../../../hooks/useVLANConfigModel';
import { Nav } from '../../../../nav';
import { useCurrentController } from '../../../../providers/CurrentControllerProvider';

export const Meta = () => ({
  path: '/org/:companyName/controller/:controllerName/vlans/:vlanName',
});

export default function VLANSummaryPage() {
  const { vlanName } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.VLANSummaryPage),
  );
  const controllerName = useCurrentController();
  const vlan = useVLANConfigModel(controllerName, vlanName);

  return (
    <Drawer>
      <DrawerHeader>
        <DrawerTitle>VLAN</DrawerTitle>
      </DrawerHeader>
      <DrawerContent>
        <VLANTaggingWidget vlan={vlan} shouldLinkToDetails />
        <VLANDHCPWidget vlan={vlan} />
        <VLANClientsWidget vlan={vlan} />
      </DrawerContent>
    </Drawer>
  );
}
