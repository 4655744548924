import type { Location, To } from 'history';
import type { NavigateOptions, RouteObject } from 'react-router';
import React, { useCallback, useContext, useMemo } from 'react';
import {
  matchPath,
  Routes as ReactRouterRoutes,
  UNSAFE_RouteContext,
  useLocation,
  useRoutes as useReactRouterRoutes,
} from 'react-router';
import { Link as ReactRouterLink, useNavigate as useReactRouterNavigate } from 'react-router-dom';

import type { ExtractRouteParams } from './ExtractRouteParams';
import { isDefined } from './isDefined';
import { makeTo as makeToInternal } from './makeTo';
import { parseRegionLocations } from './parseRegionLocations';

export interface MatchPathOptions {
  end?: boolean;
  caseSensitive?: boolean;
}

type MultiTo<RegionNameArray extends readonly string[]> =
  | {
      [K in RegionNameArray[number] | 'root']?: To | null | undefined;
    }
  | To;

export interface LinkNavigationProps<RegionNameArray extends readonly string[]> {
  reloadDocument?: boolean;
  replace?: boolean;
  state?: any;
  preventScrollReset?: boolean;
  to: MultiTo<RegionNameArray>;
}

interface LinkProps<RegionNameArray extends readonly string[]>
  extends Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href'>,
    LinkNavigationProps<RegionNameArray> {}

type RegionLocations<RegionNameArray extends readonly string[]> = {
  [k in RegionNameArray[number]]: Location | null | undefined;
} & {
  root: Location;
};

interface MultiNavigation<RegionNameArray extends readonly string[]> {
  Provider: React.FC<{ children: React.ReactNode }>;

  useRealLocation(): Location;

  /**
   * Returns the current location for each defined region.
   */
  useRegionLocations(): RegionLocations<RegionNameArray>;

  /**
   * Returns the current root location.
   */
  useRegionLocation(region: 'root'): Location;

  /**
   * Given the name of a multi-nav region, returns the current location for that
   * region if the region is active. Otherwise, returns null.
   */
  useRegionLocation(region: RegionNameArray[number]): Location | null;

  /**
   * Returns the parameters for the current root location if the given route
   * matches. Otherwise returns null.
   *
   * @example
   *  // Given a URL https://example.com/users/123?panel=/users/456
   *  useRegionParams('root', '/users/:id');
   *  // { id: '123' }
   */
  useRegionParams<Path extends string = '', Value extends string | number | boolean = string>(
    region: 'root',
    path: Path,
    options?: MatchPathOptions,
  ): ExtractRouteParams<Path, Value> | null;

  /**
   * Given the name of a multi-nav region and a path, returns the params
   * extracted from the path if the region is active and the path is matched.
   *
   * @example
   *  // Given a URL https://example.com/users/123?panel=/users/456
   *  useRegionParams('panel', '/users/:id');
   *  // { id: '456' }
   */
  useRegionParams<Path extends string = '', Value extends string | number | boolean = string>(
    region: RegionNameArray[number],
    path: Path,
    options?: MatchPathOptions,
  ): ExtractRouteParams<Path, Value> | null;

  /**
   * Creates a Path partial capable of navigating multiple regions
   * simultaneously.
   *
   * @example
   *   makeTo({drawer: '/clients/123'});
   *   // { search: 'drawer=/clients/123' }
   */
  makeTo(to: MultiTo<RegionNameArray>, state?: unknown): To;

  /**
   * A Link component capable of navigating multiple regions simultaneously.
   *
   * @example
   *   <Link to={{drawer: '/clients/123'}}>Clients</Link>
   */
  Link: React.FC<LinkProps<RegionNameArray>>;

  /**
   * Returns a callback capable of navigating multiple regions simultaneously.
   *
   * @example
   *   const navigate = Nav.useNavigate();
   *   navigate({drawer: '/clients/123'});
   */
  useNavigate: () => (
    to: MultiTo<RegionNameArray>,
    options?: Omit<NavigateOptions, 'relative'>,
  ) => void;

  /**
   * Behaves like Routes but routes within match based on the location of its
   * associated region. If the region is not active, returns null.
   *
   * @see https://reactrouter.com/en/v6.3.0/api#routes-and-route
   */
  Routes: React.FC<{
    regionName: RegionNameArray[number] | 'root';
    children: React.ReactNode | undefined;
    /**
     * If true, a new RouteContext will be established for the nested routes.
     * This allows matching routes that are unrelated to their parent routes.
     *
     * @default true
     */
    resetRouteContext?: boolean;
  }>;

  /**
   * Behaves like useRoutes but routes within match based on the location of its
   * associated region. If the region is not active, returns null.
   *
   * @see https://reactrouter.com/docs/en/v6/api#useroutes
   */
  useRoutes(
    regionName: RegionNameArray[number] | 'root',
    routes: RouteObject[],
    /**
     * If true, a new RouteContext will be established for the nested routes.
     * This allows matching routes that are unrelated to their parent routes.
     *
     * @default true
     */
    resetRouteContext?: boolean,
  ): React.ReactElement | null;
}

/**
 * Creates a multi-navigation context that can be used to navigate multiple
 * regions simultaneously. Returns the context provider as well as some hooks
 * for working with the regions.
 *
 * @example
 *  const Nav = createMultiNavigation('drawer');
 *
 *  const App = () => (
 *    <Router>
 *      <Nav.Provider>
 *        {...}
 *      </Nav.Provider>
 *    </Router>
 *  );
 *
 *  Use within the context...
 *
 *  const location = useRegionLocation('root');
 *  <Link to={Nav.makeTo(location, {drawer: '/users/123'})}>Open a drawer</Link>
 */
export function createMultiNavigation<T extends readonly string[]>(
  ...regionNames: T
): MultiNavigation<T> {
  const name = regionNames.find((n) => ['pathname', 'search', 'state', 'hash', 'key'].includes(n));
  if (isDefined(name)) {
    throw new Error(`createMultiNavigation: ${name} is not a valid region name.`);
  }

  type RegionName = T[number] | 'root';

  interface ContextValue {
    actualLocation: Location;
    regions: {
      [k: string]: Location | null | undefined;
      root: Location;
    };
  }

  const Context = React.createContext<ContextValue>(null as any);

  const Provider = ({ children }: { children: React.ReactNode | React.ReactNode[] }) => {
    const { key, pathname, search, hash, state } = useLocation();

    const value = useMemo(
      () => ({
        actualLocation: { key, pathname, search, hash, state },
        regions: parseRegionLocations(regionNames, { key, pathname, search, hash, state }),
      }),
      [key, pathname, search, hash, state],
    );

    return <Context.Provider value={value}>{children}</Context.Provider>;
  };

  const useMultiNavigationContext = () => {
    const locationContext = useContext(Context);

    if (!isDefined(locationContext)) {
      throw new Error('useMultiNavigationContext: Expected a multi navigation Provider to exist');
    }

    return locationContext;
  };

  const useRegionLocations = (): RegionLocations<T> =>
    useMultiNavigationContext().regions as RegionLocations<T>;

  const useRegionLocation = (regionName: RegionName): Location | null =>
    useMultiNavigationContext().regions[regionName] ?? null;

  const useRealLocation = (): Location => useMultiNavigationContext().actualLocation;

  const useRegionParams = <
    Path extends string = '',
    Value extends string | number | boolean = string,
  >(
    regionName: string,
    path: Path,
    { end = false, caseSensitive = false }: MatchPathOptions = {},
  ): ExtractRouteParams<Path, Value> | null => {
    const location = useRegionLocation(regionName);

    if (location) {
      const match = matchPath(
        {
          path,
          end,
          caseSensitive,
        },
        location.pathname,
      );

      if (match) {
        return match.params as unknown as ExtractRouteParams<Path, Value>;
      }

      return null;
    }

    return null;
  };

  const BLANK_ROUTE_CONTEXT_VALUE = {
    outlet: null,
    matches: [],
  };

  const Routes = ({
    regionName,
    children,
    resetRouteContext = true,
  }: {
    regionName: RegionName;
    children?: React.ReactNode | undefined;
    resetRouteContext?: boolean;
  }) => {
    const location = useRegionLocation(regionName);

    if (isDefined(location)) {
      return resetRouteContext ? (
        // eslint-disable-next-line react/jsx-pascal-case
        <UNSAFE_RouteContext.Provider value={BLANK_ROUTE_CONTEXT_VALUE}>
          <ReactRouterRoutes location={location}>{children}</ReactRouterRoutes>
        </UNSAFE_RouteContext.Provider>
      ) : (
        <ReactRouterRoutes location={location}>{children}</ReactRouterRoutes>
      );
    }

    return null;
  };

  const UseRoutesInternal = ({ routes, location }: { location: Location; routes: RouteObject[] }) =>
    useReactRouterRoutes(routes, location);

  const useRoutes = (
    regionName: RegionName,
    routes: RouteObject[],
    resetRouteContext: boolean = true,
  ) => {
    const location = useRegionLocation(regionName);

    if (isDefined(location)) {
      return resetRouteContext ? (
        // eslint-disable-next-line react/jsx-pascal-case
        <UNSAFE_RouteContext.Provider value={BLANK_ROUTE_CONTEXT_VALUE}>
          <UseRoutesInternal location={location} routes={routes} />
        </UNSAFE_RouteContext.Provider>
      ) : (
        <UseRoutesInternal location={location} routes={routes} />
      );
    }

    return null;
  };

  const makeTo = (to: To | MultiTo<T>): To => makeToInternal(to, regionNames);

  const Link = React.forwardRef(
    ({ to, ...props }: LinkProps<T>, ref: React.ForwardedRef<HTMLAnchorElement>) => (
      <ReactRouterLink {...props} to={makeTo(to)} ref={ref} />
    ),
  );

  const useNavigate = () => {
    const navigate = useReactRouterNavigate();
    return useCallback(
      (to: MultiTo<T>, options?: Omit<NavigateOptions, 'relative'>) =>
        navigate(makeTo(to), options),
      [navigate],
    );
  };

  return {
    Provider,
    Routes,
    Link,
    useNavigate,
    useRoutes,
    makeTo,
    useRealLocation,
    useRegionLocations,
    useRegionLocation: useRegionLocation as (regionName: RegionName) => Location,
    useRegionParams,
  };
}
