import type { ManufacturerIconName } from '@meterup/metric';
import type { api } from '@meterup/proto';
import { match } from 'ts-pattern';

export const isOnline = (c: api.ControllerClient | api.UserClient): boolean => {
  if (c.last_seen) {
    const dateLessFive = new Date();
    const itemDate = new Date(c.last_seen);

    dateLessFive.setMinutes(dateLessFive.getMinutes() - 5);

    return itemDate >= dateLessFive;
  }

  return false;
};

export const isOffline = (c: api.ControllerClient | api.UserClient): boolean => !isOnline(c);

export const isGuest = (c: api.ControllerClient | api.UserClient) => c.vlan === 'guest';

export const getManufacturerIconName = (manufacturer: string): ManufacturerIconName =>
  match(manufacturer)
    .when(
      (v) => v.includes('apple'),
      () => 'apple' as const,
    )
    .when(
      (v) => v.includes('samsung'),
      () => 'samsung' as const,
    )
    .when(
      (v) => v.includes('chrome'),
      () => 'chrome' as const,
    )
    .when(
      (v) => v.includes('ecobee'),
      () => 'ecobee' as const,
    )
    .when(
      (v) => v.includes('samsung'),
      () => 'samsung' as const,
    )
    .when(
      (v) => v.includes('sonos'),
      () => 'sonos' as const,
    )
    .otherwise(() => 'unknown' as const);
