import type { MeterV1NetworkVLANStaticClient } from '@meterup/config';
import type { SortingState } from '@tanstack/react-table';
import {
  checkDefinedOrThrow,
  expectDefinedOrThrow,
  isDefined,
  NeutralBadge,
  PositiveBadge,
  ResourceNotFoundError,
} from '@meterup/common';
import { Button, EmptyState, SmallMono2, TextInput } from '@meterup/metric';
import { first, groupBy, mapValues, orderBy } from 'lodash';
import React, { useMemo } from 'react';

import type { ClientData } from '../../../../../api/types';
import { Page, PageControls, PageHeader } from '../../../../../components/Page/Page';
import { ReactRouterLink } from '../../../../../components/ReactRouterLink';
import { AutoTable2 } from '../../../../../components/Table/AutoTable2';
import { createColumnBuilder } from '../../../../../components/Table/createColumnBuilder';
import { paths } from '../../../../../constants';
import { useCloseDrawerCallback } from '../../../../../hooks/useCloseDrawerCallback';
import { useVLANClients } from '../../../../../hooks/useVLANClients';
import { useVLANConfigModel } from '../../../../../hooks/useVLANConfigModel';
import { Nav } from '../../../../../nav';
import { useCurrentCompany } from '../../../../../providers/CurrentCompanyProvider';
import { useCurrentController } from '../../../../../providers/CurrentControllerProvider';
import { useSearchParamsState } from '../../../../../providers/SearchParamsStateProvider';
import { makeDrawerLink } from '../../../../../utils/main_and_drawer_navigation';

interface ClientAndModel {
  client: ClientData | null;
  model: MeterV1NetworkVLANStaticClient;
}

const builder = createColumnBuilder<ClientAndModel>();

const columns = [
  builder.display({
    meta: {
      isLeading: true,
      sizingMode: 'fit-min',
    },
    cell: (p) =>
      isDefined(p.row.client) ? (
        <PositiveBadge arrangement="hidden-label" icon="client">
          Connected
        </PositiveBadge>
      ) : (
        <NeutralBadge arrangement="hidden-label" icon="client">
          No connection
        </NeutralBadge>
      ),
  }),
  builder.data((d) => d.model.name, {
    header: 'Name',
    meta: {
      isLeading: true,
    },
  }),
  builder.data((d) => d.model.macAddress, {
    header: 'MAC',
    meta: {
      isCopyable: true,
    },
    cell: (p) => <SmallMono2>{p.value}</SmallMono2>,
  }),
  builder.data((d) => d.model.ipAddress, {
    header: 'IP assignment',
    meta: {
      isCopyable: true,
    },
    cell: (p) => <SmallMono2>{p.value}</SmallMono2>,
  }),
];

export const Meta = () => ({
  path: '/org/:companyName/controller/:controllerName/vlans/:vlanName/static',
});

export default function VLANStaticIPListPage() {
  const { vlanName } = checkDefinedOrThrow(
    Nav.useRegionParams('root', paths.pages.VLANStaticIPListPage),
  );
  const controllerName = useCurrentController();
  const companyName = useCurrentCompany();
  const model = useVLANConfigModel(controllerName, vlanName);
  const clients = useVLANClients(controllerName, vlanName);

  const clientsByMAC = useMemo(
    () =>
      mapValues(
        groupBy(clients, (d) => d.mac_address),
        first,
      ),
    [clients],
  );

  expectDefinedOrThrow(
    model,
    new ResourceNotFoundError(`Configuration for controller ${controllerName} not found`),
  );

  const params = Nav.useRegionParams('drawer', paths.drawers.VLANStaticIPEditPage);
  const onRowDeselect = useCloseDrawerCallback();

  const sortedModelsWithClientsJoined = useMemo((): ClientAndModel[] => {
    const sortedModels = orderBy(model.staticClients, (c) => c.ip?.bigInteger());
    return sortedModels.map((d) => ({
      client: clientsByMAC[d.macAddress] ?? null,
      model: d,
    }));
  }, [clientsByMAC, model.staticClients]);

  const [globalFilter, setGlobalFilter] = useSearchParamsState<string>('filter', '');
  const [sortingState, setSortingState] = useSearchParamsState<SortingState>('sort');

  return (
    <Page>
      <PageHeader>
        <PageControls>
          <Button
            as={ReactRouterLink}
            to={makeDrawerLink(window.location, paths.drawers.VLANStaticIPCreatePage, {
              vlanName,
              controllerName,
              companyName,
            })}
            variant="tertiary"
            icon="plusCircle"
            arrangement="leading-icon"
          >
            Create static IP
          </Button>
        </PageControls>

        <div style={{ minWidth: 56 }}>
          <TextInput
            aria-label="Filter static IPs"
            icon="searchScoped"
            value={globalFilter}
            onChange={setGlobalFilter}
          />
        </div>
      </PageHeader>
      {sortedModelsWithClientsJoined.length > 0 ? (
        <AutoTable2
          data={sortedModelsWithClientsJoined}
          columns={columns}
          sortingState={sortingState}
          onChangeSortingState={setSortingState}
          globalFilter={globalFilter}
          onRowDeselect={onRowDeselect}
          isRowSelected={(d) => d.model.macAddress === params?.macAddress}
          getLinkTo={(d) =>
            makeDrawerLink(window.location, paths.drawers.VLANStaticIPEditPage, {
              vlanName,
              macAddress: d.model.macAddress,
              controllerName,
              companyName,
            })
          }
        />
      ) : (
        <EmptyState
          icon="vlan"
          heading="No static IPs defined"
          action={
            <Button
              as={ReactRouterLink}
              to={makeDrawerLink(window.location, paths.drawers.VLANStaticIPCreatePage, {
                vlanName,
                controllerName,
                companyName,
              })}
              variant="tertiary"
              icon="plusCircle"
              arrangement="leading-icon"
            >
              Create static IP
            </Button>
          }
        />
      )}
    </Page>
  );
}
