import { styled } from '@meterup/metric/src/stitches.config';
import { observer } from 'mobx-react-lite';

import { VisualState } from '../core';
import { useCommand } from './Root';

const Box = styled('div');

export const Overlay = observer(() => {
  const { state } = useCommand();

  if ([VisualState.Hidden, VisualState.Hiding].includes(state.ui.visualState)) {
    return null;
  }

  // TODO: Add our animations on the overlay + dark mode
  return (
    <Box
      onPointerDown={() => {
        state.ui.update((ui) => {
          // TODO: animations
          // eslint-disable-next-line no-param-reassign
          ui.visualState = VisualState.Hidden;
        });
      }}
      css={{
        position: 'fixed',
        inset: 0,
        background:
          'radial-gradient(50% 50% at 50% 50%, rgba(9, 11, 20, 0.05) 0%, rgba(9, 11, 20, 0.125) 100%)',
        zIndex: 3,
      }}
    />
  );
});
