import { Command } from '@meterup/command';
import { ResourceNotFoundError } from '@meterup/common';
import * as Sentry from '@sentry/react';
import { ErrorBoundary } from '@sentry/react';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import React, { Suspense, useEffect } from 'react';
import { ModalProvider, OverlayProvider } from 'react-aria';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Navigate, useLocation } from 'react-router';
import { BrowserRouter as Router } from 'react-router-dom';
import { instanceOf, match } from 'ts-pattern';

import { useValidateFeatureFlags } from '../hooks/useValidateFeatureFlags';
import { ViewportProvider } from '../hooks/useViewport';
import {
  LaunchDarklyIdentifyEffect,
  PostHogIdentifyEffect,
  SentryIdentifyEffect,
} from '../identify_effects';
import { Nav } from '../nav';
import { DefaultCompanyProvider } from '../providers/DefaultCompanyProvider';
import { DefaultCurrentControllerProvider } from '../providers/DefaultControllerProvider';
import { IdentityDataProvider } from '../providers/IdentityDataProvider';
import { PosthogFeatureFlagsProvider } from '../providers/PostHogFeatureFlagsProvider';
import { colors, globalCss, styled } from '../stitches';
import { AppSkeleton } from './AppSkeleton';
import { AutoSaveDefaultCompanyEffect } from './AutoSaveDefaultCompanyEffect';
import { AutoSaveDefaultControllerEffect } from './AutoSaveDefaultControllerEffect';
import { FatalErrorFallback } from './ErrorFallback/ErrorFallback';
import { Notifications } from './Notifications';
import { OverlayableContainer, overlayableRootCSS } from './overlays';
import { MainRoutes } from './route_elements';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (count, error) =>
        match(error)
          .with(instanceOf(ResourceNotFoundError), () => false)
          .otherwise(() => true),
    },
  },
});

const injectGlobalStyles = globalCss({
  'html, body, #root': {
    height: '100%',
    width: '100%',
    overscrollBehaviorY: 'none',
    position: 'fixed',
    overflow: 'hidden',
  },
  body: {
    background: colors.white,
    fontSize: 16,
    '&.m-dark': {
      background: colors['gray-800'],
    },
    // Enable improved font rendering only on high-DPI displays
    '@media only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 13/10), only screen and (min-resolution: 120dpi)':
      {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
      },
  },
  '#root': overlayableRootCSS,
});

const StyledOverlayProvider = styled(OverlayProvider, OverlayableContainer);

const RedirectDrawerEffect = ({ children }: { children: React.ReactChild }) => {
  const location = useLocation();

  if (location.search.includes('drawer')) {
    const params = new URLSearchParams(location.search);
    const drawer = params.get('drawer');

    return (
      <Navigate
        replace
        to={Nav.makeTo({
          root: location.pathname,
          drawer,
        })}
      />
    );
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

const AppProviders = ({ children }: React.PropsWithChildren<{}>) => (
  <PosthogFeatureFlagsProvider>
    <Router>
      <RedirectDrawerEffect>
        <Nav.Provider>
          <QueryClientProvider client={queryClient}>
            <StyledOverlayProvider>
              <ModalProvider>
                <IdentityDataProvider>
                  <LaunchDarklyIdentifyEffect />
                  <SentryIdentifyEffect />
                  <PostHogIdentifyEffect />
                  <DefaultCompanyProvider>
                    <DefaultCurrentControllerProvider>
                      <AutoSaveDefaultControllerEffect />
                      <AutoSaveDefaultCompanyEffect />
                      <ViewportProvider>
                        <Command.Root>
                          <Command.Renderer />
                          {children}
                        </Command.Root>
                      </ViewportProvider>
                    </DefaultCurrentControllerProvider>
                  </DefaultCompanyProvider>
                </IdentityDataProvider>
              </ModalProvider>
            </StyledOverlayProvider>
          </QueryClientProvider>
        </Nav.Provider>
      </RedirectDrawerEffect>
    </Router>
  </PosthogFeatureFlagsProvider>
);

const App: React.FC = () => {
  useEffect(() => {
    injectGlobalStyles();
  });

  useValidateFeatureFlags();

  return (
    <ErrorBoundary fallback={FatalErrorFallback}>
      <Suspense fallback={<AppSkeleton />}>
        <Notifications />
        <AppProviders>
          <MainRoutes />
        </AppProviders>
      </Suspense>
    </ErrorBoundary>
  );
};

const withLaunchDarkly = withLDProvider({
  clientSideID: import.meta.env.LAUNCHDARKLY_CLIENT_ID,
  reactOptions: {
    useCamelCaseFlagKeys: false,
  },
});

export default Sentry.withProfiler(withLaunchDarkly(App));
