import React from 'react';
import { useQuery } from 'react-query';

import { fetchClients } from '../../../../../api/api';
import { ClientsList } from '../../../../../components/ClientsList';
import { Page } from '../../../../../components/Page/Page';
import { useFeatureFlags } from '../../../../../hooks/useFeatureFlags';
import { useCurrentController } from '../../../../../providers/CurrentControllerProvider';

export const Meta = () => ({
  path: '/org/:companyName/controller/:controllerName/clients',
  layout: 'NetworkLayout',
});

export default function ClientsListPage() {
  const controller = useCurrentController();
  const flags = useFeatureFlags();

  const clients =
    useQuery(['clients', controller], () => fetchClients(controller), { suspense: true }).data ??
    [];

  return (
    <Page>
      <ClientsList clients={clients} showTabSwitcher={flags['client-wired-wireless-filter-ui']} />
    </Page>
  );
}
