import type { IconName } from '@meterup/metric/src/assets/Icon/Icon';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';

import type { State } from '../state';
import type { Directory } from './directory';
import type { Root } from './root';

export interface BaseConfig {
  id: string;
  /**
   * `display` the UI to render for the specific node
   */
  display: any;
  /**
   * `label` the text for screen readers
   */
  label: string;
  /**
   * `priority` enables finer filter controls
   */
  priority?: number;
  /**
   * `group` segment nodes further into their own filterable groups.
   */
  group?: string;
  /**
   * `synonyms` keywords separated by a space; ie `'wifi internet online'`
   */
  synonyms?: string;
  icon?: IconName;
  shortcut?: string;
}

export enum Priority {
  Low = 0.9,
  Medium = 1,
  High = 1.1,
}

export abstract class Node<Config extends BaseConfig = any> {
  id!: BaseConfig['id'];

  display: BaseConfig['display'] = '';

  group: BaseConfig['group'] = '';

  priority: number = Priority.Medium;

  label: BaseConfig['label'] = '';

  parent?: Root | Directory | null = null;

  synonyms: string = '';

  icon?: IconName;

  shortcut?: string | null = null;

  constructor(config: Config, readonly state: State) {
    Object.assign(this, config);

    makeObservable(this, {
      id: observable,
      display: observable,
      group: observable,
      priority: observable,
      label: observable,
      parent: observable,
      synonyms: observable,
      shortcut: observable,
      update: action,
      ancestors: computed,
    });
  }

  update(mutator: (node: Node) => void) {
    runInAction(() => {
      mutator(this);
    });
  }

  get ancestors() {
    const ancestors: (Root | Directory)[] = [];
    let tempParent = this.parent;

    while (tempParent) {
      ancestors.unshift(tempParent);
      tempParent = tempParent.parent;
    }
    return ancestors;
  }
}
