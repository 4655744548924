// Need this to fix console error regeneratorRuntime is not defined.
import 'regenerator-runtime/runtime';
import './sentry';

import { enableMapSet } from 'immer';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './components/App';
import { initializePosthog } from './utils/initializePosthog';

initializePosthog();
enableMapSet();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
