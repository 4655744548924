import { isDefined } from '@meterup/common';
import { matchPath, useLocation } from 'react-router';

import { paths } from '../constants';
import { WorkspaceNotFoundError } from '../errors';
import { useIdentity } from '../providers/IdentityDataProvider';

export const useCurrentCompanyFromPathOrNull = () => {
  const identity = useIdentity();
  const location = useLocation();

  const companyNameFromPath = matchPath(
    {
      path: paths.pages.CompanyRootPage,
      end: false,
    },
    location.pathname,
  )?.params.companyName;

  return identity.company_memberships.find((c) => c.company_slug === companyNameFromPath) ?? null;
};

export const useCurrentCompanyFromPath = () => {
  const identity = useIdentity();
  const location = useLocation();

  const companyNameFromPath = matchPath(
    {
      path: paths.pages.CompanyRootPage,
      end: false,
    },
    location.pathname,
  )?.params.companyName;

  const currentCompany =
    identity.company_memberships.find((c) => c.company_slug === companyNameFromPath) ?? null;

  if (!isDefined(currentCompany)) {
    throw new WorkspaceNotFoundError(
      'Workspace not found',
      `The workspace "${companyNameFromPath}" does not exist or you do not have permission to access it. Please contact the workspace administrator or support if you believe this is an error.`,
    );
  }

  return currentCompany;
};
