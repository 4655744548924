import { isDefinedAndNotEmpty, ISPStatusBadge, NeutralBadge } from '@meterup/common';
import { Body2, Small2 } from '@meterup/metric';
import React from 'react';
import { useQuery } from 'react-query';

import type { SSIDData } from '../../../api/types';
import { fetchControllerSSIDs, fetchNetworkISPInfo, fetchProviders } from '../../../api/api';
import { paths } from '../../../constants';
import { Nav } from '../../../nav';
import { useCurrentCompany } from '../../../providers/CurrentCompanyProvider';
import { useCurrentController } from '../../../providers/CurrentControllerProvider';
import { colors, fontWeights, shadows, styled } from '../../../stitches';
import { makeDrawerLink } from '../../../utils/main_and_drawer_navigation';
import { Page, PageHeader, PageSection, PageTitle } from '../../Page/Page';
import { AutoTable2 } from '../../Table/AutoTable2';
import { createColumnBuilder } from '../../Table/createColumnBuilder';
import { UploadDownloadStat } from './UploadDownloadStat';

const ConnectionGrid = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'minmax(80px, 120px) 1fr minmax(140px, max-content)',
  alignItems: 'stretch',
});

const ConnectionRow = styled('div', {
  display: 'contents',
});

const ConnectionCell = styled('div', Body2, {
  display: 'flex',
  alignItems: 'center',
  padding: '$16',
  boxShadow: shadows.fenceBottomLight,
  '&:first-child': {
    paddingLeft: '$20',
  },
  '&:last-child': {
    paddingRight: '$20',
  },
});

const Stat = styled('div', {
  vStack: '$4',
  alignItems: 'stretch',
});

const PrimaryStatLabel = styled('div', Body2, {
  fontWeight: fontWeights.medium,
  color: colors['gray-700'],
});

const StatLabel = styled('div', Small2);

const StatValue = styled('div', {
  hStack: 0,
});

const bldr = createColumnBuilder<SSIDData>();

const columns = [
  bldr.data((d) => d.ssid, {
    header: 'SSID',
    meta: {
      isLeading: true,
    },
  }),
  bldr.data((d) => d.type, {
    header: 'Type',
    cell: (p) => <NeutralBadge ends="card">{p.value}</NeutralBadge>,
  }),
];

const InternetAndWireless = () => {
  const controllerName = useCurrentController();
  const companyName = useCurrentCompany();
  const drawer = Nav.useRegionParams('drawer', paths.drawers.LegacySSIDDetailsPage);

  const providers =
    useQuery('providers', fetchProviders, {
      suspense: true,
    }).data ?? [];

  const connections =
    useQuery(['network_isp_info', controllerName], () => fetchNetworkISPInfo(controllerName), {
      suspense: true,
    }).data ?? [];

  const networkInfo =
    useQuery(['controller_ssids', controllerName], () => fetchControllerSSIDs(controllerName), {
      suspense: true,
    }).data ?? [];

  return (
    <Page css={{ gap: '$16' }}>
      {connections.length > 0 && (
        <PageSection css={{ flex: 0 }}>
          <PageHeader>
            <PageTitle>Internet connections</PageTitle>
          </PageHeader>
          <ConnectionGrid>
            {connections.map((plan) => {
              const path = providers.find((p) => p.sid === plan.provider)?.path;
              return (
                <ConnectionRow key={plan.sid}>
                  <ConnectionCell>
                    {isDefinedAndNotEmpty(path) && (
                      <img src={`//public.meter.com${path}`} alt={plan.provider} />
                    )}
                  </ConnectionCell>
                  <ConnectionCell>
                    <Stat>
                      <PrimaryStatLabel>{plan.provider_name}</PrimaryStatLabel>
                      <StatValue>
                        <ISPStatusBadge status={plan.status} />
                      </StatValue>
                    </Stat>
                  </ConnectionCell>
                  <ConnectionCell>
                    <Stat>
                      <StatLabel>Bandwidth</StatLabel>
                      <StatValue>
                        <UploadDownloadStat
                          downloadKbps={plan.download_kbps}
                          uploadKbps={plan.upload_kbps}
                        />
                      </StatValue>
                    </Stat>
                  </ConnectionCell>
                </ConnectionRow>
              );
            })}
          </ConnectionGrid>
        </PageSection>
      )}
      <PageSection>
        <PageHeader>
          <PageTitle>SSIDs</PageTitle>
        </PageHeader>
        <AutoTable2
          columns={columns}
          data={networkInfo}
          isRowSelected={(row) => row.sid === drawer?.ssidName}
          getLinkTo={(row) =>
            makeDrawerLink(window.location, paths.drawers.LegacySSIDDetailsPage, {
              ssidName: row.sid,
              controllerName,
              companyName,
            })
          }
        />
      </PageSection>
    </Page>
  );
};

export default InternetAndWireless;
