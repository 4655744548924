import { getErrorMessage, isDefined } from '@meterup/common';
import { Alert, Body2, BodyMono2, Button, space, TextInput, VStack } from '@meterup/metric';
import { fontWeights } from '@meterup/metric/src/stitches.config';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { deleteVPNClient } from '../../../../api/vpn';
import {
  Dialog,
  DialogBody,
  DialogClose,
  DialogCloseButton,
  DialogContent,
  DialogControls,
  DialogFooter,
  DialogHeader,
  DialogSection,
  DialogTitle,
  DialogTrigger,
} from '../../../../components/Dialog';
import { useCloseDrawerCallback } from '../../../../hooks/useCloseDrawerCallback';

export function DeleteVPNClientDialog({
  isOpen,
  onOpenChange,
  controllerName,
  vpnClientName,
  vpnClientSid,
}: {
  controllerName: string;
  vpnClientName: string;
  vpnClientSid: string;
  isOpen: boolean;
  onOpenChange: (value: boolean) => void;
}) {
  const [confirmDeleteString, setConfirmDeleteString] = useState<string>('');
  const queryClient = useQueryClient();
  const closeDrawer = useCloseDrawerCallback();
  const doesConfirmDeleteStringMatch = confirmDeleteString === vpnClientName;

  const resetState = () => {
    setConfirmDeleteString('');
  };

  const deleteVPNClientMutation = useMutation(
    async () => {
      await deleteVPNClient(controllerName, vpnClientSid);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['controller', controllerName]);
        resetState();
        onOpenChange(false);
        closeDrawer();
      },
    },
  );

  const actionDelete = async (e: React.FormEvent<EventTarget>) => {
    e.preventDefault();
    if (doesConfirmDeleteStringMatch) {
      deleteVPNClientMutation.mutate();
    }
  };

  const errorMessage = isDefined(deleteVPNClientMutation.error)
    ? getErrorMessage(deleteVPNClientMutation.error)
    : null;

  const handleOpenChange = (value: boolean) => {
    if (!value) {
      resetState();
      deleteVPNClientMutation.reset();
    }
    onOpenChange(value);
  };

  return (
    <Dialog open={isOpen} onOpenChange={handleOpenChange}>
      <DialogTrigger />
      <DialogContent>
        <form onSubmit={actionDelete}>
          <DialogHeader>
            <DialogTitle>Remove VPN client</DialogTitle>
            <DialogControls>
              <DialogCloseButton />
            </DialogControls>
          </DialogHeader>
          <DialogBody>
            {isDefined(errorMessage) && (
              <Alert
                icon="warning"
                variant="negative"
                heading="Failed to remove client"
                copy={errorMessage}
                cornerStyle="square"
              />
            )}

            <DialogSection>
              <VStack align="stretch" spacing={space(20)}>
                <VStack align="stretch" spacing={space(8)}>
                  <Body2 style={{ fontWeight: fontWeights.medium.toString() }}>
                    Are you sure you want to remove: {vpnClientName}?
                  </Body2>
                  <Body2 as="p">
                    This action cannot be undone. This will permanently remove the VPN client from
                    the network, as well as delete any data associated with client{' '}
                    <span style={{ fontWeight: fontWeights.medium.toString() }}>
                      {vpnClientName}
                    </span>
                    .
                  </Body2>
                  <Body2 as="p">
                    Please type{' '}
                    <BodyMono2 as="code" style={{ fontWeight: 600 }}>
                      {vpnClientName}
                    </BodyMono2>{' '}
                    to confirm.
                  </Body2>
                </VStack>
                <TextInput
                  controlSize="large"
                  aria-label="Confirm device removal"
                  onChange={(value) => setConfirmDeleteString(value)}
                />
              </VStack>
            </DialogSection>
          </DialogBody>
          <DialogFooter>
            <DialogControls>
              <DialogClose asChild>
                <Button variant="secondary">Cancel</Button>
              </DialogClose>
              <Button
                variant="destructive"
                disabled={deleteVPNClientMutation.isLoading || !doesConfirmDeleteStringMatch}
              >
                Remove
              </Button>
            </DialogControls>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
}
