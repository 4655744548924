import React from 'react';

import { TabletJoinInstructions } from '../../components/TabletJoinInstructions';

export const Meta = () => ({
  path: '/org/:companyName/controller/:controllerName/join/:ssid',
  layout: null,
});

export default function TabletJoinInstructionsPage() {
  return <TabletJoinInstructions />;
}
