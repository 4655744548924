import type { SidebarLocationControlOption } from '@meterup/metric';
import { SidebarLocationControl } from '@meterup/metric';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Item } from 'react-stately';

import { paths } from '../../constants';
import { useCurrentCompany } from '../../providers/CurrentCompanyProvider';
import { useCurrentController } from '../../providers/CurrentControllerProvider';
import { makeLink } from '../../utils/main_and_drawer_navigation';
import { useControllerList } from './useControllerList';

interface LocationControlProps {
  onClose: () => void;
}

export const LocationControl = ({ onClose }: LocationControlProps) => {
  const currentController = useCurrentController();
  const controllers = useControllerList();

  const dropDownOptions: SidebarLocationControlOption[] = controllers
    .map((c) => ({
      value: c.name,
      label: c.address,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const navigate = useNavigate();
  const companyName = useCurrentCompany();

  const changeCurrentController = useCallback(
    (slug: string) => {
      navigate(makeLink(paths.pages.OverviewPage, { controllerName: slug, companyName }));
    },
    [companyName, navigate],
  );

  const handleControllerSelect = (slug: string) => {
    changeCurrentController(slug);
    onClose();
  };

  return (
    <SidebarLocationControl
      aria-label="Choose a location"
      value={currentController}
      onValueChange={(key) => handleControllerSelect(key as string)}
    >
      {dropDownOptions.map((item) => (
        <Item key={item.value}>{item.label}</Item>
      ))}
    </SidebarLocationControl>
  );
};
