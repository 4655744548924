import {
  Alert,
  Drawer,
  DrawerContent,
  DrawerControls,
  DrawerHeader,
  DrawerTitle,
} from '@meterup/metric';
import { ErrorBoundary } from '@sentry/react';
import React from 'react';

import { CloseDrawerButton } from '../../CloseDrawerButton';
import { RefreshOrContactSupportBoilerplate } from '../../ErrorFallback/ErrorFallback';
import { NetworkInfo } from './NetworkInfo';

export const AddClientDrawer = () => (
  <Drawer>
    <DrawerHeader>
      <DrawerTitle>Wireless info</DrawerTitle>
      <DrawerControls>
        <CloseDrawerButton />
      </DrawerControls>
    </DrawerHeader>
    <DrawerContent>
      <ErrorBoundary
        fallback={
          <Alert
            heading="Unexpected error"
            copy={
              <>
                We were unable to retrieve your network information.{' '}
                <RefreshOrContactSupportBoilerplate />
              </>
            }
          />
        }
      >
        <NetworkInfo />
      </ErrorBoundary>
    </DrawerContent>
  </Drawer>
);
