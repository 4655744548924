import React from 'react';

import Box from '../../utilities/Box/Box';
import { space } from '../../utilities/shared/sizes';
import Text from '../../utilities/Text/Text';

export type ShortcutKeyProps = {
  children: React.ReactNode;
};

export const ShortcutKey: React.FunctionComponent<ShortcutKeyProps> = ({ children }) => (
  <Box
    width="fit-content"
    display="inline-flex"
    padding={{ x: space(4), y: space(2) }}
    bg={{ light: 'brand-50', dark: 'brand-600' }}
    radius={{ all: 4 }}
  >
    <Text
      color={{ dark: 'brand-50', light: 'brand-800' }}
      lineHeight={12}
      size={11}
      variant="tabular"
      weight="medium"
    >
      {children}
    </Text>
  </Box>
);

export default ShortcutKey;
