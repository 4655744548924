import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { isDefinedAndNotEmpty, ISPStatusBadge } from '@meterup/common';
import { Body2, Small2 } from '@meterup/metric';
import React from 'react';
import { useQuery } from 'react-query';

import { fetchNetworkISPInfo, fetchProviders } from '../../../../api/api';
import { Page, PageHeader, PageSection, PageTitle } from '../../../../components/Page/Page';
import { UploadDownloadStat } from '../../../../components/Settings/InternetWiFi/UploadDownloadStat';
import { useCurrentController } from '../../../../providers/CurrentControllerProvider';
import { colors, fontWeights, shadows, styled } from '../../../../stitches';

const ConnectionGrid = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'minmax(80px, 120px) 1fr minmax(140px, max-content)',
  alignItems: 'stretch',
});

const ConnectionRow = styled('div', {
  display: 'contents',
});

const ConnectionCell = styled('div', Body2, {
  display: 'flex',
  alignItems: 'center',
  padding: '$16',
  boxShadow: shadows.fenceBottomLight,
  '&:first-child': {
    paddingLeft: '$20',
  },
  '&:last-child': {
    paddingRight: '$20',
  },
});

const Stat = styled('div', {
  vStack: '$4',
  alignItems: 'stretch',
});

const PrimaryStatLabel = styled('div', Body2, {
  fontWeight: fontWeights.medium,
  color: colors['gray-700'],
});

const StatLabel = styled('div', Small2);

const StatValue = styled('div', {
  hStack: 0,
});

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/controller/:controllerName/isps',
});

export default function ISPListPage() {
  const controller = useCurrentController();

  const providers =
    useQuery('providers', fetchProviders, {
      suspense: true,
    }).data ?? [];

  const connections =
    useQuery(['network_isp_info', controller], () => fetchNetworkISPInfo(controller), {
      suspense: true,
    }).data ?? [];

  return (
    <Page>
      {connections.length > 0 && (
        <PageSection>
          <PageHeader>
            <PageTitle>Internet connections</PageTitle>
          </PageHeader>
          <ConnectionGrid>
            {connections.map((plan) => {
              const path = providers.find((p) => p.sid === plan.provider)?.path;
              return (
                <ConnectionRow key={plan.sid}>
                  <ConnectionCell>
                    {isDefinedAndNotEmpty(path) && (
                      <img src={`//public.meter.com${path}`} alt={plan.provider} />
                    )}
                  </ConnectionCell>
                  <ConnectionCell>
                    <Stat>
                      <PrimaryStatLabel>{plan.provider_name}</PrimaryStatLabel>
                      <StatValue>
                        <ISPStatusBadge status={plan.status} />
                      </StatValue>
                    </Stat>
                  </ConnectionCell>
                  <ConnectionCell>
                    <Stat>
                      <StatLabel>Bandwidth</StatLabel>
                      <StatValue>
                        <UploadDownloadStat
                          downloadKbps={plan.download_kbps}
                          uploadKbps={plan.upload_kbps}
                        />
                      </StatValue>
                    </Stat>
                  </ConnectionCell>
                </ConnectionRow>
              );
            })}
          </ConnectionGrid>
        </PageSection>
      )}
    </Page>
  );
}
