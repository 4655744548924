import { useQuery } from 'react-query';

import { fetchVPNClientsWithUserLogs } from '../../../../../api/vpn';
import { Page } from '../../../../../components/Page/Page';
import { VPNClientsList } from '../../../../../components/VPNClientsList';
import { useCurrentCompany } from '../../../../../providers/CurrentCompanyProvider';
import { useCurrentController } from '../../../../../providers/CurrentControllerProvider';

export const Meta = () => ({
  path: '/org/:companyName/controller/:controllerName/vpn',
  layout: 'NetworkLayout',
});

export default function VPNListPage() {
  const controller = useCurrentController();
  const company = useCurrentCompany();

  const vpnClientsWithUserLogs =
    useQuery(
      ['controller', controller, 'vpn-clients'],
      () => fetchVPNClientsWithUserLogs(controller, company),
      {
        suspense: true,
      },
    ).data ?? [];

  return (
    <Page css={{ gap: 0 }}>
      <VPNClientsList clients={vpnClientsWithUserLogs} />
    </Page>
  );
}
