import { colors, styled } from '../../stitches.config';

const SidebarDivider = styled('div', {
  position: 'relative',
  height: 0,
  width: '100%',
  overflow: 'visible',
  paddingX: '$12',
  '&::before': {
    content: '',
    position: 'absolute',
    top: 0,
    width: 'calc(100% - 24px)',
    borderBottom: `1px solid ${colors['gray-100']}`,
  },
});

export default SidebarDivider;
