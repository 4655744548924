import { checkDefinedOrThrow } from '@meterup/common';
import React from 'react';

import { Page } from '../../../../../components/Page/Page';
import {
  VLANClientsWidget,
  VLANDHCPWidget,
  VLANTaggingWidget,
} from '../../../../../components/vlans';
import { paths } from '../../../../../constants';
import { useVLANConfigModel } from '../../../../../hooks/useVLANConfigModel';
import { Nav } from '../../../../../nav';
import { useCurrentController } from '../../../../../providers/CurrentControllerProvider';
import { styled } from '../../../../../stitches';

const SplitContainer = styled('div', {
  vStack: '$20',
  '@lg': {
    hStack: '$16',
    alignItems: 'flex-start',
  },
});

const Column = styled('div', {
  vStack: '$20',
  alignItems: 'stretch',
  width: '100%',
  '@lg': {
    width: '50%',
  },
});

export const Meta = () => ({
  path: '/org/:companyName/controller/:controllerName/vlans/:vlanName',
});

export default function VLANDetailPage() {
  const controllerName = useCurrentController();
  const { vlanName } = checkDefinedOrThrow(
    Nav.useRegionParams('root', paths.pages.VLANDetailPage),
  )!;

  const vlan = useVLANConfigModel(controllerName, vlanName);

  return (
    <Page css={{ padding: '$20' }}>
      <SplitContainer>
        <Column>
          <VLANTaggingWidget vlan={vlan} />
          <VLANClientsWidget vlan={vlan} />
        </Column>
        <Column>
          <VLANDHCPWidget vlan={vlan} />
        </Column>
      </SplitContainer>
    </Page>
  );
}
