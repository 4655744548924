import type { MeterV2WirelessAccessPoint, MeterV2WirelessServiceSet } from '@meterup/config';
import { checkDefinedOrThrow } from '@meterup/common';
import { Badge, Button, EmptyState } from '@meterup/metric';
import React from 'react';

import { Box } from '../../../../../components/Box';
import { Page } from '../../../../../components/Page/Page';
import { ReactRouterLink } from '../../../../../components/ReactRouterLink';
import { AutoTable2 } from '../../../../../components/Table/AutoTable2';
import { createColumnBuilder } from '../../../../../components/Table/createColumnBuilder';
import { paths } from '../../../../../constants';
import { useCloseDrawerCallback } from '../../../../../hooks/useCloseDrawerCallback';
import { useControllerConfig } from '../../../../../hooks/useControllerConfig';
import { Nav } from '../../../../../nav';
import { useCurrentCompany } from '../../../../../providers/CurrentCompanyProvider';
import { useCurrentController } from '../../../../../providers/CurrentControllerProvider';
import { makeDrawerLink } from '../../../../../utils/main_and_drawer_navigation';

interface ServiceSetAndAPs {
  serviceSet: MeterV2WirelessServiceSet;
  aps: MeterV2WirelessAccessPoint[];
  countOfAllAPs: number;
  countOfAPsBroadcastingSSID: number;
}

const builder = createColumnBuilder<ServiceSetAndAPs>();

const columns = [
  builder.display({
    meta: {
      sizingMode: 'fit-min',
    },
    cell: (d) => (
      <Badge
        arrangement="hidden-label"
        icon={d.row.serviceSet.hidden ? 'eyeClosed' : 'eyeOpen'}
        variant={d.row.serviceSet.hidden ? 'neutral' : 'positive'}
        size="small"
        ends="pill"
      >
        {d.row.serviceSet.hidden ? 'SSID hidden' : 'SSID visible'}
      </Badge>
    ),
  }),
  builder.data((d) => d.serviceSet.ssid, {
    header: 'SSID',
    meta: {
      isLeading: true,
    },
  }),
  builder.display({
    header: 'Standard',
    cell: () => (
      <Badge size="small" ends="card">
        WPA2
      </Badge>
    ),
  }),
  builder.data((d) => Array.from(d.serviceSet.getSortedBands()).join(', '), {
    header: 'Bands (GHz)',
    cell: (d) => (
      <Box css={{ hStack: '$4' }}>
        {d.row.serviceSet
          .getSortedBands()
          .map((b) => b.replace(' GHz', ''))
          .map((b) => (
            <Badge key={b} size="small" ends="card">
              {b}
            </Badge>
          ))}
      </Box>
    ),
  }),
  builder.data(
    (d) =>
      d.countOfAPsBroadcastingSSID === d.countOfAllAPs
        ? 'All'
        : `${d.countOfAPsBroadcastingSSID} / ${d.countOfAllAPs}`,
    {
      header: 'Access points',
      cell: (d) => (
        <Badge size="small" ends="card">
          {d.value}
        </Badge>
      ),
    },
  ),
];

export const Meta = () => ({
  path: '/org/:companyName/controller/:controllerName/vlans/:vlanName/ssids',
});

export default function VLANSSIDListPage() {
  const { vlanName } = checkDefinedOrThrow(
    Nav.useRegionParams('root', paths.pages.VLANSSIDListPage),
  );

  const controllerName = useCurrentController();
  const companyName = useCurrentCompany();

  const model = useControllerConfig(controllerName);

  const params = Nav.useRegionParams('drawer', paths.drawers.SSIDEditPage);
  const onRowDeselect = useCloseDrawerCallback();

  const createSSIDLink = makeDrawerLink(window.location, paths.drawers.SSIDCreatePage, {
    controllerName,
    companyName,
  });

  return (
    <Page>
      {model.getServiceSetsByVLAN(vlanName).length > 0 ? (
        <AutoTable2
          data={model.getServiceSetsByVLAN(vlanName).map((serviceSet) => {
            const aps = model.getAPsBroadcastingServiceSet(serviceSet);
            return {
              serviceSet,
              aps,
              countOfAllAPs: model.accessPoints.length,
              countOfAPsBroadcastingSSID: aps.length,
            };
          })}
          columns={columns}
          onRowDeselect={onRowDeselect}
          isRowSelected={(d) => d.serviceSet.stableId === params?.id}
          getLinkTo={(d) =>
            makeDrawerLink(window.location, paths.drawers.SSIDEditPage, {
              id: d.serviceSet.stableId,
              controllerName,
              companyName,
            })
          }
        />
      ) : (
        <EmptyState
          icon="ssid"
          heading="No SSIDs"
          action={
            <Button
              as={ReactRouterLink}
              to={createSSIDLink}
              variant="tertiary"
              icon="plusCircle"
              arrangement="leading-icon"
              size="small"
            >
              Create a new SSID
            </Button>
          }
        />
      )}
    </Page>
  );
}
