import { activeThemeSelector, colors, darkThemeSelector, styled } from '../../stitches.config';

export const StyledSVG = styled('svg', {
  $$backgroundColor: colors['gray-500'],
  $$glyphColor: colors['gray-50'],
  $$detailColor: colors['gray-200'],
  $$tintColor: colors['gray-300'],

  [darkThemeSelector]: {
    $$backgroundColor: colors['gray-50'],
    $$glyphColor: colors['gray-400'],
    $$detailColor: colors['gray-300'],
    $$tintColor: colors['gray-200'],
  },

  [`${activeThemeSelector}, &.selected`]: {
    $$backgroundColor: colors['brand-700'],
    $$glyphColor: colors.white,
    $$detailColor: colors['brand-100'],
    $$tintColor: colors['brand-200'],

    [darkThemeSelector]: {
      $$backgroundColor: colors['brand-900'],
      $$glyphColor: colors.white,
      $$detailColor: colors['brand-100'],
      $$tintColor: colors['brand-200'],
    },
  },
});

const BasePath = styled('path', {
  transition: 'fill 150ms ease-out',
});

export const Background = styled(BasePath, { fill: '$$backgroundColor' });
export const Glyph = styled(BasePath, { fill: '$$glyphColor' });
export const Detail = styled(BasePath, { fill: '$$detailColor' });
export const Tint = styled(BasePath, { fill: '$$tintColor' });
