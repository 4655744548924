import React from 'react';

import { Background, Detail, Glyph } from '../common';

export const ProductIconConnect = () => (
  <>
    <Background
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16 8C16 10.7289 15.814 13.8076 14.8139 14.8105C13.8139 15.8134 10.2326 16 8.02326 16C5.81394 16 2.23257 15.8134 1.23256 14.8105C0.232559 13.8076 0 10.7289 0 8C0 5.27115 0.232557 2.19243 1.23256 1.1895C2.23257 0.186588 5.81394 0 8.02326 0C10.2326 0 13.814 0.186588 14.814 1.1895C15.814 2.19243 16 5.27115 16 8Z"
      fill="#66687A"
    />
    <Detail d="M10.8584 10.8945H11.5433C11.7955 10.8945 12 10.6943 12 10.4472V9.55275C12 9.30574 11.7955 9.1055 11.5433 9.1055H10.8584C10.7323 9.1055 10.6301 9.00537 10.6301 8.88187V7.64792C10.6301 7.30274 10.2477 7.08767 9.94332 7.2616L5.82653 9.61367C5.52446 9.78626 5.52446 10.2137 5.82653 10.3863L9.94332 12.7384C10.2477 12.9123 10.6301 12.6973 10.6301 12.3521V11.1181C10.6301 10.9946 10.7323 10.8945 10.8584 10.8945Z" />
    <Glyph d="M5.1416 5.1055H4.45664C4.20445 5.1055 4 5.30574 4 5.55275V6.44725C4 6.69426 4.20445 6.8945 4.45664 6.8945H5.1416C5.2677 6.8945 5.36993 6.99462 5.36993 7.11812V8.35207C5.36993 8.69726 5.75223 8.91233 6.05666 8.7384L10.1734 6.38632C10.4755 6.21374 10.4755 5.78626 10.1734 5.61367L6.05666 3.2616C5.75223 3.08767 5.36993 3.30274 5.36993 3.64792V4.88187C5.36993 5.00537 5.2677 5.1055 5.1416 5.1055Z" />
  </>
);

export default ProductIconConnect;
