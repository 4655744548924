import type { RefObject } from 'react';
import type { AriaListBoxOptions } from 'react-aria';
import type { ListProps, ListState } from 'react-stately';
import cx from 'classnames';
import React, { useRef } from 'react';
import { useListBox } from 'react-aria';
import { useListState } from 'react-stately';

import type { ControlSize } from '../Control/types';
import ListBoxOption from './ListBoxOption';

interface ListBoxProps<T extends object> extends AriaListBoxOptions<T>, ListProps<T> {
  listBoxRef?: RefObject<HTMLElement>;
  listBoxState?: ListState<T>;
  size?: ControlSize;
}

function ListBox<T extends object>(props: ListBoxProps<T>) {
  const ref = useRef<HTMLUListElement>(null);
  const state = useListState<T>(props);

  const { size, listBoxRef = ref, listBoxState = state } = props;
  const { listBoxProps } = useListBox<T>(props, listBoxState, listBoxRef);

  const classes = cx('m-overflow-auto m-outline-none m-flex m-flex-col m-gap-y-0.5');

  return (
    <ul {...listBoxProps} ref={listBoxRef as RefObject<any>} className={classes}>
      {Array.from(listBoxState.collection).map((item) => (
        <ListBoxOption key={item.key} item={item} state={listBoxState} size={size} />
      ))}
    </ul>
  );
}

export default ListBox;
