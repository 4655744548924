import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Nav } from '../nav';

export const useCloseDrawerCallback = () => {
  const navigate = useNavigate();
  const location = Nav.useRegionLocation('root');

  return useCallback(() => {
    const newSearch = new URLSearchParams(location.search);
    newSearch.delete('drawer');

    navigate({
      ...location,
      search: newSearch.toString(),
    });
  }, [navigate, location]);
};
