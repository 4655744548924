// @ts-ignore
import commandScore from 'command-score';
import { computed, makeObservable } from 'mobx';

import type { Node } from './nodes/node';
import type { State } from './state';
import { Action, Directory } from './nodes';
import { Root } from './nodes/root';

function defaultFilter(value: string, search: string) {
  return commandScore(value, search);
}
export interface ScoredNode {
  node: Node;
  score: number;
}

type Group = string;

export const NO_GROUP = 'no_group' as Group;

interface FilterConfig {
  filter: (value: string, search: string) => number;
}

export class Filter {
  constructor(
    readonly state: State,
    readonly config: FilterConfig = {
      filter: defaultFilter,
    },
  ) {
    makeObservable(this, {
      results: computed,
      keyedByGroup: computed,
      ordered: computed,
    });
  }

  /**
   * From the currentRoot, return all nested children
   *
   * @returns ScoredNode[]
   */
  get results(): ScoredNode[] {
    const root = this.state.currentRoot;

    const getScore = (node: Node) =>
      this.config.filter(
        node.label.concat(' ', node.synonyms).trim(),
        this.state.ui.search.trim(),
      ) * node.priority;

    const collectChildren = (node: Root | Directory, children: ScoredNode[] = []) => {
      if (!(node instanceof Root) && node !== root) {
        const score = getScore(node);
        children.push({
          node,
          score,
        });
      }

      for (let i = 0; i < node.children.length; i += 1) {
        const child = node.children[i];

        if (child instanceof Action) {
          const score = getScore(child);
          children.push({
            node: child,
            score,
          });
        } else if (child instanceof Directory) {
          collectChildren(child, children);
        }
      }

      return children;
    };

    if (this.state.ui.search.length > 0) {
      const c = collectChildren(root, []);

      return c.filter((child) => child.score > 0);
    }

    // Show top level children only when no query exists
    return root.children.map((child) => ({
      score: 0.99 * child.priority,
      node: child,
    }));
  }

  /**
   * @returns Record<Group, ScoredNode[]>
   */
  get keyedByGroup() {
    return this.results.reduce((accum, curr) => {
      const group: Group = curr.node.group || NO_GROUP;

      if (!accum.get(group)) {
        accum.set(group, []);
      }

      accum.get(group)!.push(curr);

      return accum;
    }, new Map<Group, ScoredNode[]>());
  }

  /**
   * @returns (Group | ScoredNode)[]
   */
  get ordered() {
    const noGroup = this.keyedByGroup.get(NO_GROUP) ?? [];
    const groups = Array.from(this.keyedByGroup.keys()).filter((g) => g !== NO_GROUP);

    const scoredGroups = groups.map((group) => {
      const groupItems = this.keyedByGroup.get(group);
      // Label score is simply the highest item's score within the label.
      const score = Math.max(...groupItems!.map((i) => i.score));
      return {
        group,
        score,
      };
    });

    const sorted = [...noGroup, ...scoredGroups].sort((a, b) => b.score - a.score);

    const ordered: (string | ScoredNode)[] = [];

    for (let i = 0; i < sorted.length; i += 1) {
      const item = sorted[i];
      if ('group' in item) {
        const { group } = item;
        const groupItems = this.keyedByGroup.get(group)!;
        ordered.push(group);
        for (let j = 0; j < groupItems.length; j += 1) {
          const scoredNode = groupItems[j];
          ordered.push(scoredNode);
        }
      } else {
        ordered.push(item);
      }
    }

    return ordered;
  }
}
