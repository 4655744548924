import { useContext, useEffect } from 'react';

import { useCurrentControllerOrNull } from '../hooks/useCurrentControllerOrNull';
import { DefaultControllerContext } from '../providers/DefaultControllerProvider';

export const AutoSaveDefaultControllerEffect = () => {
  const currentController = useCurrentControllerOrNull();
  const { setDefaultControllerSlug } = useContext(DefaultControllerContext);

  useEffect(() => {
    if (currentController) {
      setDefaultControllerSlug(currentController);
    }
  }, [currentController, setDefaultControllerSlug]);

  return null;
};
