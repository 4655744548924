import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import {
  checkDefinedOrThrow,
  ClientStatusIcon,
  DetailPageLayouts,
  expectDefinedOrThrow,
  getManufacturerIconName,
  ResourceNotFoundError,
} from '@meterup/common';
import { Heading2 } from '@meterup/metric';
import { orderBy } from 'lodash';
import oui from 'oui';
import React from 'react';
import { useQuery } from 'react-query';
import { Link as ReactRouterLink } from 'react-router-dom';

import { fetchClientConnectionHistory } from '../../../../../api/api';
import { DetailPageLayout } from '../../../../../components/DetailPageLayout';
import { paths } from '../../../../../constants';
import { Nav } from '../../../../../nav';
import { useCurrentCompany } from '../../../../../providers/CurrentCompanyProvider';
import { useCurrentController } from '../../../../../providers/CurrentControllerProvider';
import { clientNameOrNull, getClientConnectionStatus } from '../../../../../utils/clientLists';
import { makeLink } from '../../../../../utils/main_and_drawer_navigation';

const ClientDetailPageHeader = () => {
  const controllerName = useCurrentController();
  const companyName = useCurrentCompany();
  const { macAddress } = checkDefinedOrThrow(
    Nav.useRegionParams('root', paths.pages.ClientDetailPage),
  );

  const { data: clientHistory } =
    useQuery(
      ['client_history', macAddress],
      () => fetchClientConnectionHistory(controllerName, macAddress),
      {
        suspense: true,
      },
    ) ?? [];

  const client = orderBy(clientHistory, (d) => d.last_seen, 'desc')[0] ?? null;

  expectDefinedOrThrow(client, new ResourceNotFoundError('Client not found'));

  return (
    <DetailPageLayouts.Container>
      <DetailPageLayouts.HeaderContent>
        <ReactRouterLink
          to={makeLink(paths.pages.ClientDetailPage, {
            macAddress,
            companyName,
            controllerName,
          })}
        >
          <ClientStatusIcon
            value={getClientConnectionStatus(client)}
            manufacturer={getManufacturerIconName(oui(client.mac_address)?.toLowerCase() ?? '')}
          />
        </ReactRouterLink>
        <DetailPageLayouts.HeadingAndTabs>
          <Heading2>{clientNameOrNull(client) ?? client.mac_address ?? '-'}</Heading2>
        </DetailPageLayouts.HeadingAndTabs>
      </DetailPageLayouts.HeaderContent>
    </DetailPageLayouts.Container>
  );
};

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/controller/:controllerName/clients/:macAddress',
  layout: 'SidebarLayout',
});

export default function ClientDetailLayout() {
  return <DetailPageLayout header={<ClientDetailPageHeader />} />;
}
