import { useEffect, useRef, useState } from 'react';

/**
 * Allows toggling a boolean value to `true` for a fixed period of time
 * @param timeoutMS Timeout in milliseconds, updating the value resets the timer
 */
export const useTimedToggle = (timeoutMS: number = 1000) => {
  const frameRef = useRef<number | null>(null);
  const [startTime, setStartTime] = useState<number | null>(null);
  // Use state for timeElapsed explicitly to update component as time passes.
  const [timeElapsed, setTimeElapsed] = useState<number | null>(null);

  useEffect(() => {
    const handleFrame = () => {
      if (startTime != null) {
        const delta = Date.now() - startTime;
        setTimeElapsed(delta);
        if (delta >= timeoutMS) {
          setStartTime(null);
          setTimeElapsed(null);
        } else {
          frameRef.current = requestAnimationFrame(handleFrame);
        }
      }
    };

    frameRef.current = requestAnimationFrame(handleFrame);

    return () => {
      if (frameRef.current) {
        cancelAnimationFrame(frameRef.current);
        frameRef.current = null;
      }
    };
  }, [startTime, setTimeElapsed, timeoutMS]);

  const show = () => {
    setStartTime(Date.now());
  };

  const hide = () => {
    setStartTime(null);
  };

  const toggle = () => setStartTime((prev) => (prev ? null : Date.now()));

  return {
    value: startTime != null,
    timeElapsed,
    timeRemaining: timeElapsed != null ? timeoutMS - timeElapsed : 0,
    timeout: timeoutMS,
    progress: timeElapsed != null ? timeElapsed / timeoutMS : 0,
    show,
    hide,
    toggle,
  };
};
