import { orderBy } from 'lodash';
import { useQuery } from 'react-query';

import { fetchClientConnectionHistory } from '../api/api';

export const useClientDataByMAC = (controllerName: string, macAddress: string) => {
  const { data: clientHistory } =
    useQuery(
      ['client_history', macAddress],
      () => fetchClientConnectionHistory(controllerName, macAddress),
      {
        suspense: true,
      },
    ) ?? [];

  return orderBy(clientHistory, (d) => d.last_seen, 'desc')[0] ?? null;
};
