import { expectDefinedOrThrow, HTTPStatusCode } from '@meterup/common';
import { isAxiosError } from 'axios';
import React, { createContext, useContext } from 'react';
import { useQuery } from 'react-query';

import type { IdentityData } from '../api/types';
import { getIdentity } from '../api/api';
import { UnauthenticatedError } from '../errors';
import { COOKIE_NAME } from '../utils/cookies';
import { redirectToSignInPage } from '../utils/redirectToSignInPage';

async function fetchIdentityOrRedirect(returnPath: string): Promise<IdentityData> {
  // NOTE: We're merely checking if the cookie is present to constitute a
  // "session". If it is not set, portal will set it.
  if (!document.cookie.includes(COOKIE_NAME)) {
    redirectToSignInPage(returnPath);
  }

  try {
    const identity = await getIdentity();
    if (identity && identity?.permissions?.includes('dashboard')) {
      return identity;
    }

    redirectToSignInPage(returnPath);
  } catch (e) {
    if (isAxiosError(e) && e.status === HTTPStatusCode.Forbidden) {
      redirectToSignInPage(returnPath);
    } else {
      throw e;
    }
  }

  // We never reach this code. It's here to satisfy the type checker.
  return undefined as any;
}

export const IdentityDataContext = createContext<IdentityData>(null as any);

/**
 * Provides basic session info like the user's current identity as well as their
 * currently selected company. If this info is not yet available, this component
 * does not render its children.
 */
export const IdentityDataProvider: React.FC = ({ children }) => {
  const identity = useQuery('identity', () => fetchIdentityOrRedirect(window.location.pathname), {
    suspense: true,
  }).data;

  expectDefinedOrThrow(
    identity,
    new UnauthenticatedError('User is not authenticated and did not redirect to sign in page'),
  );

  return <IdentityDataContext.Provider value={identity}>{children}</IdentityDataContext.Provider>;
};

export const useIdentity = () => useContext(IdentityDataContext);
