import type { BandSize } from '@meterup/metric';
import type { BandVariant } from '@meterup/metric/src/assets/Band/Band';
import { BandWithIcon } from '@meterup/metric';
import React from 'react';

import { styled } from '../stitches';

const Container = styled('div', { position: 'relative', display: 'inline-flex' });

const BadgeRegion = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  bottom: -4,
  left: 0,
  right: 0,
});

export const BandIconAndBadge = ({
  variant,
  size,
  icon,
  badge,
}: {
  icon: React.ReactNode;
  badge: React.ReactNode;
  variant: BandVariant;
  size: BandSize;
}) => (
  <Container>
    <BandWithIcon size={size} variant={variant}>
      {icon}
    </BandWithIcon>
    <BadgeRegion>{badge}</BadgeRegion>
  </Container>
);
