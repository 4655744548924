import { checkDefinedOrThrow, expectDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import {
  Button,
  Drawer,
  DrawerContent,
  DrawerControls,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTriggerButton,
} from '@meterup/metric';
import { Form, Formik } from 'formik';
import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import type { ValidContentFilterRuleData } from './form_data';
import { getRuleForCompany, updateRuleForCompany } from '../../../../../api/api';
import { CloseDrawerButton } from '../../../../../components/CloseDrawerButton';
import { FormikConditional } from '../../../../../components/FormikConditional';
import { notify } from '../../../../../components/Notifications';
import { paths } from '../../../../../constants';
import { useCloseDrawerCallback } from '../../../../../hooks/useCloseDrawerCallback';
import { Nav } from '../../../../../nav';
import { useCurrentCompany } from '../../../../../providers/CurrentCompanyProvider';
import { useCurrentController } from '../../../../../providers/CurrentControllerProvider';
import { styled } from '../../../../../stitches';
import { makeDrawerLink } from '../../../../../utils/main_and_drawer_navigation';
import { withZodSchema } from '../../../../../utils/withZodSchema';
import { ActionField, ContentField, PriorityField } from './fields';
import { fromAPIData, toAPIData, validContentFilterRuleData } from './form_data';

const StyledForm = styled(Form, {
  display: 'contents',
});

export const Meta = () => ({
  path: '/org/:companyName/controller/:controllerName/content-filters/rules/:id',
});

export default function ContentFilterRuleEditPage() {
  const companyName = useCurrentCompany();
  const controllerName = useCurrentController();
  const { id } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.ContentFilterRuleEditPage),
  );

  const rule =
    useQuery(['content_filters', companyName, id], () => getRuleForCompany(companyName, id), {
      suspense: true,
    }).data ?? null;

  expectDefinedOrThrow(rule, new ResourceNotFoundError('Rule not found'));

  const queryClient = useQueryClient();

  const updateRuleMutation = useMutation(
    (data: ValidContentFilterRuleData) => {
      const apiData = toAPIData(data);

      return updateRuleForCompany(companyName, id, apiData);
    },
    {
      onSuccess: async () => {
        notify('Rule updated successfully', { variant: 'positive' });
        await queryClient.invalidateQueries(['content_filters', companyName]);
      },
      onError: () => {
        notify('Failed to update rule', { variant: 'negative' });
      },
    },
  );

  const navigate = useNavigate();
  const closeDrawer = useCloseDrawerCallback();

  return (
    <Formik<ValidContentFilterRuleData>
      validate={withZodSchema(validContentFilterRuleData)}
      initialValues={fromAPIData(rule)}
      onSubmit={(values) => updateRuleMutation.mutate(values)}
      enableReinitialize
    >
      <StyledForm>
        <Drawer>
          <DrawerHeader>
            <DrawerTitle>Edit rule</DrawerTitle>
            <DrawerControls>
              <DropdownMenu>
                <DropdownMenuTriggerButton variant="tertiary" icon="cog" arrangement="hidden-label">
                  Actions
                </DropdownMenuTriggerButton>

                <DropdownMenuContent>
                  <DropdownMenuItem
                    onSelect={() =>
                      navigate(
                        makeDrawerLink(window.location, paths.drawers.ContentFilterRuleRemovePage, {
                          id,
                          companyName,
                          controllerName,
                        }),
                      )
                    }
                  >
                    Remove rule
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
              <CloseDrawerButton />
            </DrawerControls>
          </DrawerHeader>
          <DrawerContent>
            <ContentField />
            <ActionField />
            <PriorityField />
          </DrawerContent>
          <FormikConditional condition={(v, ctx) => ctx.dirty}>
            <DrawerFooter>
              <DrawerControls>
                <Button type="button" variant="secondary" onClick={closeDrawer}>
                  Cancel
                </Button>
                <Button type="submit">Save</Button>
              </DrawerControls>
            </DrawerFooter>
          </FormikConditional>
        </Drawer>
      </StyledForm>
    </Formik>
  );
}
