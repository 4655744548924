import type * as Polymorphic from '@radix-ui/react-polymorphic';
import React from 'react';

import type { IconName } from '../../assets/Icon/Icon';
import type {
  PolymorphicComponentProps,
  PolymorphicRef,
} from '../../utilities/types/polymorphicAsProp';
import Icon from '../../assets/Icon/Icon';
import { FocusRingSelf } from '../../common/focus_rings';
import { colors, darkThemeSelector, fontWeights, styled } from '../../stitches.config';
import { Body2 } from '../../text/Body';
import { Small2 } from '../../text/Small';
import { isDefined } from '../../utilities/isDefined';

export interface TabProps {
  /**
   * Marks the currently active tab.
   * */
  active?: boolean;
  /**
   * Additional annotation such a result count within the tab.
   * */
  annotation?: React.ReactNode;
  /**
   * Written label for the tab.
   * */
  children: React.ReactNode;
  /**
   * Set which icon to display, no value displays no icon.
   */
  icon?: IconName;
  /**
   * Handles the onClick event.
   */
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const Container = styled('button', FocusRingSelf, Body2, {
  padding: '$4 $8',
  borderRadius: '$8',
  fontWeight: fontWeights.medium,
  hStack: '$6',
  alignItems: 'center',
  WebkitAppearance: 'none',
  whiteSpace: 'nowrap',
  variants: {
    hasAnnotation: {
      true: {
        paddingRight: '$6',
      },
    },
    isActive: {
      true: {
        $$annotationBackground: colors.white,
        $$annotationColor: colors['brand-600'],
        $$iconColor: colors.white,
        color: colors.white,
        background: colors['brand-600'],
      },
      false: {
        $$annotationBackground: colors['gray-100'],
        $$annotationColor: colors['gray-700'],
        $$iconColor: colors['gray-600'],
        color: colors['gray-700'],
        background: colors.transparent,
        [darkThemeSelector]: {
          $$annotationBackground: colors['gray-700'],
          $$annotationColor: colors['gray-100'],
          $$iconColor: colors['gray-100'],
          color: colors['gray-50'],
          background: colors.transparent,
        },
        '&:hover': {
          $$annotationBackground: colors['gray-200'],
          $$annotationColor: colors['gray-800'],
          $$iconColor: colors['gray-700'],
          color: colors['gray-800'],
          background: colors['gray-50'],
          [darkThemeSelector]: {
            $$annotationBackground: colors['gray-800'],
            $$annotationColor: colors['gray-100'],
            $$iconColor: colors['gray-50'],
            color: colors.white,
            background: colors['gray-600'],
          },
        },
      },
    },
  },
});

const StyledIcon = styled(Icon, {
  color: '$$iconColor',
  width: '$12',
  height: '$12',
});

const Annotation = styled('div', Small2, {
  justifyContent: 'center',
  transition: 'all 150ms ease-out',
  display: 'flex',
  minWidth: '$20',
  lineHeight: '$20',
  paddingX: '$4',
  borderRadius: '$6',
  background: '$$annotationBackground',
  color: '$$annotationColor',
  fontVariantNumeric: 'tabular-nums',
  fontWeight: fontWeights.medium,
});

export const Tab = React.forwardRef(
  <Tag extends React.ElementType>(
    {
      active = false,
      annotation,
      children,
      icon,
      ...props
    }: PolymorphicComponentProps<Tag, TabProps>,
    forwardedRef: PolymorphicRef<Tag>,
  ) => (
    <Container
      type="button"
      role="tab"
      aria-selected={active ? 'true' : 'false'}
      isActive={active}
      hasAnnotation={isDefined(annotation)}
      {...props}
      ref={forwardedRef}
    >
      {isDefined(icon) && <StyledIcon icon={icon} />}
      {children}
      {isDefined(annotation) && <Annotation>{annotation}</Annotation>}
    </Container>
  ),
) as Polymorphic.ForwardRefComponent<'button', TabProps>;

export default Tab;
