import type { ReactNode } from 'react';
import { Body2, Icon, List, ListItem } from '@meterup/metric';
import * as Collapsible from '@radix-ui/react-collapsible';
import React from 'react';

import { colors, fontWeights, keyframes, styled } from '../stitches';

const CollapsibleListHeading = styled('button', ListItem, Body2, {
  hStack: '$8',
  fontWeight: fontWeights.medium,
  color: colors['gray-700'],
  cursor: 'pointer',
  userSelect: 'none',
  '& > svg': {
    transition: 'transform 75ms ease-out',
    transform: 'rotate(-90deg)',
  },
  '&[data-state="open"]': {
    borderRadiusBottom: 0,
    '& > svg': {
      transform: 'rotate(0)',
    },
  },
});

const open = keyframes({
  from: {
    height: 0,
    overflow: 'hidden',
  },
  to: {
    height: 'var(--radix-collapsible-content-height)',
    overflow: 'visible',
  },
});

const close = keyframes({
  from: {
    height: 'var(--radix-collapsible-content-height)',
    overflow: 'visible',
  },
  to: {
    height: 0,
    overflow: 'hidden',
  },
});

const CollapsibleListContent = styled(List, {
  '&[data-state="open"]': { animation: `${open} 150ms ease-out forwards`, overflow: 'visible' },
  '&[data-state="closed"]': { animation: `${close} 150ms ease-out forwards`, overflow: 'hidden' },
  [`& ${ListItem}:first-child`]: {
    borderRadiusTop: 0,
  },
});

export const CollapsibleList = ({
  title,
  subtitle,
  children,
  defaultOpen = false,
}: {
  title: ReactNode;
  subtitle?: ReactNode;
  children: ReactNode;
  defaultOpen?: boolean;
}) => (
  <Collapsible.Root asChild defaultOpen={defaultOpen}>
    <List>
      <Collapsible.Trigger asChild>
        <CollapsibleListHeading>
          <Icon icon="chevronDown" size={11 as any} />
          <div>{title}</div>
          {subtitle && <Body2 style={{ marginLeft: 'auto' }}>{subtitle}</Body2>}
        </CollapsibleListHeading>
      </Collapsible.Trigger>
      <Collapsible.Content asChild>
        <CollapsibleListContent>{children}</CollapsibleListContent>
      </Collapsible.Content>
    </List>
  </Collapsible.Root>
);
