import {
  DesktopSidebar,
  Drawer,
  Header,
  HeaderLogo,
  HStack,
  Skeleton,
  space,
  VStack,
} from '@meterup/metric';
import React, { useState } from 'react';

import { styled } from '../stitches';
import { Container, ContentContainer, HeaderContainer } from './app_layout';
import { SidebarAndMainLayout } from './SidebarAndMainLayout';

const MainContainer = styled('div', {
  flex: 1,
  overflow: 'auto',
  webkitOverflowScrolling: 'touch',
  variants: {
    drawerVisible: {
      true: {
        display: 'none',
        '@sm': {
          display: 'block',
        },
      },
      false: {},
    },
  },
});

const MainAndDrawerContainer = styled('div', {
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
  overflow: 'auto',
});

export const AppSkeleton = () => {
  const [hasDrawer] = useState(() => window.location.search.includes('drawer'));
  return (
    <Container>
      <HeaderContainer>
        <Header
          navigation={
            <HStack align="center" spacing={space(6)}>
              <Skeleton height={28} width={120} radius={6} />
            </HStack>
          }
          logo={<HeaderLogo />}
          controls={<Skeleton height={28} width={28} radius={6} />}
        />
      </HeaderContainer>
      <ContentContainer>
        <SidebarAndMainLayout
          desktopSidebar={
            <DesktopSidebar>
              <VStack spacing={space(0)}>
                <VStack spacing={space(8)} style={{ padding: 12 }}>
                  <Skeleton height={28} width={200} radius={6} />
                </VStack>
                <VStack spacing={space(8)} style={{ padding: 12 }}>
                  <Skeleton height={12} width={80} radius={6} />
                  <Skeleton height={28} width="100%" radius={6} />
                  <Skeleton height={28} width="100%" radius={6} />
                </VStack>
                <VStack spacing={space(8)} style={{ padding: 12 }}>
                  <Skeleton height={12} width={80} radius={6} />
                  <Skeleton height={28} width="100%" radius={6} />
                </VStack>
              </VStack>
            </DesktopSidebar>
          }
          main={
            <MainAndDrawerContainer>
              <MainContainer drawerVisible={hasDrawer}>
                <div style={{ width: '100%' }}>
                  <VStack spacing={space(8)} style={{ padding: 12 }}>
                    <Skeleton height={28} width={200} radius={6} />
                  </VStack>
                  <VStack spacing={space(8)} style={{ padding: '4px 12px' }}>
                    <Skeleton height={400} width="100%" radius={6} />
                  </VStack>
                </div>
              </MainContainer>
              {hasDrawer && (
                <Drawer>
                  <div style={{ width: '100%' }}>
                    <VStack spacing={space(8)} style={{ padding: 12 }}>
                      <Skeleton height={28} width={200} radius={6} />
                    </VStack>
                    <VStack spacing={space(8)} style={{ padding: '4px 12px' }}>
                      <Skeleton height={120} width="100%" radius={6} />
                    </VStack>
                  </div>
                </Drawer>
              )}
            </MainAndDrawerContainer>
          }
        />
      </ContentContainer>
    </Container>
  );
};
