import type { Location } from 'history';
import { createRedirectRouteFn } from '@meterup/common';
import { useMemo } from 'react';
import drawerRoutes from 'virtual:pagefiles/drawers';
import pageRoutes from 'virtual:pagefiles/pages';

import { paths } from '../constants';
import { Nav } from '../nav';
import { useDefaultCompany } from '../providers/CurrentCompanyProvider';
import { useDefaultController } from '../providers/DefaultControllerProvider';
import { makeLink } from '../utils/main_and_drawer_navigation';

interface RedirectContext {
  companyName: string;
  controllerName: string | null;
}

export const defaultMakeTo = (
  regions: Record<string, Location | null | undefined>,
  path: string | null,
  params: Record<string, string>,
) => Nav.makeTo({ ...regions, root: { pathname: path ? makeLink(path, params) : '/' } });

export const defaultDrawerMakeTo = (
  regions: Record<string, Location | null | undefined>,
  path: string | null,
  params: Record<string, string>,
) => Nav.makeTo({ ...regions, drawer: path ? { pathname: makeLink(path, params) } : null });

export const MainRoutes = () => {
  const companyName = useDefaultCompany();
  const controllerName = useDefaultController();

  const redirectContext: RedirectContext = useMemo(
    () => ({ companyName, controllerName }),
    [companyName, controllerName],
  );

  const regions = Nav.useRegionLocations();

  const createRedirect = useMemo(
    () => createRedirectRouteFn(defaultMakeTo, redirectContext, '/', regions, regions.root),
    [redirectContext, regions],
  );

  return Nav.useRoutes('root', [
    {
      children: [
        createRedirect('/clients', paths.pages.ClientsListPage),
        createRedirect('/clients/:macAddress', paths.pages.ClientDetailPage),
        createRedirect('/devices', paths.pages.AccessPointListPage),
        createRedirect('/devices/:deviceName', paths.pages.AccessPointDetailPage),
        createRedirect('/events', paths.pages.EventsListPage),
        createRedirect('/internet-wifi', paths.pages.InternetAndWirelessPage),
        createRedirect('/isps', paths.pages.ISPListPage),
        createRedirect('/join/:ssid', paths.pages.TabletJoinInstructionsPage),
        createRedirect('/ssids', paths.pages.SSIDListPage),
      ],
    },
    {
      children: pageRoutes,
    },
  ]);
};

export const DrawerRoutes = () => {
  const companyName = useDefaultCompany();
  const controllerName = useDefaultController();

  const redirectContext: RedirectContext = useMemo(
    () => ({ companyName, controllerName }),
    [companyName, controllerName],
  );

  const regions = Nav.useRegionLocations();
  const drawerLocation = Nav.useRegionLocation('drawer');

  const createRedirect = useMemo(
    () =>
      createRedirectRouteFn(defaultDrawerMakeTo, redirectContext, null, regions, drawerLocation),
    [redirectContext, regions, drawerLocation],
  );

  return Nav.useRoutes('drawer', [
    {
      children: [
        createRedirect('/clients/:macAddress', paths.drawers.ClientSummaryPage),
        createRedirect('/clients/:macAddress/rename', paths.drawers.ClientRenamePage),
        createRedirect('/clients/add', paths.drawers.AddClientPage),
        createRedirect('/devices/:deviceName', paths.drawers.AccessPointSummary),
        createRedirect('/ssid/:ssidName', paths.drawers.LegacySSIDDetailsPage),
        createRedirect('/ssids/:id/edit', paths.drawers.SSIDEditPage),
        createRedirect('/ssids/:id/remove', paths.drawers.SSIDRemovePage),
        createRedirect('/ssids/create', paths.drawers.SSIDCreatePage),
      ],
    },
    {
      children: drawerRoutes,
    },
  ]);
};
