import { checkDefinedOrThrow, expectDefinedOrThrow } from '@meterup/common';
import {
  Alert,
  Button,
  Drawer,
  DrawerContent,
  DrawerControls,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from '@meterup/metric';
import React from 'react';

import { CloseDrawerButton } from '../../../../../components/CloseDrawerButton';
import { CancelAndGoBackButton } from '../../../../../components/form_buttons';
import { notify } from '../../../../../components/Notifications';
import { paths } from '../../../../../constants';
import { useCloseDrawerCallback } from '../../../../../hooks/useCloseDrawerCallback';
import { useControllerConfig } from '../../../../../hooks/useControllerConfig';
import { useVLANConfigModel } from '../../../../../hooks/useVLANConfigModel';
import { Nav } from '../../../../../nav';
import { useCurrentController } from '../../../../../providers/CurrentControllerProvider';
import { useRemoveStaticClientMutation } from './mutations';

export const Meta = () => ({
  path: '/org/:companyName/controller/:controllerName/vlans/:vlanName/static/:macAddress/remove',
});

export default function VLANStaticIPRemovePage() {
  const { vlanName, macAddress } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.VLANStaticIPRemovePage),
  );

  const controllerName = useCurrentController();

  const configModel = useControllerConfig(controllerName);
  const vlan = useVLANConfigModel(controllerName, vlanName);

  const staticClient = vlan.getStaticClientByMacAddress(macAddress);

  expectDefinedOrThrow(staticClient, new Error(`Static assignment for "${macAddress}" not found`));

  const mutation = useRemoveStaticClientMutation(controllerName, configModel, vlan, staticClient);
  const closeDrawer = useCloseDrawerCallback();

  return (
    <Drawer>
      <DrawerHeader>
        <DrawerTitle>Remove static IP</DrawerTitle>
        <DrawerControls>
          <CloseDrawerButton />
        </DrawerControls>
      </DrawerHeader>
      <DrawerContent>
        <Alert
          icon="attention"
          heading="Confirmation required"
          copy={`Are you sure you wish to remove the static assignment for ${staticClient.macAddress}?`}
          variant="negative"
        />
      </DrawerContent>
      <DrawerFooter>
        <DrawerControls>
          <CancelAndGoBackButton />
          <Button
            onClick={() =>
              mutation.mutate(undefined, {
                onSuccess: () => {
                  closeDrawer();
                  notify('Removed', { variant: 'positive' });
                },
                onError: () => {
                  notify('Failed to save changes', { variant: 'negative' });
                },
              })
            }
            variant="destructive"
          >
            Remove
          </Button>
        </DrawerControls>
      </DrawerFooter>
    </Drawer>
  );
}
