import { expectDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import {
  Alert,
  CloseDrawerButton,
  Drawer,
  DrawerContent,
  DrawerControls,
  DrawerHeader,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTriggerButton,
} from '@meterup/metric';
import { useState } from 'react';
import { useQuery } from 'react-query';

import type { FixMeLater } from '../../../../types/fixMeLater';
import { fetchVPNClient } from '../../../../api/vpn';
import { CopyableCodeBlock } from '../../../../components/CopyableCodeBlock';
import { paths } from '../../../../constants';
import { useCloseDrawerCallback } from '../../../../hooks/useCloseDrawerCallback';
import { Nav } from '../../../../nav';
import { useCurrentController } from '../../../../providers/CurrentControllerProvider';
import { createWGConf } from '../../../../utils/vpn';
import { DeleteVPNClientDialog } from './VPNClientDelete';
import { VPNSummaryTabs } from './vpnSummary';

export const Meta = () => ({
  path: '/org/:companyName/controller/:controllerName/vpn/:sid/config',
});

export default function VPNClientConfigSummary() {
  const controllerName = useCurrentController();
  const { sid } = Nav.useRegionParams('drawer', paths.drawers.VPNClientSummaryPage)!;

  const vpnClientDetails = useQuery(
    ['controller', controllerName, 'vpn-clients', sid],
    () => fetchVPNClient(controllerName, sid),
    { suspense: true },
  ).data;

  const closeDrawer = useCloseDrawerCallback();
  const [isOpen, setIsOpen] = useState(false);
  const client = vpnClientDetails?.client;
  const server = vpnClientDetails?.server;

  expectDefinedOrThrow(
    vpnClientDetails,
    new ResourceNotFoundError('VPN client response not found'),
  );
  expectDefinedOrThrow(client, new ResourceNotFoundError('VPN client not found'));
  expectDefinedOrThrow(server, new ResourceNotFoundError('VPN client not found'));

  const wgCfg = createWGConf(
    client.ip_address,
    server.endpoint,
    server.port as FixMeLater,
    server.public_key as FixMeLater,
    '$YOUR_PRIVATE_KEY',
  );

  return (
    <Drawer>
      <DrawerHeader>
        <VPNSummaryTabs sid={sid} />
        <DrawerControls>
          <DropdownMenu>
            <DropdownMenuTriggerButton
              arrangement="hidden-label"
              icon="overflowVertical"
              variant="tertiary"
            >
              Actions
            </DropdownMenuTriggerButton>
            <DropdownMenuContent>
              <DropdownMenuItem onSelect={() => setIsOpen(true)}>
                Remove VPN client
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
          <CloseDrawerButton onClick={closeDrawer} />
        </DrawerControls>
      </DrawerHeader>
      <DrawerContent>
        <Alert
          icon="information"
          heading="Your WireGuard-compatible config"
          copy={
            'Make sure you replace "$YOUR_PRIVATE_KEY" with your private key when pasting into WireGuard.'
          }
        />
        <CopyableCodeBlock value={wgCfg} />
      </DrawerContent>
      <DeleteVPNClientDialog
        controllerName={controllerName}
        vpnClientName={client.name}
        vpnClientSid={client.sid}
        isOpen={isOpen}
        onOpenChange={setIsOpen}
      />
    </Drawer>
  );
}
