import type { SortingState } from '@tanstack/react-table';
import { Badge, Button, EmptyState, Small2, SmallMono2, TextInput } from '@meterup/metric';
import { DateTime } from 'luxon';

import type { VPNClientWithUserLogs } from '../api/types';
import { paths } from '../constants';
import { useCloseDrawerCallback } from '../hooks/useCloseDrawerCallback';
import { Nav } from '../nav';
import { useCurrentCompany } from '../providers/CurrentCompanyProvider';
import { useCurrentController } from '../providers/CurrentControllerProvider';
import { useSearchParamsState } from '../providers/SearchParamsStateProvider';
import { makeDrawerLink } from '../utils/main_and_drawer_navigation';
import { isActive } from '../utils/vpnClientLists';
import { PageControls, PageHeader, PageSection } from './Page/Page';
import { ReactRouterLink } from './ReactRouterLink';
import { AutoTable2 } from './Table/AutoTable2';
import { createColumnBuilder } from './Table/createColumnBuilder';
import { createFilterEnumeration } from './Table/createFilterEnumeration';

const builder = createColumnBuilder<VPNClientWithUserLogs>();

const columns = [
  builder.data((d) => `${isActive(d)} ? 'Active' : 'Inactive'}`, {
    header: '',
    minSize: 40,
    maxSize: 40,
    meta: {
      sizingMode: 'fit-min',
      alignment: 'end',
    },
    cell: (p) => (
      <Badge
        arrangement="hidden-label"
        variant={isActive(p.row) ? 'positive' : 'neutral'}
        icon={isActive(p.row) ? 'checkmarkCircle' : 'crossCircle'}
        size="small"
        ends="pill"
      >
        {isActive(p.row) ? 'Active' : 'Inactive'}
      </Badge>
    ),
  }),
  builder.data((row) => row.vpnClient.name, {
    header: 'Name',
    minSize: 120,
    size: 300,
    meta: {
      isLeading: true,
      sizingMode: 'truncate',
    },
  }),
  builder.data((row) => row.user?.email ?? '', {
    header: 'User',
    meta: { sizingMode: 'truncate', isCopyable: true },
  }),
  builder.data((row) => row.vpnClient.ip_address, {
    header: 'IP',
    meta: { sizingMode: 'fit-min', isCopyable: true },
    cell: (props) => <SmallMono2>{props.value}</SmallMono2>,
  }),
  builder.data((row) => row.vpnClient.created_at ?? '', {
    header: `Created`,
    cell: (p) => <Small2>{DateTime.fromISO(p.value).toLocaleString(DateTime.DATETIME_MED)}</Small2>,
  }),
];

const Filters = createFilterEnumeration<VPNClientWithUserLogs>(
  [
    {
      key: 'all',
      label: 'All',
      predicate: () => true,
    },
    {
      key: 'active',
      label: 'Active',
      predicate: isActive,
    },
  ],
  {
    urlKey: 'tab',
  },
);

const EmptyStates = ({
  clientCount,
  filteredClientCount,
  onResetFilters,
}: {
  clientCount: number;
  filteredClientCount: number;
  onResetFilters: () => void;
}) => {
  if (clientCount === 0) {
    return <EmptyState icon="client" heading="There are no VPN clients on this network" />;
  }

  if (filteredClientCount === 0) {
    return (
      <EmptyState
        icon="filter"
        heading="Your filter returned no results"
        action={
          <Button
            variant="tertiary"
            icon="minusCircle"
            arrangement="leading-icon"
            onClick={onResetFilters}
          >
            Reset filters
          </Button>
        }
      />
    );
  }

  return null;
};

export const VPNClientsList = ({
  clients,
  showTabSwitcher = true,
}: {
  clients: VPNClientWithUserLogs[];
  showTabSwitcher?: boolean;
}) => {
  const companyName = useCurrentCompany();
  const controllerName = useCurrentController();
  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.VPNClientSummaryPage);

  const closeDrawer = useCloseDrawerCallback();
  const filteredClients = Filters.useCurrentPredicate(clients);
  const resetFilter = Filters.useResetCallback();

  const [globalFilter, setGlobalFilter] = useSearchParamsState<string>('filter', '');
  const [sortingState, setSortingState] = useSearchParamsState<SortingState>('sort');

  return (
    <>
      <PageHeader>
        {showTabSwitcher && <Filters.TabSwitcher data={clients} />}
        <PageControls>
          <Button
            as={ReactRouterLink}
            to={makeDrawerLink(window.location, paths.drawers.AddClientForm, {
              controllerName,
              companyName,
            })}
            variant="tertiary"
            icon="plusCircle"
            arrangement="leading-icon"
          >
            Add client
          </Button>
          <div style={{ minWidth: 56 }}>
            <TextInput
              aria-label="Filter clients"
              icon="searchScoped"
              value={globalFilter}
              onChange={setGlobalFilter}
            />
          </div>
        </PageControls>
      </PageHeader>
      <PageSection>
        {filteredClients.length > 0 && (
          <AutoTable2
            columns={columns}
            data={filteredClients}
            sortingState={sortingState}
            onChangeSortingState={setSortingState}
            globalFilter={globalFilter}
            getLinkTo={(row) =>
              makeDrawerLink(window.location, paths.drawers.VPNClientSummaryPage, {
                sid: row.vpnClient.sid,
                controllerName,
                companyName,
              })
            }
            isRowSelected={(row) => row.vpnClient.sid === drawerParams?.sid}
            onRowDeselect={closeDrawer}
          />
        )}
        <EmptyStates
          clientCount={clients.length}
          filteredClientCount={filteredClients.length}
          onResetFilters={resetFilter}
        />
      </PageSection>
    </>
  );
};
