import type { MeterControllerConfig, MeterV1NetworkVLAN } from '@meterup/config';
import { expectDefinedOrThrow } from '@meterup/common';
import { MeterV1NetworkVLANStaticClient } from '@meterup/config';
import produce from 'immer';
import { useMutation, useQueryClient } from 'react-query';

import type { ValidStaticClient } from './form_data';
import { saveConfigDiff } from '../../../../../api/saveConfigDiff';

export const upsertStaticClientOnVlan = (
  configModel: MeterControllerConfig,
  vlan: MeterV1NetworkVLAN,
  staticClient: MeterV1NetworkVLANStaticClient,
) =>
  produce(configModel, (draft) => {
    const found = draft.vlans.find((v) => v.name === vlan.name);

    expectDefinedOrThrow(found, new Error(`VLAN ${vlan.name} not defined`));

    const staticClientIndex = found.staticClients.findIndex(
      (sc) => sc.macAddress === staticClient.macAddress,
    );

    if (staticClientIndex === -1) {
      found.staticClients.push(staticClient);
    } else {
      found.staticClients[staticClientIndex] = staticClient;
    }
  });

export const deleteStaticClientOnVlan = (
  configModel: MeterControllerConfig,
  vlan: MeterV1NetworkVLAN,
  staticClient: MeterV1NetworkVLANStaticClient,
) =>
  produce(configModel, (draft) => {
    const found = draft.vlans.find((v) => v.name === vlan.name);

    expectDefinedOrThrow(found, new Error(`VLAN ${vlan.name} not defined`));

    const staticClientIndex = found.staticClients.findIndex(
      (sc) => sc.macAddress === staticClient.macAddress,
    );

    if (staticClientIndex !== -1) {
      found.staticClients.splice(staticClientIndex, 1);
    }
  });

export function useUpsertStaticClientMutation(
  controller: string,
  configModel: MeterControllerConfig,
  vlanModel: MeterV1NetworkVLAN,
) {
  const queryClient = useQueryClient();

  return useMutation(
    async (values: ValidStaticClient) => {
      const staticClient = MeterV1NetworkVLANStaticClient.fromProps(
        values.mac,
        values.ip,
        values.name,
      );

      const updatedModel = upsertStaticClientOnVlan(configModel, vlanModel, staticClient);

      await saveConfigDiff(controller, updatedModel.toJSON(), configModel.toJSON());
    },
    {
      onSuccess: async () => {
        await queryClient.refetchQueries(['controller', controller, 'config']);
      },
    },
  );
}

export function useRemoveStaticClientMutation(
  controller: string,
  configModel: MeterControllerConfig,
  vlanModel: MeterV1NetworkVLAN,
  staticClient: MeterV1NetworkVLANStaticClient,
) {
  const queryClient = useQueryClient();

  return useMutation(
    async () => {
      const updatedModel = deleteStaticClientOnVlan(configModel, vlanModel, staticClient);

      await saveConfigDiff(controller, updatedModel.toJSON(), configModel.toJSON());
    },
    {
      onSettled: () => {
        queryClient.refetchQueries(['controller', controller, 'config']);
      },
    },
  );
}
