import type { api } from '@meterup/proto';
import {
  bytesPerSecond,
  formatDataRateBytes,
  isDefinedAndNotEmpty,
  Tooltip,
} from '@meterup/common';
import {
  BodyMono2,
  CopyCapsule,
  HStack,
  MinList,
  MinListItemHeader,
  MinListItemLabel,
  MinListItemPair,
  MinListItemValue,
  MinListTitle,
  SmallMono2,
} from '@meterup/metric';
import { get } from 'lodash';
import { DateTime } from 'luxon';

import { timestampToISO } from '../utils/timestamp';
import { CopyToClipboardButtonMinimal } from './CopyToClipboardButton';

export const VPNClientDetailWidget = ({ vpnClient }: { vpnClient: api.VPNClient }) => (
  <MinList>
    <MinListItemHeader icon="client">
      <MinListTitle>Details</MinListTitle>
    </MinListItemHeader>
    <MinListItemPair>
      <MinListItemLabel>Name</MinListItemLabel>
      <MinListItemValue>{vpnClient.name}</MinListItemValue>
    </MinListItemPair>
    <MinListItemPair>
      <MinListItemLabel>IP address</MinListItemLabel>
      <MinListItemValue>
        <BodyMono2 style={{ wordBreak: 'break-all' }}>
          <CopyCapsule
            textValue={vpnClient.ip_address?.toString() ?? ''}
            aria-label="Copy VPN client IP address"
            arrangement="leading-icon"
          >
            {vpnClient.ip_address}
          </CopyCapsule>
        </BodyMono2>
      </MinListItemValue>
    </MinListItemPair>
    <MinListItemPair>
      <MinListItemLabel>Public key</MinListItemLabel>
      <MinListItemValue>
        <BodyMono2 style={{ wordBreak: 'break-all', textAlign: 'right' }}>
          <CopyCapsule
            textValue={vpnClient.public_key?.toString() ?? ''}
            aria-label="Copy VPN client public key"
            arrangement="leading-icon"
          >
            {vpnClient.public_key}
          </CopyCapsule>
        </BodyMono2>
      </MinListItemValue>
    </MinListItemPair>
    {isDefinedAndNotEmpty(vpnClient.created_at) && (
      <MinListItemPair>
        <MinListItemLabel>Created at</MinListItemLabel>
        <MinListItemValue>
          {DateTime.fromISO(vpnClient.created_at).toLocaleString(DateTime.DATETIME_MED)}
        </MinListItemValue>
      </MinListItemPair>
    )}
    {isDefinedAndNotEmpty(vpnClient.created_by) && (
      <MinListItemPair>
        <MinListItemLabel>Created by</MinListItemLabel>
        <MinListItemValue>
          <BodyMono2 style={{ wordBreak: 'break-all' }}>{vpnClient.created_by}</BodyMono2>
        </MinListItemValue>
      </MinListItemPair>
    )}
  </MinList>
);

export const VPNClientConfigWidget = ({ wgConfig }: { wgConfig: string }) => (
  <MinList>
    <MinListItemHeader icon="wrench">
      <HStack justify="between" align="center" width="full">
        <MinListTitle>WireGuard Config</MinListTitle>
        <CopyToClipboardButtonMinimal text={wgConfig} />
      </HStack>
    </MinListItemHeader>
    <MinListItemPair>
      <MinListItemValue>
        <SmallMono2
          style={{ wordBreak: 'break-all', whiteSpace: 'pre-wrap', display: 'block' }}
          as="pre"
        >
          {wgConfig}
        </SmallMono2>
      </MinListItemValue>
    </MinListItemPair>
  </MinList>
);

export const VPNClientLogsWidget = ({ peers, logs }: { peers: any; logs: any }) => {
  const observedAt = get(peers, ['observed_at']);
  const lastHandshake = get(logs, ['last_handshake_time']);
  return (
    <MinList>
      <MinListItemHeader icon="log">
        <MinListTitle>Logs</MinListTitle>
      </MinListItemHeader>
      <MinListItemPair>
        <MinListItemLabel>Rx bytes</MinListItemLabel>
        <MinListItemValue>
          <BodyMono2 style={{ wordBreak: 'break-all' }}>
            {formatDataRateBytes(logs.rx_bytes, bytesPerSecond)}
          </BodyMono2>
        </MinListItemValue>
      </MinListItemPair>
      <MinListItemPair>
        <MinListItemLabel>Tx bytes</MinListItemLabel>
        <MinListItemValue>
          <MinListItemValue>
            <BodyMono2 style={{ wordBreak: 'break-all' }}>
              {formatDataRateBytes(logs.tx_bytes, bytesPerSecond)}
            </BodyMono2>
          </MinListItemValue>
        </MinListItemValue>
      </MinListItemPair>
      <MinListItemPair>
        <MinListItemLabel>Last handshake time</MinListItemLabel>
        <MinListItemValue>
          {lastHandshake !== 0 &&
            DateTime.fromISO(timestampToISO(lastHandshake)).toLocaleString(DateTime.DATETIME_MED)}
          {lastHandshake === 0 && (
            <Tooltip content="This peer has not completed a handshake since the VPN service was last restarted.">
              Not since last restart
            </Tooltip>
          )}
        </MinListItemValue>
      </MinListItemPair>
      <MinListItemPair>
        <MinListItemLabel>Observed at</MinListItemLabel>
        <MinListItemValue>
          {observedAt !== 0 && DateTime.fromISO(observedAt).toLocaleString(DateTime.DATETIME_MED)}
        </MinListItemValue>
      </MinListItemPair>
    </MinList>
  );
};
