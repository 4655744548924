import { ErrorBoundary } from '@sentry/react';
import React, { Suspense } from 'react';
import { QueryErrorResetBoundary } from 'react-query';

import type { ErrorFallbackProps } from './ErrorFallback/ErrorFallback';
import { Nav } from '../nav';
import { styled } from '../stitches';
import { LoadingDrawer } from './Drawers/LoadingDrawer';
import { ErrorFallback, ErrorFallbackDrawer } from './ErrorFallback/ErrorFallback';
import { LoadingFallback } from './LoadingFallback';

const MainContainer = styled('div', {
  flex: 1,
  overflow: 'auto',
  webkitOverflowScrolling: 'touch',
  variants: {
    drawerVisible: {
      true: {
        display: 'none',
        '@sm': {
          display: 'block',
        },
      },
      false: {},
    },
  },
});

const ErrorFallbackDrawerInContainer = (props: ErrorFallbackProps) => (
  <ErrorFallbackDrawer {...props} />
);

const MainAndDrawerContainer = styled('div', {
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
  overflow: 'auto',
});

export const MainAndDrawerLayout = ({
  main,
  drawer,
}: {
  main: React.ReactNode;
  drawer?: React.ReactNode;
}) => {
  const location = Nav.useRegionLocation('root');
  const drawerLocation = Nav.useRegionLocation('drawer');
  return (
    <MainAndDrawerContainer>
      <MainContainer>
        <QueryErrorResetBoundary
          // TRICKY: Pass key to the error boundary to force re-render when route changes
          key={location.pathname}
        >
          {({ reset }) => (
            <ErrorBoundary onReset={reset} fallback={ErrorFallback}>
              <Suspense fallback={<LoadingFallback />}>{main}</Suspense>
            </ErrorBoundary>
          )}
        </QueryErrorResetBoundary>
      </MainContainer>
      <ErrorBoundary key={drawerLocation?.pathname ?? ''} fallback={ErrorFallbackDrawerInContainer}>
        <Suspense fallback={<LoadingDrawer />}>{drawer}</Suspense>
      </ErrorBoundary>
    </MainAndDrawerContainer>
  );
};
