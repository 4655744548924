import { useQuery } from 'react-query';

import { fetchControllers } from '../api/api';
import { useCurrentCompanyOrDefault } from '../providers/CurrentCompanyProvider';
import { useCurrentCompanyFromPathOrNull } from './useCurrentCompanyFromPath';

export const useControllersOnCurrentCompany = () => {
  const companyName = useCurrentCompanyOrDefault();

  return useQuery(
    ['controllers', companyName],
    () => (companyName ? fetchControllers(companyName) : []),
    {
      suspense: true,
    },
  ).data;
};

export const useControllersOnCurrentCompanyFromPath = () => {
  const companyName = useCurrentCompanyFromPathOrNull()?.company_slug;

  return useQuery(
    ['controllers', companyName],
    () => (companyName ? fetchControllers(companyName) : []),
    {
      suspense: true,
    },
  ).data;
};
