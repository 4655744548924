import { delay } from './delay';

export async function retry<T extends any>(
  fn: () => Promise<T>,
  retriesLeft: number = 5,
  intervalMS: number = 1000,
  exponential: boolean = false,
): Promise<T> {
  try {
    return await fn();
  } catch (error) {
    if (retriesLeft) {
      await delay(intervalMS);
      return retry(fn, retriesLeft - 1, exponential ? intervalMS * 2 : intervalMS, exponential);
    }
    throw new Error('Max retries reached');
  }
}
