import {
  breakpoints,
  darkThemeSelector,
  focusVisibleClassName,
  focusVisibleSelector,
  theme,
} from '@meterup/metric';

export const {
  css,
  styled,
  keyframes,
  globalCss,
  theme: { shadows, colors, fonts, fontWeights },
} = theme;

export { breakpoints, darkThemeSelector, focusVisibleClassName, focusVisibleSelector };
