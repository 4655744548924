import type * as Polymorphic from '@radix-ui/react-polymorphic';
import React from 'react';

import type { IconName } from '../../assets/Icon/Icon';
import type {
  PolymorphicComponentProps,
  PolymorphicRef,
} from '../../utilities/types/polymorphicAsProp';
import Icon from '../../assets/Icon/Icon';
import { colors, darkThemeSelector, fontWeights, shadows, styled } from '../../stitches.config';
import { Body2 } from '../../text/Body';
import { Small2 } from '../../text/Small';
import { isDefined } from '../../utilities/isDefined';

export interface Tab2Props {
  /**
   * Marks the currently active tab.
   * */
  active?: boolean;
  /**
   * Additional annotation such a result count within the tab.
   * */
  annotation?: React.ReactNode;
  /**
   * Determines the size of the tab
   * */
  size?: 'small' | 'medium';
  /**
   * Written label for the tab.
   * */
  children: React.ReactNode;
  /**
   * Set which icon to display, no value displays no icon.
   */
  icon?: IconName;
  /**
   * Handles the onClick event.
   */
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const Container = styled('button', Body2, {
  position: 'relative',
  $$focusRing: shadows.none,
  '&:focus': {
    outline: 'none',
    $$focusRing: shadows.focusRingLight,
    [darkThemeSelector]: {
      $$focusRing: shadows.focusRingDark,
    },
  },
});

const Content = styled('div', {
  position: 'relative',
  top: '50%',
  transform: 'translateY(-50%)',
  display: 'inline-flex',
  transition: 'all 150ms ease-out',
  fontWeight: fontWeights.medium,
  hStack: '$6',
  alignItems: 'center',
  whiteSpace: 'nowrap',
  boxShadow: '$$focusRing',
  variants: {
    hasAnnotation: {
      true: {
        paddingRight: '$4',
      },
    },
    size: {
      small: {
        padding: '0px $6',
        fontSize: '$12',
        borderRadius: '$6',
      },
      medium: {
        padding: '$4 $6',
        fontSize: '$14',
        borderRadius: '$8',
      },
    },
    isActive: {
      true: {
        $$annotationBackground: colors['brand-50'],
        $$annotationColor: colors['brand-600'],
        $$iconColor: colors['brand-700'],
        color: colors['brand-700'],
        background: colors.transparent,
        [darkThemeSelector]: {
          color: colors['brand-50'],
          $$iconColor: colors['brand-50'],
        },
      },
      false: {
        $$annotationBackground: colors['gray-100'],
        $$annotationColor: colors['gray-700'],
        $$iconColor: colors['gray-600'],
        color: colors['gray-700'],
        background: colors.transparent,
        [darkThemeSelector]: {
          $$annotationBackground: colors['gray-700'],
          $$annotationColor: colors['gray-100'],
          $$iconColor: colors['gray-100'],
          color: colors['gray-50'],
          background: colors.transparent,
        },
        '&:hover': {
          $$annotationBackground: colors['gray-200'],
          $$annotationColor: colors['gray-800'],
          $$iconColor: colors['gray-700'],
          color: colors['gray-800'],
          background: colors.transparent,
          [darkThemeSelector]: {
            $$annotationBackground: colors['gray-600'],
            $$annotationColor: colors['gray-100'],
            $$iconColor: colors['gray-50'],
            color: colors.white,
            background: colors.transparent,
          },
        },
      },
    },
  },
});

const StyledIcon = styled(Icon, {
  color: '$$iconColor',
  width: '$12',
  height: '$12',
});

const Annotation = styled('div', Small2, {
  justifyContent: 'center',
  transition: 'all 150ms ease-out',
  display: 'flex',
  minWidth: '$20',
  lineHeight: '$20',
  paddingX: '$4',
  borderRadius: '$6',
  background: '$$annotationBackground',
  color: '$$annotationColor',
  fontVariantNumeric: 'tabular-nums',
  fontWeight: fontWeights.medium,
});

const Underline = styled('div', {
  position: 'absolute',
  right: 0,
  left: 0,
  bottom: 0,
  borderRadius: '8px 8px 0px 0px',
  height: '4px',
  variants: {
    isActive: {
      true: {
        background: colors['brand-600'],
      },
      false: {
        background: colors.transparent,
      },
    },
  },
});

export const Tab2 = React.forwardRef(
  <Tag extends React.ElementType>(
    {
      active = false,
      annotation,
      size = 'medium',
      children,
      icon,
      ...props
    }: PolymorphicComponentProps<Tag, Tab2Props>,
    forwardedRef: PolymorphicRef<Tag>,
  ) => (
    <Container
      type="button"
      role="tab"
      aria-selected={active ? 'true' : 'false'}
      {...props}
      ref={forwardedRef}
    >
      <Content isActive={active} hasAnnotation={isDefined(annotation)} size={size}>
        {isDefined(icon) && <StyledIcon icon={icon} />}
        {children}
        {isDefined(annotation) && <Annotation>{annotation}</Annotation>}
      </Content>
      <Underline isActive={active} />
    </Container>
  ),
) as Polymorphic.ForwardRefComponent<'button', Tab2Props>;
export default Tab2;
