import { api } from '@meterup/proto';
import { match } from 'ts-pattern';
import * as z from 'zod';

export const formatLifecycleStatus = (lifecycleStatus: api.LifecycleStatus) =>
  match(lifecycleStatus)
    .with(api.LifecycleStatus.LIFECYCLE_STATUS_PROVISIONED, () => 'Provisioned')
    .with(api.LifecycleStatus.LIFECYCLE_STATUS_INSTALLED_PRIMARY, () => 'Primary')
    .with(api.LifecycleStatus.LIFECYCLE_STATUS_INSTALLED_BACKUP, () => 'Backup')
    .with(api.LifecycleStatus.LIFECYCLE_STATUS_UNINSTALLED, () => 'Uninstalled')
    .with(api.LifecycleStatus.LIFECYCLE_STATUS_INACTIVE, () => 'Inactive')
    .with(api.LifecycleStatus.LIFECYCLE_STATUS_DEMO, () => 'Demo')
    .with(api.LifecycleStatus.LIFECYCLE_STATUS_UNKNOWN, () => 'Unknown')
    .exhaustive();

export const validLifecycleStatus = z.nativeEnum(api.LifecycleStatus);
