import {
  checkDefinedOrThrow,
  expectDefinedOrThrow,
  isDefined,
  ResourceNotFoundError,
} from '@meterup/common';
import {
  Button,
  Drawer,
  DrawerContent,
  DrawerControls,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTriggerButton,
} from '@meterup/metric';
import { Form, Formik } from 'formik';
import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import type { ValidServiceSetData } from '../../../../../validations/validServiceSetData';
import { fetchControllerSSIDs } from '../../../../../api/api';
import { CloseDrawerButton } from '../../../../../components/CloseDrawerButton';
import { DiscardChangesButton } from '../../../../../components/form_buttons';
import { FormikConditional } from '../../../../../components/FormikConditional';
import { NetworkJoinWidget } from '../../../../../components/NetworkJoinWidget';
import { notify } from '../../../../../components/Notifications';
import { paths } from '../../../../../constants';
import { useControllerConfig } from '../../../../../hooks/useControllerConfig';
import { useToggle } from '../../../../../hooks/useToggle';
import { Nav } from '../../../../../nav';
import { useCurrentCompany } from '../../../../../providers/CurrentCompanyProvider';
import { useCurrentController } from '../../../../../providers/CurrentControllerProvider';
import { styled } from '../../../../../stitches';
import { makeDrawerLink } from '../../../../../utils/main_and_drawer_navigation';
import { withZodSchema } from '../../../../../utils/withZodSchema';
import { validServiceSetData } from '../../../../../validations/validServiceSetData';
import { ConfirmNetworkChangesDrawer } from '../ConfirmNetworkChangesDrawer';
import { modelToFormData } from './form_data';
import { useUpdateServiceSetMutation } from './hooks';
import { BandsField, PasswordField, SSIDField, VLANField } from './ssid_fields';

const StyledForm = styled(Form, {
  display: 'contents',
});

export const Meta = () => ({
  path: '/org/:companyName/controller/:controllerName/ssids/:id/edit',
});

export default function SSIDEditPage() {
  const { id } = checkDefinedOrThrow(Nav.useRegionParams('drawer', paths.drawers.SSIDEditPage));
  const controllerName = useCurrentController();
  const companyName = useCurrentCompany();

  const configModel = useControllerConfig(controllerName);

  const serviceSetModel = configModel.getServiceSetByStableId(id);

  expectDefinedOrThrow(serviceSetModel, new ResourceNotFoundError('SSID not found'));

  const currentSSIDs =
    useQuery(['controller', controllerName, 'ssids'], () => fetchControllerSSIDs(controllerName), {
      suspense: true,
    }).data ?? [];

  const navigate = useNavigate();

  const isConfirmingChanges = useToggle();

  const updateConfigMutation = useUpdateServiceSetMutation(
    controllerName,
    configModel,
    serviceSetModel,
    () => {
      notify('Changes saved successfully', {
        variant: 'positive',
        icon: 'checkmarkCircle',
      });
      isConfirmingChanges.toggleOff();
    },
    () => {
      notify('Something went wrong. Please try again', {
        variant: 'negative',
        icon: 'crossCircle',
      });
    },
  );

  const currentSSID = currentSSIDs.find((d) => d.ssid === serviceSetModel?.ssid);
  const currentPassword = currentSSID?.password?.value ?? null;

  const initialValues = useMemo(() => modelToFormData(serviceSetModel), [serviceSetModel]);

  return (
    <Formik<ValidServiceSetData>
      validate={withZodSchema(validServiceSetData)}
      initialValues={initialValues}
      onSubmit={(values) => {
        if (isConfirmingChanges.value) {
          updateConfigMutation.mutate(values);
        } else {
          isConfirmingChanges.toggleOn();
        }
      }}
      enableReinitialize
    >
      <StyledForm>
        {isConfirmingChanges.value ? (
          <ConfirmNetworkChangesDrawer onCancel={isConfirmingChanges.toggleOff} />
        ) : (
          <Drawer>
            <DrawerHeader>
              <DrawerTitle>Configure SSID</DrawerTitle>
              <DrawerControls>
                <DropdownMenu>
                  <DropdownMenuTriggerButton
                    variant="tertiary"
                    icon="cog"
                    arrangement="hidden-label"
                  >
                    Actions
                  </DropdownMenuTriggerButton>

                  <DropdownMenuContent>
                    <DropdownMenuItem
                      onSelect={() =>
                        navigate(
                          makeDrawerLink(window.location, paths.drawers.SSIDRemovePage, {
                            id,
                            controllerName,
                            companyName,
                          }),
                        )
                      }
                    >
                      Remove
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
                <CloseDrawerButton />
              </DrawerControls>
            </DrawerHeader>
            <DrawerContent>
              {isDefined(currentSSID) && (
                <NetworkJoinWidget ssid={currentSSID} title="QR code" defaultOpen="never" />
              )}
              <SSIDField />
              <PasswordField currentValue={currentPassword} />
              <BandsField />
              <VLANField model={configModel} />
            </DrawerContent>
            <FormikConditional<ValidServiceSetData> condition={(values, ctx) => ctx.dirty}>
              <DrawerFooter>
                <DrawerControls>
                  <DiscardChangesButton />
                  <Button type="submit">Save</Button>
                </DrawerControls>
              </DrawerFooter>
            </FormikConditional>
          </Drawer>
        )}
      </StyledForm>
    </Formik>
  );
}
