import type { api } from '@meterup/proto';

export function getEnabledRadioForBandOrNull(
  accessPoint: api.AccessPointRadiosResponse | null | undefined,
  band: api.RadioBand,
): api.AccessPointRadioResponse | null {
  if (accessPoint && accessPoint.access_point) {
    return accessPoint.radios.find((radio) => radio.band === band && !radio.disabled) ?? null;
  }
  return null;
}
