import type { api } from '@meterup/proto';
import {
  DisabledBadge,
  getEnabledRadioForBandOrNull,
  isDefined,
  isDefinedAndNotEmpty,
  Tooltip,
} from '@meterup/common';
import { Badge, HardwareIcon, HStack, space } from '@meterup/metric';
import React from 'react';

import type { DeviceDataAndRadios } from '../api/types';
import { Box } from '../components/Box';

export const RadioNumbers = ({ value }: { value: api.AccessPointRadioResponse }) => (
  <HStack spacing={space(4)}>
    <Tooltip content="Channel">
      <Badge size="small">{value.channel}</Badge>
    </Tooltip>
    <Tooltip content="Channel width">
      <Badge size="small">{value.channel_width}</Badge>
    </Tooltip>
    <Tooltip content="Power">
      <Badge size="small">{value.power}</Badge>
    </Tooltip>
  </HStack>
);

export const RadioNumbersOrDisabled = ({
  value,
  band,
}: {
  value: api.AccessPointRadiosResponse | null | undefined;
  band: api.RadioBand;
}) => {
  const radio = getEnabledRadioForBandOrNull(value, band);
  return isDefined(radio) ? (
    <RadioNumbers value={radio} />
  ) : (
    <DisabledBadge icon="crossCircle" arrangement="leading-icon" />
  );
};

export const formatAPRadiosForBand = (
  accessPoint: api.AccessPointRadiosResponse | null,
  band: api.RadioBand,
) => {
  const radio = getEnabledRadioForBandOrNull(accessPoint, band);
  return radio
    ? [radio.channel.toString(), radio.channel_width.toString(), radio.power.toString()].join(', ')
    : null;
};

export enum DevicesFilterStrategy {
  All = 'all',
  Online = 'online',
  Offline = 'offline',
}

export const isOnline = (d: DeviceDataAndRadios) =>
  d.device.status === DevicesFilterStrategy.Online;

export const isOffline = (d: DeviceDataAndRadios) =>
  d.device.status === DevicesFilterStrategy.Offline;

export const getAPName = (location: string, deviceName: string) => {
  if (isDefinedAndNotEmpty(location)) {
    return location;
  }
  return deviceName;
};

export const getAPNameForDevice = (device: api.MeterDevice) =>
  getAPName(device.physical_location, device.name);

export const getAPNameForClient = (client: api.UserClient) =>
  getAPName(client.ap_location, client.apname);

export const AccessPointHeadingCell = ({ name }: { name: string }) => (
  <Box css={{ hStack: '$8' }}>
    <HardwareIcon icon="ap" size="small" />
    <Box css={{ truncate: true }}>{name}</Box>
  </Box>
);
