/* eslint-disable react/button-has-type,react/prop-types */
import type * as Polymorphic from '@radix-ui/react-polymorphic';
import type { ButtonHTMLAttributes } from 'react';
import React from 'react';

import type { IconName } from '../../assets/Icon/Icon';
import type {
  PolymorphicComponentProps,
  PolymorphicRef,
} from '../../utilities/types/polymorphicAsProp';
import type { ControlSize } from '../Control/types';
import Icon from '../../assets/Icon/Icon';
import LoadingIcon from '../../assets/LoadingIcon/LoadingIcon';
import { useControlSize } from '../../common/control_size';
import { visuallyHiddenCSS } from '../../common/visually_hidden';
import { colors, darkThemeSelector, fontWeights, shadows, styled } from '../../stitches.config';
import { Body2 } from '../../text/Body';
import {
  focusSelector,
  iconColor,
  labelColor,
  loadingIconColor,
  primaryDisabledStyles,
  primaryEnabledStyles,
  secondaryDisabledStyles,
  secondaryEnabledStyles,
  sharedTransition,
} from './button_styles';

const StyledIcon = styled(Icon, {
  color: iconColor,
  transition: sharedTransition,
  opacity: 1,
  width: '$$iconSize',
  height: '$$iconSize',
  variants: {
    isLoading: {
      true: {
        opacity: 0,
      },
    },
  },
});

const StyledLoadingIcon = styled(LoadingIcon, {
  color: loadingIconColor,
  transition: sharedTransition,
});

const StyledLabel = styled('span', Body2, {
  fontWeight: fontWeights.medium,
  transition: sharedTransition,
  truncate: true,
  // NOTE: Override base Text colors
  color: labelColor,
  [darkThemeSelector]: {
    color: labelColor,
  },
  variants: {
    isLoading: {
      true: {
        opacity: 0,
      },
      false: {
        opacity: 1,
      },
    },
    arrangement: {
      'hidden-label': visuallyHiddenCSS,
      'leading-icon': {},
      'leading-label': {},
    },
    size: {
      small: {
        lineHeight: '$16',
        fontSize: '$12',
      },
      medium: {
        lineHeight: '$20',
        fontSize: '$14',
      },
      large: {
        lineHeight: '$20',
        fontSize: '$14',
      },
      'x-large': {
        lineHeight: '$24',
        fontSize: '$16',
      },
    },
  },
});

const BaseButton = styled('button', {
  alignItems: 'center',
  borderRadius: '$8',
  justifyContent: 'space-between',
  cursor: 'pointer',
  display: 'flex',
  opacity: 1,
  position: 'relative',
  transition: sharedTransition,
  userSelect: 'none',
  paddingX: '$$paddingX',
  paddingY: '$$paddingY',
  truncate: true,
  whiteSpace: 'nowrap',
  width: '100%',
  [focusSelector]: {
    outline: 'none',
  },
  variants: {
    width: {
      auto: {
        width: 'fit-content',
      },
      truncate: {
        maxWidth: 'fit-content',
      },
      full: {
        width: '100%',
      },
    },
    size: {
      small: {
        $$iconSize: '$space$12',
        $$buttonGap: '$space$4',
        $$paddingX: '$space$4',
        $$paddingY: '$space$2',
        minHeight: '$20',
        borderRadius: '$6',
      },
      medium: {
        $$iconSize: '$space$12',
        $$buttonGap: '$space$4',
        $$paddingX: '$space$8',
        $$paddingY: '$space$4',
        minHeight: '$28',
      },
      large: {
        $$iconSize: '$space$16',
        $$buttonGap: '$space$8',
        $$paddingX: '$space$12',
        $$paddingY: '$space$8',
        minHeight: '$36',
      },
      'x-large': {
        $$iconSize: '$space$16',
        $$buttonGap: '$space$8',
        $$paddingX: '$space$20',
        $$paddingY: '$space$10',
        minHeight: '$44',
      },
    },
    isDisabled: {
      true: {
        cursor: 'not-allowed',
        opacity: 0.5,
      },
      false: {},
    },
    hasError: {
      true: {},
      false: {},
    },
    variant: {
      destructive: {},
      primary: {},
      secondary: {},
      tertiary: {},
    },
  },
  compoundVariants: [
    // Destructive
    {
      variant: 'destructive',
      isDisabled: false,
      css: {
        backgroundColor: colors['red-600'],
        backgroundImage: colors.controlDestructive,
        boxShadow: shadows.controlDestructiveInitialLight,
        [labelColor]: colors['red-50'],
        [iconColor]: colors['red-100'],
        '&:hover': {
          boxShadow: shadows.controlDestructiveHoveredLight,
          [labelColor]: colors.white,
          [iconColor]: colors['red-50'],
        },
        [focusSelector]: {
          boxShadow: shadows.controlDestructiveFocusedLight,
        },
        [darkThemeSelector]: {
          backgroundColor: colors['red-600'],
          boxShadow: shadows.controlDestructiveInitialDark,
          '&:hover': {
            boxShadow: shadows.controlDestructiveHoveredDark,
          },
          [focusSelector]: {
            boxShadow: shadows.controlDestructiveFocusedDark,
          },
        },
      },
    },
    {
      variant: 'destructive',
      isDisabled: true,
      css: {
        backgroundColor: colors['red-800'],
        boxShadow: shadows.controlDestructiveDisabledLight,
        [labelColor]: colors['red-200'],
        [iconColor]: colors['red-300'],
        [loadingIconColor]: colors['red-50'],
        [darkThemeSelector]: {
          boxShadow: shadows.controlDestructiveDisabledDark,
        },
      },
    },

    // Primary
    {
      variant: 'primary',
      isDisabled: false,
      css: primaryEnabledStyles,
    },
    {
      variant: 'primary',
      isDisabled: true,
      css: primaryDisabledStyles,
    },

    // Secondary
    {
      variant: 'secondary',
      isDisabled: false,
      css: secondaryEnabledStyles,
    },
    {
      variant: 'secondary',
      isDisabled: true,
      css: secondaryDisabledStyles,
    },

    // Tertiary
    {
      variant: 'tertiary',
      isDisabled: false,
      css: {
        backgroundColor: colors.transparent,
        boxShadow: shadows.controlTertiaryInitialLight,
        [labelColor]: colors['gray-600'],
        [iconColor]: colors['gray-500'],
        '&:hover': {
          boxShadow: shadows.controlTertiaryHoveredLight,
          [labelColor]: colors['gray-700'],
          [iconColor]: colors['gray-600'],
        },
        [focusSelector]: {
          boxShadow: shadows.controlTertiaryFocusedLight,
        },
        [darkThemeSelector]: {
          backgroundColor: colors.transparent,
          boxShadow: shadows.controlTertiaryInitialDark,
          [labelColor]: colors['gray-50'],
          [iconColor]: colors['gray-100'],
          '&:hover': {
            boxShadow: shadows.controlTertiaryHoveredDark,
            [labelColor]: colors.white,
            [iconColor]: colors['gray-50'],
          },
          [focusSelector]: {
            boxShadow: shadows.controlTertiaryFocusedDark,
          },
        },
      },
    },
    {
      variant: 'tertiary',
      isDisabled: true,
      css: {
        boxShadow: shadows.controlTertiaryDisabledLight,
        [labelColor]: colors['gray-500'],
        [iconColor]: colors['gray-400'],
        [loadingIconColor]: colors['gray-600'],
        [darkThemeSelector]: {
          boxShadow: shadows.controlTertiaryDisabledDark,
          [labelColor]: colors['gray-200'],
          [iconColor]: colors['gray-300'],
          [loadingIconColor]: colors['gray-50'],
        },
      },
    },
    {
      isDisabled: false,
      hasError: true,
      css: {
        boxShadow: shadows.fieldErrorLight,
        '&:hover:not(:focus)': {
          boxShadow: shadows.fieldErrorLight,
        },
        [darkThemeSelector]: {
          boxShadow: shadows.fieldErrorDark,
          '&:hover:not(:focus)': {
            boxShadow: shadows.fieldErrorDark,
          },
        },
      },
    },
  ],
});

const LoadingIconContainer = styled('div', {
  position: 'absolute',
  inset: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const ButtonContent = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '$$buttonGap',
  truncate: true,
  variants: {
    arrangement: {
      'hidden-label': {},
      'leading-icon': {
        flexDirection: 'row-reverse',
      },
      'leading-label': {},
    },
  },
});

const DirectionArrow = styled('div', {
  display: 'flex',
  alignItems: 'center',
  flexGrow: 1,
  flexBasis: 0,
});

const MenuArrow = styled(StyledIcon, {
  marginLeft: 4,
  height: 9,
  width: 9,
});

const PreviousArrow = styled(DirectionArrow, {
  justifyContent: 'flex-start',
  // TRICKY: Since this arrow is always in the DOM, apply a margin to its (only)
  // child so that the arrow takes 0 width when empty.
  '& > *': {
    marginRight: '$$buttonGap',
  },
});

const NextArrow = styled(DirectionArrow, {
  justifyContent: 'flex-end',
  '& > *': {
    marginLeft: '$$buttonGap',
  },
});

export type ButtonArrangement = 'hidden-label' | 'leading-icon' | 'leading-label';
export type ButtonMenuArrow = 'dropdown' | 'select';
export type ButtonDirection = 'next' | 'previous';
export type ButtonSize = ControlSize;
export type ButtonType = 'button' | 'submit';
export type ButtonVariant = 'destructive' | 'primary' | 'secondary' | 'tertiary';
export type ButtonWidth = 'auto' | 'truncate' | 'full';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  arrangement?: ButtonArrangement;
  children: React.ReactNode;
  direction?: ButtonDirection;
  disabled?: boolean;
  hasError?: boolean;
  form?: string;
  /**
   * Set which icon to display, no value displays no icon.
   */
  icon?: IconName;
  menuArrow?: ButtonMenuArrow;
  loading?: boolean;
  size?: ButtonSize;
  type?: ButtonType;
  /**
   * Set the most appropriate variant of the component for your use.
   */
  variant?: ButtonVariant;
  width?: ButtonWidth;
}

const ButtonInner = <Tag extends React.ElementType>(
  {
    arrangement = 'leading-label',
    children,
    direction,
    disabled = false,
    icon,
    menuArrow,
    loading = false,
    hasError = false,
    size,
    variant = 'primary',
    width = 'auto',
    type = 'button',
    ...rest
  }: PolymorphicComponentProps<Tag, ButtonProps>,
  ref: PolymorphicRef<Tag>,
) => {
  const controlSize = useControlSize(size, 'medium');

  // Disable the button during its loading state
  const isDisabled = disabled || loading;

  return (
    <BaseButton
      {...(rest as object)}
      type={type}
      ref={ref}
      variant={variant}
      size={controlSize}
      width={width}
      isDisabled={isDisabled}
      disabled={isDisabled}
      hasError={hasError}
    >
      {loading && (
        <LoadingIconContainer>
          <StyledLoadingIcon />
        </LoadingIconContainer>
      )}
      <PreviousArrow>
        {direction === 'previous' && <StyledIcon isLoading={loading} icon="arrowLeft" />}
      </PreviousArrow>
      <ButtonContent arrangement={arrangement}>
        <StyledLabel size={controlSize} isLoading={loading} arrangement={arrangement}>
          {children}
        </StyledLabel>
        {icon && <StyledIcon isLoading={loading} icon={icon} />}
      </ButtonContent>
      {menuArrow && (
        <MenuArrow
          isLoading={loading}
          icon={menuArrow === 'dropdown' ? 'chevronDown' : 'arrowsVertical'}
        />
      )}
      <NextArrow>
        {direction === 'next' && <StyledIcon isLoading={loading} icon="arrowRight" />}
      </NextArrow>
    </BaseButton>
  );
};

const Button = React.forwardRef(ButtonInner) as Polymorphic.ForwardRefComponent<
  React.ElementType,
  ButtonProps
>;

export default Button;
