import type { Address4Range } from '@meterup/common';
import type { MeterV1NetworkVLAN } from '@meterup/config';
import {
  isDefined,
  isOffline,
  isOnline,
  NeutralBadge,
  OnlineOfflineClientCountBadge,
  TaggedOrUntaggedBadge,
} from '@meterup/common';
import {
  BodyMono2,
  CopyCapsule,
  MinList,
  MinListItemFooterLink,
  MinListItemHeader,
  MinListItemLabel,
  MinListItemPair,
  MinListItemValue,
  MinListTitle,
} from '@meterup/metric';
import React from 'react';

import { paths } from '../constants';
import { useVLANClients } from '../hooks/useVLANClients';
import { useCurrentCompany } from '../providers/CurrentCompanyProvider';
import { useCurrentController } from '../providers/CurrentControllerProvider';
import { makeLink } from '../utils/main_and_drawer_navigation';
import { NoValue } from './NoValue';
import { ReactRouterLink } from './ReactRouterLink';

const IPRange = ({ range }: { range: Address4Range }) => {
  const serializedRange = `${range.start.correctForm()} - ${range.end.correctForm()}`;

  return (
    <BodyMono2>
      <CopyCapsule
        aria-label="Copy IP range to clipboard"
        arrangement="leading-icon"
        textValue={serializedRange}
      >
        {serializedRange}
      </CopyCapsule>
    </BodyMono2>
  );
};

export const VLANTaggingWidget = ({
  vlan,
  shouldLinkToDetails = false,
}: {
  vlan: MeterV1NetworkVLAN;
  shouldLinkToDetails?: boolean;
}) => {
  const controllerName = useCurrentController();
  const companyName = useCurrentCompany();
  return (
    <MinList>
      <MinListItemHeader icon="vlan">
        <MinListTitle>VLAN</MinListTitle>
      </MinListItemHeader>
      <MinListItemPair>
        <MinListItemLabel>Tagging</MinListItemLabel>
        <MinListItemValue>
          <TaggedOrUntaggedBadge isUntagged={vlan.untagged} />
        </MinListItemValue>
      </MinListItemPair>
      <MinListItemPair>
        <MinListItemLabel>ID</MinListItemLabel>
        <MinListItemValue>{vlan.vlanId}</MinListItemValue>
      </MinListItemPair>

      {shouldLinkToDetails && (
        <MinListItemFooterLink
          as={ReactRouterLink}
          to={makeLink(paths.pages.VLANDetailPage, {
            vlanName: vlan.name,
            controllerName,
            companyName,
          })}
          replace={false}
        >
          View VLAN
        </MinListItemFooterLink>
      )}
    </MinList>
  );
};

export const VLANDHCPWidget = ({ vlan }: { vlan: MeterV1NetworkVLAN }) => (
  <MinList>
    <MinListItemHeader>
      <MinListTitle>DHCP</MinListTitle>
    </MinListItemHeader>
    <MinListItemPair>
      <MinListItemLabel>Subnet</MinListItemLabel>
      <MinListItemValue>
        <BodyMono2>
          <CopyCapsule
            aria-label="Copy subnet CIDR address to clipboard"
            arrangement="leading-icon"
            textValue={vlan.gatewayAddress}
          >
            {vlan.subnet?.address}
          </CopyCapsule>
        </BodyMono2>
      </MinListItemValue>
    </MinListItemPair>
    <MinListItemPair>
      <MinListItemLabel>Mask</MinListItemLabel>
      <MinListItemValue>
        <BodyMono2>
          <CopyCapsule
            aria-label="Copy netmask to clipboard"
            arrangement="leading-icon"
            textValue={vlan.json.dhcp?.netmask ?? ''}
          >
            {vlan.json.dhcp?.netmask}
          </CopyCapsule>
        </BodyMono2>
      </MinListItemValue>
    </MinListItemPair>
    <MinListItemPair>
      <MinListItemLabel>Gateway</MinListItemLabel>
      <MinListItemValue>
        <BodyMono2>
          <CopyCapsule
            aria-label="Copy gateway IP address to clipboard"
            arrangement="leading-icon"
            textValue={vlan.gatewayAddress}
          >
            {vlan.gatewayAddress}
          </CopyCapsule>
        </BodyMono2>
      </MinListItemValue>
    </MinListItemPair>
    <MinListItemPair>
      <MinListItemLabel>Usable range</MinListItemLabel>
      <MinListItemValue>
        {isDefined(vlan.usableRange) ? <IPRange range={vlan.usableRange} /> : <NoValue />}
      </MinListItemValue>
    </MinListItemPair>
    <MinListItemPair>
      <MinListItemLabel>DHCP range</MinListItemLabel>
      <MinListItemValue>
        {isDefined(vlan.dhcpRange) ? <IPRange range={vlan.dhcpRange} /> : <NoValue />}
      </MinListItemValue>
    </MinListItemPair>
    <MinListItemPair>
      <MinListItemLabel>Static IPs</MinListItemLabel>
      <MinListItemValue>
        <NeutralBadge>{vlan.staticClients.length}</NeutralBadge>
      </MinListItemValue>
    </MinListItemPair>
  </MinList>
);

export const VLANClientsWidget = ({ vlan }: { vlan: MeterV1NetworkVLAN }) => {
  const controllerName = useCurrentController();
  const companyName = useCurrentCompany();
  const clients = useVLANClients(controllerName, vlan.name);
  return (
    <MinList>
      <MinListItemHeader icon="client">
        <MinListTitle>Clients</MinListTitle>
      </MinListItemHeader>
      <MinListItemPair>
        <MinListItemLabel>All</MinListItemLabel>
        <MinListItemValue>
          <OnlineOfflineClientCountBadge value={clients.length} />
        </MinListItemValue>
      </MinListItemPair>
      <MinListItemPair>
        <MinListItemLabel>Online</MinListItemLabel>
        <MinListItemValue>
          <OnlineOfflineClientCountBadge value={clients.filter(isOnline).length} />
        </MinListItemValue>
      </MinListItemPair>
      <MinListItemPair>
        <MinListItemLabel>Offline</MinListItemLabel>
        <MinListItemValue>
          <NeutralBadge arrangement="leading-icon" icon="client">
            {clients.filter(isOffline).length}
          </NeutralBadge>
        </MinListItemValue>
      </MinListItemPair>

      <MinListItemFooterLink
        as={ReactRouterLink}
        to={makeLink(paths.pages.VLANClientListPage, {
          vlanName: vlan.name,
          controllerName,
          companyName,
        })}
        replace={false}
      >
        View clients
      </MinListItemFooterLink>
    </MinList>
  );
};
