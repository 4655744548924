import { colors, darkThemeSelector, fonts, fontWeights, shadows } from '../stitches.config';

export const contentStyles = {
  vStack: '$2',
  overflow: 'hidden',
  alignItems: 'stretch',
  backgroundColor: colors.white,
  borderRadius: '$8',
  boxShadow: shadows.overlayLight,
  [darkThemeSelector]: {
    backgroundColor: colors['gray-700'],
    boxShadow: shadows.overlayDark,
  },
};

export const itemStyles = {
  fontFamily: fonts.sans,
  fontWeight: fontWeights.medium,
  fontSize: '$14',
  lineHeight: '$20',
  color: colors['gray-700'],
  borderRadius: '$6',
  display: 'flex',
  alignItems: 'center',
  minHeight: '$28',
  padding: '0 $6',
  position: 'relative',
  outline: 'none',
  userSelect: 'none',
  paddingLeft: '$24',

  '&[data-disabled]': {
    color: colors['gray-500'],
    pointerEvents: 'none',
  },

  '&:focus, &[data-focused]': {
    backgroundColor: colors['brand-600'],
    color: colors.white,
  },

  [darkThemeSelector]: {
    color: colors['gray-100'],
    '&[data-disabled]': {
      color: colors['gray-200'],
    },
    '&:focus, &[data-focused]': {
      color: colors.white,
    },
  },
};

export const labelStyles = {
  paddingLeft: '$24',
  fontWeight: fontWeights.regular,
  lineHeight: '$20',
  color: colors['gray-500'],
  [darkThemeSelector]: {
    color: colors['gray-300'],
  },
};

export const separatorStyles = {
  height: 1,
  backgroundColor: colors['gray-100'],
  margin: '$4 -4px',
  [darkThemeSelector]: {
    backgroundColor: colors['gray-800'],
  },
};

export const itemIndicatorStyles = {
  position: 'absolute',
  left: 0,
  width: 24,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const scrollButtonStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '$24',
  backgroundColor: colors.white,
  color: colors['gray-500'],
  cursor: 'default',
  [darkThemeSelector]: {
    backgroundColor: colors['gray-800'],
    color: colors['gray-200'],
  },
};
