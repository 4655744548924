import { expectDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import {
  CloseDrawerButton,
  Drawer,
  DrawerContent,
  DrawerControls,
  DrawerHeader,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTriggerButton,
} from '@meterup/metric';
import { useState } from 'react';
import { useQuery } from 'react-query';

import { fetchVPNClient } from '../../../../api/vpn';
import VPNClientSummaryContent from '../../../../components/VPNClientSummaryContent';
import { paths } from '../../../../constants';
import { useCloseDrawerCallback } from '../../../../hooks/useCloseDrawerCallback';
import { Nav } from '../../../../nav';
import { useCurrentController } from '../../../../providers/CurrentControllerProvider';
import { DeleteVPNClientDialog } from './VPNClientDelete';
import { VPNSummaryTabs } from './vpnSummary';

export const Meta = () => ({
  path: '/org/:companyName/controller/:controllerName/vpn/:sid',
});

export default function VPNClientSummaryPage() {
  const controllerName = useCurrentController();
  const { sid } = Nav.useRegionParams('drawer', paths.drawers.VPNClientSummaryPage)!;

  const vpnClientDetails = useQuery(
    ['controller', controllerName, 'vpn-clients', sid],
    () => fetchVPNClient(controllerName, sid),
    { suspense: true },
  ).data;

  const closeDrawer = useCloseDrawerCallback();
  const [isOpen, setIsOpen] = useState(false);

  expectDefinedOrThrow(
    vpnClientDetails,
    new ResourceNotFoundError('VPN client response not found'),
  );
  expectDefinedOrThrow(vpnClientDetails.client, new ResourceNotFoundError('VPN client not found'));
  expectDefinedOrThrow(vpnClientDetails.server, new ResourceNotFoundError('VPN client not found'));

  return (
    <Drawer>
      <DrawerHeader>
        <VPNSummaryTabs sid={sid} />
        <DrawerControls>
          <DropdownMenu>
            <DropdownMenuTriggerButton
              arrangement="hidden-label"
              icon="overflowVertical"
              variant="tertiary"
            >
              Actions
            </DropdownMenuTriggerButton>
            <DropdownMenuContent>
              <DropdownMenuItem onSelect={() => setIsOpen(true)}>
                Remove VPN client
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
          <CloseDrawerButton onClick={closeDrawer} />
        </DrawerControls>
      </DrawerHeader>
      <DrawerContent>
        <VPNClientSummaryContent vpnClient={vpnClientDetails.client} />
      </DrawerContent>
      <DeleteVPNClientDialog
        controllerName={controllerName}
        vpnClientName={vpnClientDetails.client.name}
        vpnClientSid={vpnClientDetails.client.sid}
        isOpen={isOpen}
        onOpenChange={setIsOpen}
      />
    </Drawer>
  );
}
