import { Priority, useCommand, useRegisterCommands } from '@meterup/command';
import { isDefined, SettingsButton } from '@meterup/common';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@meterup/metric';
import { filter, orderBy } from 'lodash';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { paths } from '../constants';
import { useLogoutHandler } from '../hooks/useLogoutHandler';
import { useCurrentCompany } from '../providers/CurrentCompanyProvider';
import { useIdentity } from '../providers/IdentityDataProvider';
import { makeLink } from '../utils/main_and_drawer_navigation';

export const HeaderDropdownMenu = () => {
  const identity = useIdentity();
  const navigate = useNavigate();

  const logout = useLogoutHandler();
  const currentCompany = useCurrentCompany();

  const changeCurrentCompany = (companyName: string) => {
    navigate(makeLink(paths.pages.CompanyRootPage, { companyName }));
  };

  const sortedCompanySlugs = orderBy(identity.company_memberships, (c) => c.company_slug).map(
    (c) => c.company_slug,
  );

  const sortedCompanySlugsIgnoreCurrent = filter(
    sortedCompanySlugs,
    (slug) => slug !== currentCompany,
  );

  const isMeterEmployee = identity.username.endsWith('@meter.com');

  const { state } = useCommand();

  useRegisterCommands(
    [
      isMeterEmployee
        ? state.nodeFactory.directory({
            id: 'switch-companies',
            children: sortedCompanySlugsIgnoreCurrent.map((slug) =>
              state.nodeFactory.action({
                id: slug,
                display: `Switch to ${slug}`,
                label: `Switch to ${slug}`,
                onSelect() {
                  changeCurrentCompany(slug);
                },
              }),
            ),
            display: 'Switch company…',
            label: 'Switch company…',
            group: 'Account',
            priority: Priority.Low,
          })
        : undefined,
      state.nodeFactory.action({
        id: 'signout',
        display: 'Sign out',
        label: 'Signout',
        group: 'Account',
        priority: Priority.Low,
        onSelect() {
          logout();
        },
      }),
    ].filter(isDefined),
    sortedCompanySlugsIgnoreCurrent,
  );
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <SettingsButton />
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {isMeterEmployee && (
          <>
            <DropdownMenuLabel>Current company</DropdownMenuLabel>
            {sortedCompanySlugs.map((slug) => (
              <DropdownMenuCheckboxItem
                key={slug}
                checked={currentCompany === slug}
                onSelect={() => changeCurrentCompany(slug)}
              >
                {slug}
              </DropdownMenuCheckboxItem>
            ))}
            <DropdownMenuSeparator />
          </>
        )}

        <DropdownMenuItem icon="power" onSelect={logout}>
          Sign out
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
