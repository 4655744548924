import { expectDefinedOrThrow } from '@meterup/common';
import { first } from 'lodash';
import React, { createContext, useMemo } from 'react';

import { NoCompanyMembershipsError } from '../errors';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { useIdentity } from './IdentityDataProvider';

export const DefaultCompanyContext = createContext<{
  defaultCompany: string;
  setDefaultCompany: (slug: string | null) => void;
}>(null as any);

export const DefaultCompanyProvider: React.FC = ({ children }) => {
  const identity = useIdentity();

  const [defaultCompany, setDefaultCompany] = useLocalStorage<string | null>(
    'currentCompany',
    null,
  );

  const defaultOrFirstPermittedCompany =
    identity.company_memberships.find((d) => d.company_slug === defaultCompany) ??
    first(identity.company_memberships) ??
    null;

  // If the user has no company memberships, throw an error. They won't be able to use any
  // functionality in Dashboard in this state.
  expectDefinedOrThrow(
    defaultOrFirstPermittedCompany,
    new NoCompanyMembershipsError(
      'No associated networks',
      `Your email address "${identity.username}" is not associated with any Meter networks. If you believe this is an error, please reach out to your network administrator.`,
    ),
  );

  const defaultOrFirstPermittedCompanySlug = defaultOrFirstPermittedCompany?.company_slug;

  const value = useMemo(
    () => ({
      defaultCompany: defaultOrFirstPermittedCompanySlug ?? null,
      setDefaultCompany,
    }),
    [setDefaultCompany, defaultOrFirstPermittedCompanySlug],
  );

  return <DefaultCompanyContext.Provider value={value}>{children}</DefaultCompanyContext.Provider>;
};
