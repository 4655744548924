import { Box, Icon, LoadingIcon, space, TextInput } from '@meterup/metric';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { ROOT_ID } from '../core';
import { useCommand } from './Root';

export const Input = observer(() => {
  const { state } = useCommand();

  const rInput = React.useRef<HTMLLabelElement>(null);

  React.useEffect(() => {
    rInput.current?.focus();
  }, [state.currentRoot]);

  const placeholder =
    (state.currentRoot.id !== ROOT_ID && state.currentRoot.label) || 'Type a command or search…';

  return (
    <Box
      padding={{
        all: space(12),
      }}
    >
      <TextInput
        prefix={state.ui.loading ? <LoadingIcon size={space(16)} /> : <Icon icon="search" />}
        ref={rInput}
        value={state.ui.search}
        onChange={(val) =>
          state.ui.update((ui) => {
            // eslint-disable-next-line no-param-reassign
            ui.search = val;
          })
        }
        autoFocus
        placeholder={placeholder}
        controlSize="x-large"
      />
    </Box>
  );
});
