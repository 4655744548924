import type { SMSMessage, VPNClientCreateRequest } from '@meterup/proto/esm/api';
import { api } from '@meterup/proto';
import { z } from 'zod';

import type { FixMeLater } from '../../../../../types/fixMeLater';

export interface VPNFormData {
  owner?: string;
  device?: string;
  name?: string;
  phone?: string;
  sid?: string;
  public_key?: string;
  private_key?: string;
  activated?: boolean;
}

export enum AddClientFormStep {
  Owner,
  Device,
  Download,
  Config,
  Allow,
  Activate,
}

export enum ConfirmClientStep {
  PrivateKeyConfig,
  Allow,
  Activate,
}

export enum Device {
  MacOS = 'macOS',
  Windows = 'Windows',
  IOS = 'iOS',
  Android = 'Android',
  Other = 'Other',
}

export const MAC_OS_EX = 'e.g. MacBook Pro, iMac';
export const WINDOWS_EX = 'e.g. Dell, Lenovo, HP';
export const IOS_EX = 'e.g. iPhone, iPad';
export const ANDROID_EX = 'e.g. Pixel, Samsung Galaxy S';
export const OTHER_EX = 'e.g. Linux, Ubuntu';

export const devices = [Device.MacOS, Device.Windows, Device.IOS, Device.Android, Device.Other];

export const examples = new Map<Device, string>([
  [Device.MacOS, MAC_OS_EX],
  [Device.Windows, WINDOWS_EX],
  [Device.IOS, IOS_EX],
  [Device.Android, ANDROID_EX],
  [Device.Other, OTHER_EX],
]);

export const validVPNNameData = z.object({
  name: z.string().optional().nullable(),
});

export type ValidVPNNameData = z.infer<typeof validVPNNameData>;

export const validVPNDeviceData = z.object({
  name: z.string(),
  device: z.string(),
});

export type ValidVPNDeviceData = z.infer<typeof validVPNDeviceData>;

export const validVPNEmailData = z.object({
  email: z.string(),
});

export type ValidVPNEmailData = z.infer<typeof validVPNEmailData>;

export const validVPNPhoneData = z.object({
  phone: z.string(),
});

export type ValidVPNPhoneData = z.infer<typeof validVPNPhoneData>;

export const validVPNCreated = z.object({
  privateKey: z.string(),
  sid: z.string(),
});

export type ValidVPNCreated = z.infer<typeof validVPNCreated>;

export const toVPNClientCreateRequest = (
  name: string,
  email: string,
  publicKey: string,
): {
  vpnClientData: VPNClientCreateRequest;
} => ({
  vpnClientData: {
    public_key: (publicKey as FixMeLater) ?? '',
    name: name ?? '',
    email: email ?? '',
  },
});

export const toSMSRequest = (
  device: Device.Android | Device.IOS,
  phone: string,
  countryCode: string,
): {
  data: SMSMessage;
} => ({
  data: {
    receiver: phone,
    country_code: countryCode,
    body:
      device === Device.Android
        ? api.SMSMessageEnum.SMS_DOWNLOAD_TUNNEL_IOS
        : api.SMSMessageEnum.SMS_DOWNLOAD_TUNNEL_ANDROID,
  },
});
