import { isEqual } from 'lodash';

import { upsertControllerConfigKey } from './api';

export function saveConfigDiff(
  controllerName: string,
  modified: Record<string, any>,
  original: Record<string, any>,
) {
  const deletedKeys = Object.keys(original).filter((key) => !(key in modified));

  const modifiedKeys = Object.keys(modified).filter(
    (key) => !(key in original) || !isEqual(original[key], modified[key]),
  );

  const modifiedPromises = modifiedKeys.map((key) =>
    upsertControllerConfigKey(controllerName, key, modified[key]),
  );

  const deletedPromises = deletedKeys.map((key) =>
    upsertControllerConfigKey(controllerName, key, {}),
  );

  return Promise.all([...modifiedPromises, ...deletedPromises]);
}
