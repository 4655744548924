/* eslint-disable no-param-reassign */
import React from 'react';

import type { Node } from '../../core/nodes/node';
import { Action, Directory } from '../../core';
import { useCommand } from '../Root';
import { usePointerMovedSinceMount } from './usePointerMovedSinceMount';

export function useCommandItemEvents() {
  const { state } = useCommand();

  const moved = usePointerMovedSinceMount();

  return React.useCallback(
    (node: Node, index: number) => ({
      /**
       * TODO: add this back when we virtualize the results list.
       * Currently, `pointerenter` fires on the node that the pointer
       * is hovered over when we explicitly `scrollIntoView`, causing
       * a flickering activeIndex.
       */
      // onPointerEnter(event) {
      //   if (event.intercepted) {
      //     return;
      //   }
      //   if (!moved) return;
      //   state.ui.update((ui) => {
      //     ui.activeIndex = index;
      //   });
      // },
      onPointerMove() {
        state.ui.update((ui) => {
          ui.activeIndex = index;
        });
      },
      onPointerDown() {
        state.ui.update((ui) => {
          ui.activeIndex = index;
        });
      },
      onClick() {
        if (node instanceof Action) {
          node.onSelect();
        } else if (node instanceof Directory) {
          state.updateCurrentRoot(node);
        }
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [moved],
  );
}
