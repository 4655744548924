import type { Path, To } from 'history';
import { createPath } from 'history';

import { isDefined } from './isDefined';
import { joinPath } from './joinPath';
import { parseRegionLocations } from './parseRegionLocations';

const isPartialPathObject = (value: unknown): value is Partial<Path> =>
  typeof value === 'object' &&
  value != null &&
  ('pathname' in value ||
    'search' in value ||
    'state' in value ||
    'hash' in value ||
    'key' in value);

const buildRegionPaths = (rootPath: string, regions: MultiRegionTo) => {
  const rootSegments = rootPath.split('/').filter(isDefined);

  return joinPath(
    Object.entries(regions)
      .map(([regionName, to]) => {
        const regionPath = isPartialPathObject(to) ? createPath(to) : to;
        if (isDefined(regionPath)) {
          const regionSegments = regionPath.split('/').filter(isDefined) ?? [];

          let segmentOverlapLength = 0;
          while (
            segmentOverlapLength < regionSegments.length &&
            segmentOverlapLength < rootSegments.length &&
            regionSegments[segmentOverlapLength] === rootSegments[segmentOverlapLength]
          ) {
            segmentOverlapLength += 1;
          }
          segmentOverlapLength -= 1;

          let regionAndConcatDepth: string;
          let isAbsolute = false;
          if (segmentOverlapLength === 0) {
            regionAndConcatDepth = `[${regionName}]`;
            isAbsolute = true;
          } else if (segmentOverlapLength + 1 === rootSegments.length) {
            regionAndConcatDepth = `[${regionName}]`;
          } else {
            regionAndConcatDepth = `[${regionName}:${segmentOverlapLength}]`;
          }

          return `${regionAndConcatDepth}:${isAbsolute ? '/' : ''}${regionSegments
            .slice(segmentOverlapLength + 1)
            .join('/')}`;
        }

        return null;
      })
      .filter(isDefined),
  );
};

const getRootPathName = (toPathOrDescriptor: To) => {
  if (isPartialPathObject(toPathOrDescriptor)) {
    return toPathOrDescriptor.pathname;
  }

  return toPathOrDescriptor;
};

const getRootSearchParams = (toPathOrDescriptor: To) => {
  if (isPartialPathObject(toPathOrDescriptor)) {
    return toPathOrDescriptor.search;
  }

  return undefined;
};

export type MultiRegionTo = {
  [key: string]: To | null | undefined;
};

export const makeTo = (to: To | MultiRegionTo, regionNames: readonly string[]): To => {
  if (typeof to === 'string') {
    return {
      pathname: to,
    };
  }

  if (isPartialPathObject(to) && regionNames.some((name) => name in to)) {
    throw new Error('Malformed multi-region path descriptor');
  }

  if (isPartialPathObject(to)) {
    return to;
  }

  const { root, ...regions } = to;
  const rootPathnameBeforeParse = root ? getRootPathName(root) ?? '' : '';
  const rootPathname = parseRegionLocations(regionNames, {
    pathname: rootPathnameBeforeParse,
  }).root.pathname;

  const rootSearchParams = new URLSearchParams(root ? getRootSearchParams(root) : undefined);
  const regionPaths = buildRegionPaths(rootPathname, regions);

  return {
    pathname: joinPath([rootPathname, regionPaths]),
    search: rootSearchParams.toString(),
  };
};
