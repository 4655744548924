import type { To } from 'react-router';
import { isEmpty } from 'lodash';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

import { Nav } from '../nav';
import { makeParamsLink } from '../utils/main_and_drawer_navigation';

function utf8ToBase64(str: string) {
  return window.btoa(unescape(encodeURIComponent(str)));
}

function base64ToUTF8(str: string) {
  return decodeURIComponent(escape(window.atob(str)));
}

type Serializable = string | number | object;

type Updater<T> = T | ((prev: T) => T);

interface SearchParamsStateContextValue {
  keys: { [key: string]: Serializable };
  setKey<T extends Serializable>(key: string, updater: Updater<T | null>): void;
  getNextPath<T extends Serializable>(key: string, updater: Updater<T | null>): To;
}

const SearchParamsStateContext = createContext<SearchParamsStateContextValue>(null as any);

const SEARCH_PARAMS_STATE_KEY = 's';

function serializeState(debouncedState: { [p: string]: Serializable }) {
  return !isEmpty(debouncedState) ? utf8ToBase64(JSON.stringify(debouncedState)) : null;
}

function deserializeState(serializedVal: string) {
  return JSON.parse(base64ToUTF8(serializedVal));
}

function getNextState<T>(
  key: string,
  prev: { [p: string]: Serializable },
  updater: Updater<T | null>,
) {
  const nextValue = updater instanceof Function ? updater(prev[key] as any) : updater;

  if (isEmpty(nextValue) || nextValue === null) {
    const { [key]: skip, ...rest } = prev;
    return rest;
  }

  return {
    ...prev,
    [key]: nextValue,
  };
}

export const SearchParamsStateProvider = ({ children }: { children?: React.ReactNode }) => {
  const navigate = useNavigate();
  const location = Nav.useRealLocation();

  const [state, setState] = useState<{ [key: string]: Serializable }>(() => {
    const params = new URLSearchParams(location.search);
    const serializedVal = params.get(SEARCH_PARAMS_STATE_KEY);

    if (serializedVal) {
      return deserializeState(serializedVal);
    }

    return {};
  });

  const [debouncedState] = useDebounce(state, 200);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const serializedVal = params.get(SEARCH_PARAMS_STATE_KEY);

    if (serializedVal === null) {
      setState({});
    } else if (serializedVal !== serializeState(debouncedState)) {
      setState(deserializeState(serializedVal));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  useEffect(() => {
    navigate(
      makeParamsLink(location.pathname, location.search, {
        s: serializeState(debouncedState),
      }),
      { replace: true },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedState, navigate]);

  const value = useMemo(
    () => ({
      keys: state,
      getNextPath<T extends Serializable>(key: string, updater: Updater<T | null>) {
        const nextState = getNextState(key, state, updater);
        return makeParamsLink(location.pathname, location.search, {
          s: serializeState(nextState),
        });
      },
      setKey<T extends Serializable>(key: string, updater: Updater<T | null>) {
        setState((prev) => getNextState(key, prev, updater));
      },
    }),
    [location, state],
  );

  return (
    <SearchParamsStateContext.Provider value={value}>{children}</SearchParamsStateContext.Provider>
  );
};

export const useSearchParamsState = <T extends Serializable>(key: string, defaultValue?: T) => {
  const ctx = useContext(SearchParamsStateContext);

  if (!ctx) {
    throw new Error('useSearchParamsState must be used within SearchParamsStateProvider');
  }

  const setValue = (updater: Updater<T>) => {
    if (updater === defaultValue) {
      return ctx.setKey(key, null);
    }

    return ctx.setKey(key, updater as Updater<T | null>);
  };

  const getNextPath = (updater: Updater<T>) => {
    if (updater === defaultValue) {
      return ctx.getNextPath(key, null);
    }

    return ctx.getNextPath(key, updater as Updater<T | null>);
  };

  return [
    (ctx.keys[key] ?? defaultValue) as T | typeof defaultValue,
    setValue,
    getNextPath,
  ] as const;
};
