import type { Location } from 'history';

import { ROOT_REGION_NAME } from './constants';
import { joinPath } from './joinPath';

// Matches a region token in the form of `[regionName]:` or `[regionName:<number>]:`
const REGION_TOKEN_REGEX = /^\[([a-z]+)(?::(\d+))?]:/;

/**
 * Given the rootLocation, parse the pathname and return a map of region names to
 * their corresponding locations. If a region is not present in the pathname, it
 * will be `null` in the returned map.
 *
 * @example
 *  parseRegionLocation('drawer', {pathname: '/clients/[drawer:123]', ... });
 *  // { pathname: '/clients/123' }
 */
export const parseRegionLocations = (
  regionNames: readonly string[],
  rootLocation: Partial<Location>,
): Record<string, Location | null> & { root: Location } => {
  const pathname = (rootLocation.pathname ?? '/').replaceAll('%5B', '[').replaceAll('%5D', ']');

  const segmentsByRegion: Record<string, string[]> = { root: [] };
  regionNames.forEach((regionName) => {
    segmentsByRegion[regionName] = [];
  });

  const concatLengthsByRegion: Record<string, number | undefined> = {};
  regionNames.forEach((regionName) => {
    concatLengthsByRegion[regionName] = undefined;
  });

  let regionName: string = ROOT_REGION_NAME;
  let pathSegment = '';
  let char: string | undefined;

  for (let i = 0; i <= pathname.length; i += 1) {
    char = pathname[i];
    switch (char) {
      case '/':
        // If we are at a slash, push the current segment and reset the path segment
        segmentsByRegion[regionName].push(pathSegment);
        pathSegment = '';
        break;
      case '[': {
        const match = REGION_TOKEN_REGEX.exec(pathname.slice(i));
        if (match && regionNames.includes(match[1])) {
          // Advance the index to the end of the match and reset the path segment
          i += match[0].length - 1;
          pathSegment = '';

          // Region name is the first capture group
          // eslint-disable-next-line prefer-destructuring
          regionName = match[1];

          // Concatenation length is the (optional) second capture group
          if (match[2] != null) {
            // Add 1 to the length to account for the slash
            concatLengthsByRegion[regionName] = Number.parseInt(match[2], 10) + 1;
          }

          // If the next character after capture is a slash, and we did not define a
          // concatenation length in the previous conditional, then we need to
          // set the concatenation length to 0 as it denotes an absolute path.
          if (concatLengthsByRegion[regionName] === undefined && pathname[i + 1] === '/') {
            concatLengthsByRegion[regionName] = 0;
          }
        } else {
          // Same as default case
          pathSegment += char;
        }
        break;
      }
      case undefined:
        // If we are at the end of the path, push the last segment
        segmentsByRegion[regionName].push(pathSegment);
        break;
      default:
        pathSegment += char;
    }
  }

  return {
    [ROOT_REGION_NAME]: {
      pathname: joinPath(segmentsByRegion.root),
      key: rootLocation.key ?? '',
      search: rootLocation.search ?? '',
      hash: rootLocation.hash ?? '',
      state: rootLocation.state,
    },
    ...Object.fromEntries(
      regionNames.map((region) => [
        region,
        segmentsByRegion[region].length === 0
          ? null
          : {
              pathname: joinPath([
                ...segmentsByRegion.root.slice(0, concatLengthsByRegion[region]),
                ...segmentsByRegion[region],
              ]),
              key: rootLocation.key ?? '',
              search: rootLocation.search ?? '',
              hash: rootLocation.hash ?? '',
              state: rootLocation.state,
            },
      ]),
    ),
  };
};
