import React from 'react';
import { Navigate } from 'react-router';

import { paths } from '../../constants';
import { useDefaultCompany } from '../../providers/CurrentCompanyProvider';
import { makeLink } from '../../utils/main_and_drawer_navigation';

export const Meta = () => ({
  path: '/',
});

export default function RootPage() {
  const companyName = useDefaultCompany();
  return (
    <Navigate
      to={makeLink(paths.pages.CompanyRootPage, {
        companyName,
      })}
      replace
    />
  );
}
