import { isDefinedAndNotEmpty } from '@meterup/common';
import { Header, HeaderLogo, HeaderNavItem, HStack, space } from '@meterup/metric';
import React from 'react';
import { Navigate } from 'react-router';

import { Container, ContentContainer, HeaderContainer } from '../../components/app_layout';
import { FatalErrorFallback } from '../../components/ErrorFallback/ErrorFallback';
import { HeaderDropdownMenu } from '../../components/HeaderDropdownMenu';
import { ReactRouterLink } from '../../components/ReactRouterLink';
import { paths } from '../../constants';
import { NoHardwareError } from '../../errors';
import { useCurrentControllerOrNull } from '../../hooks/useCurrentControllerOrNull';
import { useCurrentCompany } from '../../providers/CurrentCompanyProvider';
import { makeLink } from '../../utils/main_and_drawer_navigation';

export const Meta = () => ({
  path: '/org/:companyName',
});

export default function CompanyRootPage() {
  const companyName = useCurrentCompany();
  const controllerName = useCurrentControllerOrNull();

  if (isDefinedAndNotEmpty(controllerName)) {
    return (
      <Navigate
        to={makeLink(paths.pages.OverviewPage, {
          companyName,
          controllerName,
        })}
        replace
      />
    );
  }

  const error = new NoHardwareError(
    'No hardware',
    'Your organization does not have any associated networks. Please contact support.',
  );

  return (
    <Container>
      <HeaderContainer>
        <Header
          navigation={
            <HStack align="center" spacing={space(8)}>
              <HeaderNavItem
                as={ReactRouterLink}
                to="/"
                label="Network"
                isSelected
                icon="network"
              />
            </HStack>
          }
          logo={<HeaderLogo />}
          controls={<HeaderDropdownMenu />}
        />
      </HeaderContainer>
      <ContentContainer>
        <FatalErrorFallback error={error} componentStack={null} />
      </ContentContainer>
    </Container>
  );
}
