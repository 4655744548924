import { useContext, useEffect } from 'react';

import { useCurrentCompanyOrDefault } from '../providers/CurrentCompanyProvider';
import { DefaultCompanyContext } from '../providers/DefaultCompanyProvider';

export const AutoSaveDefaultCompanyEffect = () => {
  const currentCompany = useCurrentCompanyOrDefault();
  const { setDefaultCompany } = useContext(DefaultCompanyContext);

  useEffect(() => {
    setDefaultCompany(currentCompany);
  }, [currentCompany, setDefaultCompany]);

  return null;
};
