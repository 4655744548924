import { fade, palette } from './colors';
import { createShadowAnimationStack } from './shadow_stacks';

const focusRing = `0 0 0 3px ${fade(palette['brand-500'], 0.7)}`;

export const shadowParts = {
  none: [],
  device: [
    `0 1px 2px 0 ${fade(palette['gray-900'], 0.05)}`,
    `0 1px 1px 0 ${fade(palette['gray-900'], 0.08)}`,
    `0 0 0 0.6px ${fade(palette['gray-900'], 0.04)}`,
    `inset 0 -1.5px 3px -2.2px rgba(42, 43, 48, 0.16)`,
    `inset 0 0.8px 2.8px 0 rgba(42, 43, 48, 0.06)`,
  ],
  controlPrimaryInitialLight: [
    `0 2px 1px 0 ${fade(palette['gray-900'], 0.12)}`,
    `0 2px 3px 0 ${fade(palette['gray-900'], 0.18)}`,
    `0 0 0 1px ${fade(palette['brand-900'], 0.85)}`,
    `inset 0 1px 0 0 ${fade(palette.white, 0.1)}`,
  ],
  controlPrimaryHoveredLight: [
    `0 2px 1px 0 ${fade(palette['gray-900'], 0.2)}`,
    `0 2px 3px 0 ${fade(palette['gray-900'], 0.35)}`,
    `0 0 0 1px ${fade(palette['brand-900'], 0.95)}`,
    `inset 0 1px 0 0 ${fade(palette.white, 0.1)}`,
  ],
  controlPrimaryFocusedLight: [
    `0 2px 1px 0 ${fade(palette['gray-900'], 0.2)}`,
    `0 2px 3px 0 ${fade(palette['gray-900'], 0.35)}`,
    `0 0 0 1px ${fade(palette['brand-900'], 0.95)}`,
    `inset 0 1px 0 0 ${fade(palette.white, 0.1)}`,
    focusRing,
  ],
  controlPrimaryDisabledLight: [
    `0 2px 1px 0 ${fade(palette['gray-900'], 0.02)}`,
    `0 2px 3px 0 ${fade(palette['gray-900'], 0.05)}`,
    `0 0 0 1px ${fade(palette['brand-900'], 1)}`,
  ],
  controlPrimaryInitialDark: [
    `0 2px 1px 0 ${fade(palette['gray-900'], 0.12)}`,
    `0 2px 3px 0 ${fade(palette['gray-900'], 0.2)}`,
    `0 0 0 1px ${fade(palette['gray-900'], 0.2)}`,
    `inset 0 1px 0 0 ${fade(palette.white, 0.1)}`,
  ],
  controlPrimaryHoveredDark: [
    `0 2px 1px 0 ${fade(palette['gray-900'], 0.16)}`,
    `0 2px 3px 0 ${fade(palette['gray-900'], 0.24)}`,
    `0 0 0 1px ${fade(palette['gray-900'], 0.24)}`,
    `inset 0 1px 0 0 ${fade(palette.white, 0.1)}`,
  ],
  controlPrimaryFocusedDark: [
    `0 2px 1px 0 ${fade(palette['gray-900'], 0.16)}`,
    `0 2px 3px 0 ${fade(palette['gray-900'], 0.24)}`,
    `0 0 0 1px ${fade(palette['gray-900'], 0.24)}`,
    `inset 0 1px 0 0 ${fade(palette.white, 0.1)}`,
    focusRing,
    `0 0 0 1px ${fade(palette['brand-900'], 1)}`,
  ],
  controlPrimaryDisabledDark: [
    `0 2px 1px 0 ${fade(palette['gray-900'], 0.02)}`,
    `0 0 0 1px ${fade(palette['gray-900'], 0.05)}`,
    `0 0 0 1px ${fade(palette['gray-900'], 0.16)}`,
  ],
  controlSecondaryInitialLight: [
    `0 2px 1px 0 ${fade(palette['gray-900'], 0.08)}`,
    `0 2px 3px 0 ${fade(palette['gray-900'], 0.05)}`,
    `0 0 0 1px ${fade(palette['gray-600'], 0.3)}`,
    `inset 0 1px 0 0 ${fade(palette.white, 1)}`,
  ],
  controlSecondaryHoveredLight: [
    `0 2px 1px 0 ${fade(palette['gray-900'], 0.12)}`,
    `0 2px 3px 0 ${fade(palette['gray-900'], 0.18)}`,
    `0 0 0 1px ${fade(palette['gray-600'], 0.4)}`,
    `inset 0 1px 0 0 ${fade(palette.white, 1)}`,
  ],
  controlSecondaryFocusedLight: [
    `0 2px 1px 0 ${fade(palette['gray-900'], 0.12)}`,
    `0 2px 3px 0 ${fade(palette['gray-900'], 0.18)}`,
    `0 0 0 1px ${fade(palette['gray-600'], 0.4)}`,
    `inset 0 1px 0 0 ${fade(palette.white, 1)}`,
    focusRing,
  ],
  controlSecondaryDisabledLight: [
    `0 2px 1px 0 ${fade(palette['gray-900'], 0.02)}`,
    `0 2px 3px 0 ${fade(palette['gray-900'], 0.05)}`,
    `0 0 0 1px ${fade(palette['gray-300'], 1)}`,
  ],
  controlSecondaryInitialDark: [
    `0 2px 1px 0 ${fade(palette['gray-900'], 0.12)}`,
    `0 2px 3px 0 ${fade(palette['gray-900'], 0.2)}`,
    `0 0 0 1px ${fade(palette['gray-900'], 0.4)}`,
    `inset 0 1px 0 0 ${fade(palette.white, 0.12)}`,
  ],
  controlSecondaryHoveredDark: [
    `0 2px 1px 0 ${fade(palette['gray-900'], 0.16)}`,
    `0 2px 3px 0 ${fade(palette['gray-900'], 0.24)}`,
    `0 0 0 1px ${fade(palette['gray-900'], 0.5)}`,
    `inset 0 1px 0 0 ${fade(palette.white, 0.18)}`,
  ],
  controlSecondaryFocusedDark: [
    `0 2px 1px 0 ${fade(palette['gray-900'], 0.16)}`,
    `0 2px 3px 0 ${fade(palette['gray-900'], 0.24)}`,
    `0 0 0 1px ${fade(palette['gray-900'], 0.5)}`,
    `inset 0 1px 0 0 ${fade(palette.white, 0.18)}`,
    `0 0 0 3px ${fade(palette['brand-500'], 0.9)}`,
    `0 0 0 1px ${fade(palette['brand-900'], 1)}`,
  ],
  controlSecondaryDisabledDark: [
    `0 2px 1px 0 ${fade(palette['gray-900'], 0.02)}`,
    `0 2px 3px 0 ${fade(palette['gray-900'], 0.05)}`,
    `0 0 0 1px ${fade(palette['gray-900'], 0.3)}`,
  ],
  controlDestructiveInitialLight: [
    `0 2px 1px 0 ${fade(palette['gray-900'], 0.12)}`,
    `0 2px 3px 0 ${fade(palette['gray-900'], 0.18)}`,
    `0 0 0 1px ${fade(palette['red-900'], 0.85)}`,
    `inset 0 1px 0 0 ${fade(palette.white, 0.1)}`,
  ],
  controlDestructiveHoveredLight: [
    `0 2px 1px 0 ${fade(palette['gray-900'], 0.2)}`,
    `0 2px 3px 0 ${fade(palette['gray-900'], 0.35)}`,
    `0 0 0 1px ${fade(palette['red-900'], 0.95)}`,
    `inset 0 1px 0 0 ${fade(palette.white, 0.1)}`,
  ],
  controlDestructiveFocusedLight: [
    `0 2px 1px 0 ${fade(palette['gray-900'], 0.2)}`,
    `0 2px 3px 0 ${fade(palette['gray-900'], 0.35)}`,
    `0 0 0 1px ${fade(palette['red-900'], 0.95)}`,
    `inset 0 1px 0 0 ${fade(palette.white, 0.1)}`,
    focusRing,
  ],
  controlDestructiveDisabledLight: [
    `0 2px 1px 0 ${fade(palette['gray-900'], 0.02)}`,
    `0 2px 3px 0 ${fade(palette['gray-900'], 0.05)}`,
    `0 0 0 1px ${fade(palette['red-900'], 1)}`,
  ],
  controlDestructiveInitialDark: [
    `0 2px 1px 0 ${fade(palette['gray-900'], 0.12)}`,
    `0 2px 3px 0 ${fade(palette['gray-900'], 0.2)}`,
    `0 0 0 1px ${fade(palette['gray-900'], 0.2)}`,
    `inset 0 1px 0 0 ${fade(palette.white, 0.12)}`,
  ],
  controlDestructiveHoveredDark: [
    `0 2px 1px 0 ${fade(palette['gray-900'], 0.16)}`,
    `0 2px 3px 0 ${fade(palette['gray-900'], 0.24)}`,
    `0 0 0 1px ${fade(palette['gray-900'], 0.24)}`,
    `inset 0 1px 0 0 ${fade(palette.white, 0.18)}`,
  ],
  controlDestructiveFocusedDark: [
    `0 2px 1px 0 ${fade(palette['gray-900'], 0.16)}`,
    `0 2px 3px 0 ${fade(palette['gray-900'], 0.24)}`,
    `0 0 0 1px ${fade(palette['gray-900'], 0.24)}`,
    `inset 0 1px 0 0 ${fade(palette.white, 0.1)}`,
    focusRing,
    `0 0 0 1px ${fade(palette['brand-900'], 1)}`,
  ],
  controlDestructiveDisabledDark: [
    `0 2px 1px 0 ${fade(palette['gray-900'], 0.02)}`,
    `0 2px 3px 0 ${fade(palette['gray-900'], 0.05)}`,
    `0 0 0 1px ${fade(palette['gray-900'], 0.16)}`,
  ],
  controlTertiaryInitialLight: [`0 0 0 1px ${palette['gray-100']}`],
  controlTertiaryHoveredLight: [`0 0 0 1px ${palette['gray-200']}`],
  controlTertiaryDisabledLight: [`0 0 0 1px ${palette['gray-100']}`],
  controlTertiaryFocusedLight: [focusRing],
  controlTertiaryInitialDark: [`0 0 0 1px ${palette['gray-600']}`],
  controlTertiaryHoveredDark: [`0 0 0 1px ${palette['gray-500']}`],
  controlTertiaryDisabledDark: [`0 0 0 1px ${palette['gray-700']}`],
  controlTertiaryFocusedDark: [
    `0 0 0 1px ${fade(palette['brand-100'], 0.7)}`,
    `0 0 0 4px ${fade(palette['brand-200'], 0.7)}`,
  ],
  fieldInitialLight: [
    `0 2px 1px 0 ${fade(palette['gray-900'], 0.08)}`,
    `0 2px 3px 0 ${fade(palette['gray-900'], 0.05)}`,
    `0 0 0 1px ${fade(palette['gray-600'], 0.3)}`,
  ],
  fieldHoveredLight: [
    `0 2px 1px 0 ${fade(palette['gray-900'], 0.12)}`,
    `0 2px 3px 0 ${fade(palette['gray-900'], 0.18)}`,
    `0 0 0 1px ${fade(palette['gray-600'], 0.4)}`,
  ],
  fieldFocusedLight: [
    `0 2px 1px 0 ${fade(palette['gray-900'], 0.12)}`,
    `0 2px 3px 0 ${fade(palette['gray-900'], 0.18)}`,
    focusRing,
  ],
  fieldDisabledLight: [
    `0 2px 1px 0 ${fade(palette['gray-900'], 0.08)}`,
    `0 2px 3px 0 ${fade(palette['gray-900'], 0.05)}`,
    `0 0 0 1px rgba(177, 181, 199, 1)`,
  ],
  fieldErrorLight: [
    `0 2px 1px 0 ${fade(palette['gray-900'], 0.08)}`,
    `0 2px 3px 0 ${fade(palette['gray-900'], 0.05)}`,
    `0 0 0 1.5px ${fade(palette['red-600'], 0.8)}`,
  ],
  fieldInitialDark: [
    `0 2px 1px 0 ${fade(palette['gray-900'], 0.25)}`,
    `0 2px 3px 0 ${fade(palette['gray-900'], 0.4)}`,
    `0 0 0 1px ${fade(palette['gray-900'], 0.5)}`,
  ],
  fieldHoveredDark: [
    `0 2px 1px 0 ${fade(palette['gray-900'], 0.4)}`,
    `0 2px 3px 0 ${fade(palette['gray-900'], 0.5)}`,
    `0 0 0 1px ${fade(palette['gray-900'], 0.7)}`,
  ],
  fieldFocusedDark: [
    `0 2px 1px 0 ${fade(palette['gray-900'], 0.4)}`,
    `0 2px 3px 0 ${fade(palette['gray-900'], 0.5)}`,
    `0 0 0 1px ${fade(palette['gray-900'], 0.7)}`,
    `0 0 0 3px ${fade(palette['brand-500'], 0.5)}`,
    `0 0 0 1px ${fade(palette['brand-500'], 0.6)}`,
  ],
  fieldDisabledDark: [
    `0 2px 1px 0 ${fade(palette['gray-900'], 0.02)}`,
    `0 2px 3px 0 ${fade(palette['gray-900'], 0.05)}`,
    `0 0 0 1px ${fade(palette['gray-900'], 0.4)}`,
  ],
  fieldErrorDark: [
    `0 2px 1px 0 ${fade(palette['gray-900'], 0.25)}`,
    `0 2px 3px 0 ${fade(palette['gray-900'], 0.4)}`,
    `0 0 0 1px ${fade(palette['red-300'], 0.8)}`,
  ],
  surfaceTopLight: [`0 -1px 0 0 ${fade(palette['gray-600'], 0.15)}`],
  surfaceTopDark: [`0 -1px 0 0 ${fade(palette['gray-900'], 1)}`],
  surfaceTopInsetLight: [`inset 0 1px 0 0 ${fade(palette['gray-600'], 0.15)}`],
  surfaceTopInsetDark: [`inset 0 1px 0 0 ${fade(palette['gray-900'], 1)}`],
  surfaceRightLight: [`1px 0 0 0 ${fade(palette['gray-600'], 0.15)}`],
  surfaceRightDark: [`1px 0 0 0 ${fade(palette['gray-900'], 1)}`],
  surfaceRightInsetLight: [`inset -1px 0 0 0 ${fade(palette['gray-600'], 0.15)}`],
  surfaceRightInsetDark: [`inset -1px 0 0 0 ${fade(palette['gray-900'], 1)}`],
  surfaceBottomLight: [`0 1px 0 0 ${fade(palette['gray-600'], 0.15)}`],
  surfaceBottomDark: [`0 1px 0 0 ${fade(palette['gray-900'], 1)}`],
  surfaceBottomInsetLight: [`inset 0 -1px 0 0 ${fade(palette['gray-600'], 0.15)}`],
  surfaceBottomInsetDark: [`inset 0 -1px 0 0 ${fade(palette['gray-900'], 1)}`],
  surfaceLeftLight: [`-1px 0 0 0 ${fade(palette['gray-600'], 0.15)}`],
  surfaceLeftDark: [`-1px 0 0 0 ${fade(palette['gray-900'], 1)}`],
  surfaceLeftInsetLight: [`inset 1px 0 0 0 ${fade(palette['gray-600'], 0.15)}`],
  surfaceLeftInsetDark: [`inset 1px 0 0 0 ${fade(palette['gray-900'], 1)}`],
  fenceAllLight: [
    `0 0 0 0.5px ${palette['fence-light']}`,
    `inset 0 0 0 0.5px ${palette['fence-light']}`,
  ],
  fenceAllDark: [
    `0 0 0 0.5px ${palette['fence-dark']}`,
    `inset 0 0 0 0.5px ${palette['fence-dark']}`,
  ],
  fenceActiveAllLight: [
    `0 0 0 0.5px ${palette['brand-200']}`,
    `inset 0 0 0 0.5px ${palette['brand-200']}`,
  ],
  fenceActiveAllDark: [
    `0 0 0 0.5px ${palette['brand-700']}`,
    `inset 0 0 0 0.5px ${palette['brand-700']}`,
  ],
  fenceErrorAllLight: [
    `0 0 0 0.5px ${palette['red-100']}`,
    `inset 0 0 0 0.5px ${palette['red-100']}`,
  ],
  fenceErrorAllDark: [
    `0 0 0 0.5px ${palette['red-700']}`,
    `inset 0 0 0 0.5px ${palette['red-700']}`,
  ],
  fenceTopBottomLight: [
    `0 0.5px 0 0 ${palette['fence-light']}`,
    `inset 0 -0.5px 0 0 ${palette['fence-light']}`,
    `0 -0.5px 0 0 ${palette['fence-light']}`,
    `inset 0 0.5px 0 0 ${palette['fence-light']}`,
  ],
  fenceTopBottomDark: [
    `0 0.5px 0 0 ${palette['fence-dark']}`,
    `inset 0 -0.5px 0 0 ${palette['fence-dark']}`,
    `0 -0.5px 0 0 ${palette['fence-dark']}`,
    `inset 0 0.5px 0 0 ${palette['fence-dark']}`,
  ],
  fenceErrorTopBottomLight: [
    `0 0.5px 0 0 ${palette['red-100']}`,
    `inset 0 -0.5px 0 0 ${palette['red-100']}`,
    `0 -0.5px 0 0 ${palette['red-100']}`,
    `inset 0 0.5px 0 0 ${palette['red-100']}`,
  ],
  fenceErrorTopBottomDark: [
    `0 0.5px 0 0 ${palette['red-700']}`,
    `inset 0 -0.5px 0 0 ${palette['red-700']}`,
    `0 -0.5px 0 0 ${palette['red-700']}`,
    `inset 0 0.5px 0 0 ${palette['red-700']}`,
  ],
  rowActiveTopBottomLight: [
    `0 0.5px 0 0 ${palette['brand-200']}`,
    `inset 0 -0.5px 0 0 ${palette['brand-200']}`,
    `0 -0.5px 0 0 ${palette['brand-200']}`,
    `inset 0 0.5px 0 0 ${palette['brand-200']}`,
  ],
  rowActiveTopBottomDark: [
    `0 0.5px 0 0 ${palette['brand-700']}`,
    `inset 0 -0.5px 0 0 ${palette['brand-700']}`,
    `0 -0.5px 0 0 ${palette['brand-700']}`,
    `inset 0 0.5px 0 0 ${palette['brand-700']}`,
  ],
  rowFocusedTopBottomLight: [
    `0 1px 0 0 ${palette['brand-400']}`,
    `inset 0 -1px 0 0 ${palette['brand-400']}`,
    `0 -1px 0 0 ${palette['brand-400']}`,
    `inset 0 1px 0 0 ${palette['brand-400']}`,
  ],
  rowFocusedTopBottomDark: [
    `0 1px 0 0 ${palette['brand-500']}`,
    `inset 0 -1px 0 0 ${palette['brand-500']}`,
    `0 -1px 0 0 ${palette['brand-500']}`,
    `inset 0 1px 0 0 ${palette['brand-500']}`,
  ],
  fenceRightLeftLight: [
    `0.5px 0 0 0 ${palette['fence-light']}`,
    `inset -0.5px 0 0 0 ${palette['fence-light']}`,
    `-0.5px 0 0 0 ${palette['fence-light']}`,
    `inset 0.5px 0 0 0 ${palette['fence-light']}`,
  ],
  fenceRightLeftDark: [
    `0.5px 0 0 0 ${palette['fence-dark']}`,
    `inset -0.5px 0 0 0 ${palette['fence-dark']}`,
    `-0.5px 0 0 0 ${palette['fence-dark']}`,
    `inset 0.5px 0 0 0 ${palette['fence-dark']}`,
  ],
  fenceTopLight: [
    `0 -0.5px 0 0 ${palette['fence-light']}`,
    `inset 0 0.5px 0 0 ${palette['fence-light']}`,
  ],
  fenceTopDark: [
    `0 -0.5px 0 0 ${palette['fence-dark']}`,
    `inset 0 0.5px 0 0 ${palette['fence-dark']}`,
  ],
  fenceRightLight: [
    `0.5px 0 0 0 ${palette['fence-light']}`,
    `inset -0.5px 0 0 0 ${palette['fence-light']}`,
  ],
  fenceRightDark: [
    `0.5px 0 0 0 ${palette['fence-dark']}`,
    `inset -0.5px 0 0 0 ${palette['fence-dark']}`,
  ],
  fenceBottomLight: [
    `0 0.5px 0 0 ${palette['fence-light']}`,
    `inset 0 -0.5px 0 0 ${palette['fence-light']}`,
  ],
  fenceBottomDark: [
    `0 0.5px 0 0 ${palette['fence-dark']}`,
    `inset 0 -0.5px 0 0 ${palette['fence-dark']}`,
  ],
  fenceLeftLight: [
    `-0.5px 0 0 0 ${palette['fence-light']}`,
    `inset 0.5px 0 0 0 ${palette['fence-light']}`,
  ],
  fenceLeftDark: [
    `-0.5px 0 0 0 ${palette['fence-dark']}`,
    `inset 0.5px 0 0 0 ${palette['fence-dark']}`,
  ],
  fenceFocusedLight: [
    `0 0 0 0.5px ${palette['fence-light']}`,
    `inset 0 0 0 0.5px ${palette['fence-light']}`,
    focusRing,
  ],
  fenceFocusedDark: [
    `0 0 0 0.5px ${palette['fence-dark']}`,
    `inset 0 0 0 0.5px ${palette['fence-dark']}`,
    focusRing,
  ],
  focusRingLight: [focusRing],
  focusRingDark: [
    `0 0 0 1px ${fade(palette['brand-100'], 0.7)}`,
    `0 0 0 4px ${fade(palette['brand-200'], 0.7)}`,
  ],
  paneTopLight: [
    `0 -3px 5px 0 ${fade(palette['gray-900'], 0.05)}`,
    `0 -1px 2px 0 ${fade(palette['gray-900'], 0.05)}`,
    `0 -1px 0 0 ${fade(palette['gray-900'], 0.04)}`,
  ],
  paneTopDark: [
    `0 -4px 5px 0 ${fade(palette['gray-900'], 0.15)}`,
    `0 -2px 2px 0 ${fade(palette['gray-900'], 0.4)}`,
    `0 -1px 0 0 ${fade(palette['gray-900'], 0.45)}`,
  ],
  paneTopInsetLight: [
    `inset 0 3px 5px 0 ${fade(palette['gray-900'], 0.05)}`,
    `inset 0 1px 2px 0 ${fade(palette['gray-900'], 0.05)}`,
    `inset 0 1px 0 0 ${fade(palette['gray-900'], 0.04)}`,
  ],
  paneTopInsetDark: [
    `inset 0 4px 5px 0 ${fade(palette['gray-900'], 0.15)}`,
    `inset 0 2px 2px 0 ${fade(palette['gray-900'], 0.4)}`,
    `inset 0 1px 0 0 ${fade(palette['gray-900'], 0.45)}`,
  ],
  paneRightLight: [
    `3px 0 5px 0 ${fade(palette['gray-900'], 0.05)}`,
    `1px 0 2px 0 ${fade(palette['gray-900'], 0.05)}`,
    `1px 0 0 0 ${fade(palette['gray-900'], 0.04)}`,
  ],
  paneRightDark: [
    `4px 0 5px 0 ${fade(palette['gray-900'], 0.15)}`,
    `2px 0 2px 0 ${fade(palette['gray-900'], 0.4)}`,
    `1px 0 0 0 ${fade(palette['gray-900'], 0.45)}`,
  ],
  paneRightInsetLight: [
    `inset -3px 0 5px 0 ${fade(palette['gray-900'], 0.05)}`,
    `inset -1px 0 2px 0 ${fade(palette['gray-900'], 0.05)}`,
    `inset -1px 0 0 0 ${fade(palette['gray-900'], 0.04)}`,
  ],
  paneRightInsetDark: [
    `inset -4px 0 5px 0 ${fade(palette['gray-900'], 0.15)}`,
    `inset -2px 0 2px 0 ${fade(palette['gray-900'], 0.4)}`,
    `inset -1px 0 0 0 ${fade(palette['gray-900'], 0.45)}`,
  ],
  paneLeftLight: [
    `-3px 0 5px 0 ${fade(palette['gray-900'], 0.05)}`,
    `-1px 0 2px 0 ${fade(palette['gray-900'], 0.05)}`,
    `-1px 0 0 0 ${fade(palette['gray-900'], 0.04)}`,
  ],
  paneLeftDark: [
    `inset 3px 0 5px 0 ${fade(palette['gray-900'], 0.05)}`,
    `inset 1px 0 2px 0 ${fade(palette['gray-900'], 0.05)}`,
    `inset 1px 0 0 0 ${fade(palette['gray-900'], 0.04)}`,
  ],
  paneLeftInsetLight: [
    `inset 3px 0 5px 0 ${fade(palette['gray-900'], 0.05)}`,
    `inset 1px 0 2px 0 ${fade(palette['gray-900'], 0.05)}`,
    `inset 1px 0 0 0 rgba(41, 42, 51, 0.04)`,
  ],
  paneLeftInsetDark: [
    `inset 4px 0 5px 0 ${fade(palette['gray-900'], 0.15)}`,
    `inset 2px 0 2px 0 ${fade(palette['gray-900'], 0.4)}`,
    `inset 1px 0 0 0 ${fade(palette['gray-900'], 0.45)}`,
  ],
  paneBottomLight: [
    `0 3px 5px 0 ${fade(palette['gray-900'], 0.05)}`,
    `0 1px 2px 0 ${fade(palette['gray-900'], 0.05)}`,
    `0 1px 0 0 ${fade(palette['gray-900'], 0.04)}`,
  ],
  paneBottomDark: [
    `0 4px 5px 0 ${fade(palette['gray-900'], 0.15)}`,
    `0 2px 2px 0 ${fade(palette['gray-900'], 0.4)}`,
    `0 1px 0 0 ${fade(palette['gray-900'], 0.45)}`,
  ],
  paneBottomInsetLight: [
    `inset 0 -3px 5px 0 ${fade(palette['gray-900'], 0.05)}`,
    `inset 0 -1px 2px 0 ${fade(palette['gray-900'], 0.05)}`,
    `inset 0 -1px 0 0 ${fade(palette['gray-900'], 0.04)}`,
  ],
  paneBottomInsetDark: [
    `inset 0 -4px 5px 0 ${fade(palette['gray-900'], 0.15)}`,
    `inset 0 -2px 2px 0 ${fade(palette['gray-900'], 0.4)}`,
    `inset 0 -1px 0 0 ${fade(palette['gray-900'], 0.45)}`,
  ],
  overlayLight: [
    `0 3px 5px 0 ${fade(palette['gray-900'], 0.05)}`,
    `0 2px 3px 0 ${fade(palette['gray-900'], 0.05)}`,
    `0 1px 0 0 ${fade(palette['gray-900'], 0.05)}`,
    `0 0 0 1px ${fade(palette['gray-900'], 0.08)}`,
  ],
  overlayDark: [
    `0 2px 3px 0 ${fade(palette['gray-900'], 0.1)}`,
    `0 1px 2px 0 ${fade(palette['gray-900'], 0.4)}`,
    `0 1px 0 0 ${fade(palette['gray-900'], 0.3)}`,
    `0 0 0 1px ${fade(palette['gray-900'], 0.4)}`,
  ],
  modalLight: [
    `0 9px 11px 0 ${fade(palette['gray-900'], 0.05)}`,
    `0 7px 8px 0 ${fade(palette['gray-900'], 0.05)}`,
    `0 3px 0 0 ${fade(palette['gray-900'], 0.04)}`,
    `0 0 0 1px ${fade(palette['gray-900'], 0.08)}`,
  ],
  modalDark: [
    `0 9px 11px 0 ${fade(palette['gray-900'], 0.1)}`,
    `0 7px 8px 0 ${fade(palette['gray-900'], 0.4)}`,
    `0 3px 0 0 ${fade(palette['gray-900'], 0.3)}`,
    `0 0 0 1px ${fade(palette['gray-900'], 0.4)}`,
  ],
  manufacturerLight: [
    `0 0 0 1px ${fade(palette['gray-900'], 0.06)}`,
    `0px 1px 0px ${fade(palette['gray-900'], 0.06)}`,
    `0px 2px 1px ${fade(palette['gray-900'], 0.05)}`,
  ],
  manufacturerDark: [
    `0 0 0 1px ${fade(palette['gray-700'], 0.06)}`,
    `0px 1px 0px ${fade(palette['gray-700'], 0.1)}`,
    `0px 2px 1px ${fade(palette['gray-700'], 0.3)}`,
  ],
};

export const shadows = createShadowAnimationStack(shadowParts);
