import type { SVGProps } from 'react';
import * as React from 'react';
import { useId } from 'react-aria';

const WireGuardLogo = (props: SVGProps<SVGSVGElement>) => {
  const id = useId();

  return (
    <svg width={80} height={80} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath={`url(#${id})`}>
        <path
          d="M79.977 0H.023A.023.023 0 0 0 0 .023v79.954c0 .013.01.023.023.023h79.954c.013 0 .023-.01.023-.023V.023A.023.023 0 0 0 79.977 0Z"
          fill="#88171A"
        />
        <path
          d="m37.188 8.281 5.468 1.25v.313c-2.343.312-4.687-.625-7.031-.781 1.719 1.093 3.594 1.718 5.469 2.343-2.969 2.5-5.469-.781-8.75 1.406 3.125 1.563 2.968 1.25 3.281 4.22-1.406.155-3.75 1.562-4.219 2.5 2.032.468 4.375 0 6.407 1.25-.626.468-2.188 1.093-2.813 1.562 1.406.312 3.125-.313 3.906.156 2.969 2.5 8.438 5.938 10 9.375 2.657 5.781-3.437 12.031-9.375 12.969-8.281 1.718-12.968 10.312-10 18.281 2.969 7.813 12.188 11.25 19.532 7.188 10.312-6.25 8.75-16.876 2.5-22.657-.313-.312-.626-.312-.938 0a50.125 50.125 0 0 1-7.031 3.75c5.625 1.25 6.406 5.469 5.781 8.438-2.031 7.5-12.188 5.781-13.281-.625-.469-2.969 1.093-5.938 3.75-7.188 9.218-4.062 13.593-4.687 16.25-15.156.937-5.938-.469-9.063-4.844-12.5-1.719-1.719-5.156-2.813-6.25-5.469-.156-.312.156-.937.469-.937 1.562-.313 7.656-.469 7.656-.157 1.094 1.094 2.031-.625 2.5-1.406-1.563-.312-3.281-.156-4.531-.156-.157 0-.469-.313-.625-.469.156-.156.468-.312.625-.312H57.5c0-1.094-1.406-2.656-2.813-2.969v.469c-1.25.156-2.5-.156-3.75-.625-.624-.469-1.093-1.406-1.718-1.719-2.5-1.406-5.157-2.5-8.438-2.5-1.562 0-2.656.156-3.593.156ZM48.75 12.97l.469.469-.625.312c-.313.156-.469 0-.625-.156-.313-.469.625-.781.781-.625Zm-18.75 15c-8.438 5.156-7.969 17.031-.469 21.718.625.313.938.313 1.25-.156 1.875-2.343 3.594-3.437 5.625-4.687-3.906-.625-5.937-2.5-5.781-5.157C30 30.313 43.594 31.25 42.188 40c-.313 1.563-1.25 2.969-2.5 3.906 4.218-.937 7.343-3.281 8.593-7.5.313-1.25.313-2.968-.312-4.062C43.28 25.469 36.25 24.062 30 27.969Zm-9.688 30.468c2.5-1.093 5.157-1.562 7.657-2.03.156-2.032.781-4.063 2.031-5.626-4.688 0-8.594 3.125-9.688 7.657Z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width={80} height={80} rx={16} fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default WireGuardLogo;
