import { observer } from 'mobx-react-lite';

import { useCommandShortcuts } from './hooks/useCommandShortcuts';
import { useCommandToggle } from './hooks/useCommandToggle';

export const GlobalEvents = observer(() => {
  useCommandToggle();
  useCommandShortcuts();
  return null;
});
