import React from 'react';

import type { IconName } from '../../assets/Icon/Icon';
import Icon from '../../assets/Icon/Icon';
import { colors, darkThemeSelector, fontWeights, shadows, styled } from '../../stitches.config';
import { Body2 } from '../../text/Body';

export type AlertVariant = 'alternative' | 'brand' | 'negative' | 'neutral' | 'positive';

export type AlertCornerStyle = 'round' | 'square';

export type AlertProps = {
  /**
   * The more detailed information regarding the notice, no value disables no copy.
   */
  copy?: React.ReactNode;
  /**
   * A simple heading for the notice, no value disables no heading.
   */
  heading?: React.ReactNode;
  /**
   * Set which icon to display, no value displays no icon.
   */
  icon?: IconName;
  /**
   * Set the most appropriate variant of the component for your use.
   */
  variant?: AlertVariant;
  /**
   * Set whether the radius should exist or be squared off.
   */
  cornerStyle?: AlertCornerStyle;
  /**
   * Actions to be displayed at the bottom of the alert.
   */
  trailingButtons?: React.ReactNode;
};

const Container = styled('div', {
  padding: '$4',
  hStack: '$12',
  alignItems: 'stretch',
  background: '$$backgroundColor',
  variants: {
    variant: {
      alternative: {
        $$backgroundColor: colors['blue-50'],
        $$headingColor: colors['blue-800'],
        $$copyColor: colors['blue-700'],
        $$iconColor: colors['blue-700'],
        $$tabColor: colors['blue-500'],
        [darkThemeSelector]: {
          $$backgroundColor: colors['blue-900'],
          $$headingColor: colors['blue-50'],
          $$copyColor: colors['blue-100'],
          $$iconColor: colors['blue-100'],
          $$tabColor: colors['blue-300'],
        },
      },
      brand: {
        $$backgroundColor: colors['brand-50'],
        $$headingColor: colors['brand-800'],
        $$copyColor: colors['brand-700'],
        $$iconColor: colors['brand-700'],
        $$tabColor: colors['brand-500'],
        [darkThemeSelector]: {
          $$backgroundColor: colors['brand-900'],
          $$headingColor: colors['brand-50'],
          $$copyColor: colors['brand-100'],
          $$iconColor: colors['brand-100'],
          $$tabColor: colors['brand-300'],
        },
      },
      negative: {
        $$backgroundColor: colors['red-50'],
        $$headingColor: colors['red-800'],
        $$copyColor: colors['red-700'],
        $$iconColor: colors['red-700'],
        $$tabColor: colors['red-600'],
        [darkThemeSelector]: {
          $$backgroundColor: colors['red-900'],
          $$headingColor: colors['red-50'],
          $$copyColor: colors['red-100'],
          $$iconColor: colors['red-100'],
          $$tabColor: colors['red-300'],
        },
      },
      neutral: {
        $$backgroundColor: colors['gray-50'],
        $$headingColor: colors['gray-800'],
        $$copyColor: colors['gray-700'],
        $$iconColor: colors['gray-900'],
        $$tabColor: colors['gray-500'],
        [darkThemeSelector]: {
          $$backgroundColor: colors['gray-700'],
          $$headingColor: colors['gray-50'],
          $$copyColor: colors['gray-100'],
          $$iconColor: colors['gray-100'],
          $$tabColor: colors['gray-200'],
        },
      },
      positive: {
        $$backgroundColor: colors['green-50'],
        $$headingColor: colors['green-900'],
        $$copyColor: colors['green-800'],
        $$iconColor: colors['green-800'],
        $$tabColor: colors['green-700'],
        [darkThemeSelector]: {
          $$backgroundColor: colors['green-900'],
          $$headingColor: colors['green-50'],
          $$copyColor: colors['green-100'],
          $$iconColor: colors['green-100'],
          $$tabColor: colors['green-500'],
        },
      },
    },
    grouping: {
      standalone: {
        borderRadius: '$8',
      },
      stacked: {
        boxShadow: shadows.fenceAllLight,
        [darkThemeSelector]: {
          boxShadow: shadows.fenceAllDark,
        },
      },
    },
  },
});

const IconAndContent = styled('div', {
  hStack: '$12',
  paddingY: '$8',
  paddingRight: '$8',
  alignItems: 'flex-start',
});

const Content = styled('div', {
  vStack: 0,
  alignItems: 'stretch',
});

const Tab = styled('div', {
  width: 4,
  background: '$$tabColor',
  borderRadius: 100,
  flexShrink: 0,
});

const StyledIcon = styled(Icon, {
  color: '$$iconColor',
  marginTop: '$2',
});

const Heading = styled(Body2, {
  fontWeight: fontWeights.medium,
  color: '$$headingColor',
});

const Copy = styled(Body2, {
  color: '$$copyColor',
});

const Buttons = styled('div', {
  hStack: '$8',
  '* + &': {
    marginTop: '$8',
  },
});

export const Alert = ({
  copy,
  heading,
  icon,
  trailingButtons,
  variant = 'neutral',
  cornerStyle = 'round',
}: AlertProps) => (
  <Container variant={variant} grouping={cornerStyle === 'round' ? 'standalone' : 'stacked'}>
    <Tab />
    <IconAndContent>
      {icon && <StyledIcon icon={icon} />}
      {(heading || copy) && (
        <Content>
          {heading && <Heading>{heading}</Heading>}
          {copy && <Copy>{copy}</Copy>}
          {trailingButtons && <Buttons>{trailingButtons}</Buttons>}
        </Content>
      )}
    </IconAndContent>
  </Container>
);

export default Alert;
