import type { ButtonProps } from '@meterup/metric';
import { Button } from '@meterup/metric';
import { useFormikContext } from 'formik';
import React from 'react';
import { useNavigate } from 'react-router-dom';

type PartialButtonProps = Partial<ButtonProps>;

export const CancelAndGoBackButton = ({
  children = 'Cancel',
  variant = 'secondary',
  ...props
}: PartialButtonProps) => {
  const navigate = useNavigate();
  return (
    <Button {...props} variant={variant} onClick={() => navigate(-1)}>
      {children}
    </Button>
  );
};

export const DrawerFormSaveButton = ({
  type = 'submit',
  children = 'Save',
  ...props
}: PartialButtonProps) => (
  <Button type={type} {...props}>
    {children}
  </Button>
);

export const DiscardChangesButton = ({
  children = 'Discard',
  variant = 'secondary',
  ...props
}: PartialButtonProps) => {
  const formik = useFormikContext<unknown>();

  return (
    <Button {...props} variant={variant} onClick={() => formik.resetForm()}>
      {children}
    </Button>
  );
};
