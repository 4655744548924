import { checkDefinedOrThrow, expectDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import {
  Alert,
  Button,
  Drawer,
  DrawerContent,
  DrawerControls,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  PrimaryField,
  space,
  TextInput,
  VStack,
} from '@meterup/metric';
import { Form, Formik } from 'formik';
import { orderBy } from 'lodash';
import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import * as z from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';

import { fetchClientConnectionHistory, upsertMACAddressAlias } from '../../../../api/api';
import { CloseDrawerButton } from '../../../../components/CloseDrawerButton';
import { FieldProvider } from '../../../../components/FieldProvider';
import { notify } from '../../../../components/Notifications';
import { paths } from '../../../../constants';
import { Nav } from '../../../../nav';
import { useCurrentCompany } from '../../../../providers/CurrentCompanyProvider';
import { useCurrentController } from '../../../../providers/CurrentControllerProvider';
import { styled } from '../../../../stitches';
import { logError } from '../../../../utils/logError';

const validClientFormData = z.object({
  alias: z.string().optional(),
});

type ValidClientFormData = z.infer<typeof validClientFormData>;

const StyledForm = styled(Form, {
  display: 'contents',
});

const successfulMutationString = (
  originalValues: { alias: string; hostname: string },
  newValue = '',
): string => {
  const originalName = originalValues.alias || originalValues.hostname;

  if (newValue === '') {
    return `Alias removed from ${originalName}.`;
  }
  if (originalValues.alias) {
    return `${originalName} was renamed to ${newValue}.`;
  }

  return `Added ${newValue} as an alias for ${originalName}.`;
};

export const Meta = () => ({
  path: '/org/:companyName/controller/:controllerName/clients/:macAddress/rename',
});

export default function ClientRenamePage() {
  const company = useCurrentCompany();
  const controller = useCurrentController();
  const { macAddress } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.ClientRenamePage),
  );

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data: clientHistory } =
    useQuery(
      ['client_history', macAddress],
      () => fetchClientConnectionHistory(controller, macAddress),
      {
        suspense: true,
      },
    ) ?? [];

  const client = orderBy(clientHistory, (d) => d.last_seen, 'desc')[0] ?? null;

  expectDefinedOrThrow(client, new ResourceNotFoundError('Client not found'));

  const createUserMutation = useMutation(
    async (values: ValidClientFormData) =>
      upsertMACAddressAlias(company, macAddress, values.alias || ''),
    {
      onMutate: () => ({ originalValues: { alias: client.alias, hostname: client.name } }),
      onSuccess: (_, v, context) => {
        notify(successfulMutationString(context!.originalValues, v.alias), {
          variant: 'positive',
          icon: 'checkmarkCircle',
        });
        queryClient.invalidateQueries(['client_history', macAddress]);
        queryClient.invalidateQueries(['clients', controller]);
        navigate(-1);
      },
      onError: (e) => {
        notify('There was an error setting the name. Please try again.', {
          variant: 'negative',
          icon: 'crossCircle',
        });
        logError(e);
      },
    },
  );

  return (
    <Formik<ValidClientFormData>
      validationSchema={toFormikValidationSchema(validClientFormData)}
      initialValues={{ alias: client.alias }}
      onSubmit={(v) => createUserMutation.mutate(v)}
    >
      <StyledForm>
        <Drawer>
          <DrawerHeader>
            <DrawerTitle>Rename client</DrawerTitle>
            <DrawerControls>
              <CloseDrawerButton />
            </DrawerControls>
          </DrawerHeader>
          <DrawerContent>
            <Alert
              icon="information"
              variant="alternative"
              heading="Renaming a client"
              copy={
                <VStack spacing={space(8)}>
                  <div>
                    This sets or changes the existing alias for the client but the client’s name
                    will continue to show in places where aliases aren’t supported.
                  </div>
                </VStack>
              }
            />
            <FieldProvider name="alias">
              <PrimaryField label="New name" element={<TextInput />} />
            </FieldProvider>
          </DrawerContent>
          <DrawerFooter>
            <DrawerControls>
              <Button type="button" onClick={() => navigate(-1)} variant="secondary">
                Cancel
              </Button>
              <Button type="submit">Save</Button>
            </DrawerControls>
          </DrawerFooter>
        </Drawer>
      </StyledForm>
    </Formik>
  );
}
