import React from 'react';

import { Background, Glyph } from '../common';

export const ProductIconNetwork = () => (
  <>
    <Background
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 8C16 10.7289 15.814 13.8076 14.8139 14.8105C13.8139 15.8134 10.2326 16 8.02326 16C5.81394 16 2.23257 15.8134 1.23256 14.8105C0.232559 13.8076 0 10.7289 0 8C0 5.27115 0.232557 2.19243 1.23256 1.1895C2.23257 0.186588 5.81394 0 8.02326 0C10.2326 0 13.814 0.186588 14.814 1.1895C15.814 2.19243 16 5.27115 16 8Z"
      fill="#66687A"
    />
    <Glyph
      d="M13.3538 6.53896C13.5561 6.30918 13.5496 5.93277 13.3271 5.72854C11.8761 4.39724 10.0294 3.6 8.0188 3.6C5.98977 3.6 4.12772 4.4119 2.67079 5.76523C2.45016 5.97017 2.4447 6.3448 2.64624 6.57369L3.00476 6.98084C3.1945 7.19632 3.49872 7.20258 3.70905 7.01335C4.89328 5.94785 6.39061 5.31162 8.0188 5.31162C9.63083 5.31162 11.1146 5.9353 12.2932 6.98177C12.5036 7.16853 12.8059 7.16114 12.9947 6.94674L13.3538 6.53896Z"
      fill="#FAFAFC"
    />
    <Glyph
      d="M9.04752 10.5285C9.32828 10.6681 9.35477 11.0804 9.13803 11.3266L8.33999 12.2329C8.14379 12.4557 7.8257 12.4557 7.62951 12.2329L6.85311 11.3512C6.63907 11.1081 6.66146 10.7016 6.93581 10.5564C7.26486 10.3822 7.63184 10.2849 8.01883 10.2849C8.38465 10.2849 8.73259 10.3718 9.04752 10.5285Z"
      fill="#FAFAFC"
    />
    <Glyph
      d="M8.01876 7.00035C9.19805 7.00035 10.29 7.42505 11.1825 8.14711C11.4194 8.33884 11.4299 8.72372 11.2242 8.95737L10.8586 9.3726C10.6754 9.58068 10.3845 9.59279 10.1672 9.43259C9.54875 8.97673 8.81098 8.71197 8.01876 8.71197C7.21122 8.71197 6.46026 8.98707 5.83457 9.45925C5.61681 9.62359 5.32274 9.61333 5.13786 9.40337L4.7737 8.98981C4.5692 8.75757 4.5781 8.37544 4.81201 8.18229C5.71232 7.43886 6.82045 7.00035 8.01876 7.00035Z"
      fill="#FAFAFC"
    />
  </>
);

export default ProductIconNetwork;
