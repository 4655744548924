import { styled } from '../stitches';

export const Container = styled('div', {
  height: '100%',
  display: 'grid',
  position: 'relative',
  gridTemplateColumns: '1fr',
  gridTemplateRows: 'min-content minmax(0, 1fr)',
  gridTemplateAreas: '"header" "content"',
});

export const HeaderContainer = styled('div', {
  gridArea: 'header',
  display: 'block',
  zIndex: 3,
  '@maxSm': {
    display: 'none',
  },
});

export const ContentContainer = styled('div', {
  gridArea: 'content',
  overflow: 'auto',
  zIndex: 1,
});
