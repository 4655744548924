import type { IconName, ToastProps, ToastVariant } from '@meterup/metric';
import { Toast } from '@meterup/metric';
import { AnimatePresence, motion } from 'framer-motion';
import createToast, { useToaster } from 'react-hot-toast';

export const notify = (
  heading: string,
  {
    variant = 'neutral',
    duration = 2000,
    icon,
  }: Omit<ToastProps, 'heading'> & { duration?: number } = {},
) => {
  createToast(heading, {
    icon,
    // KLUDGE: react-hot-toast doesn't provide a way to pass custom props, so we're overriding the
    // className prop to pass the variant.
    className: variant,
    duration,
  });
};

export const Notifications = () => {
  const { toasts, handlers } = useToaster();
  const { startPause, endPause } = handlers;

  const hasVisibleToasts = toasts.length > 0;
  return (
    <div
      onMouseEnter={startPause}
      onMouseLeave={endPause}
      style={{
        position: 'fixed',
        bottom: 0,
        margin: hasVisibleToasts ? 0 : 32,
        padding: hasVisibleToasts ? 32 : 0,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column-reverse',
        width: 'fit-content',
        left: '50%',
        transform: 'translateX(-50%)',
        gap: 8,
        zIndex: 2,
      }}
    >
      <AnimatePresence>
        {toasts
          .filter((toast) => toast.visible)
          .map((toast) => (
            <Toast
              as={motion.div}
              key={toast.id}
              {...toast.ariaProps}
              heading={toast.message}
              variant={toast.className as ToastVariant}
              icon={toast.icon as IconName}
              layout
              initial={{ y: 40 }}
              animate={{ y: 0, scale: 1, opacity: 1 }}
              exit={{ scale: 0.8, opacity: 0, transition: { duration: 0.2 } }}
              transition={{ type: 'spring', stiffness: 500, damping: 20 }}
            />
          ))}
      </AnimatePresence>
    </div>
  );
};
