import React from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { TabletQRCode } from 'tablet-qr-code';

import { fetchControllerSSIDs } from '../api/api';
import { useCurrentController } from '../providers/CurrentControllerProvider';

export const TabletJoinInstructions = () => {
  const { ssid: ssidParam } = useParams<{ ssid: string }>();

  const controller = useCurrentController();

  const networkInfo =
    useQuery(['controller_ssids', controller], () => fetchControllerSSIDs(controller), {
      suspense: true,
      refetchInterval: 1000 * 60 * 15,
    }).data ?? [];

  const queryClient = useQueryClient();

  const refreshSSID = () => {
    queryClient.invalidateQueries('controller_ssids');
  };

  const ssidData = networkInfo.find((d) => d.ssid === ssidParam);

  const password = ssidData?.password?.value ?? '';
  const ssid = ssidData?.ssid ?? '';

  return (
    <TabletQRCode
      type="WPA"
      ssid={ssid}
      password={password}
      hiddenSSID={false}
      onRefresh={refreshSSID}
    />
  );
};
