import { isDefined } from '@meterup/common';
import * as Sentry from '@sentry/react';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import posthog from 'posthog-js';
import { useEffect } from 'react';

import { useCurrentCompanyFromPathOrNull } from './hooks/useCurrentCompanyFromPath';
import { useIdentity } from './providers/IdentityDataProvider';

export const LaunchDarklyIdentifyEffect = () => {
  const LDClient = useLDClient();
  const identity = useIdentity();
  const currentCompany = useCurrentCompanyFromPathOrNull();

  useEffect(() => {
    if (isDefined(LDClient)) {
      if (isDefined(identity)) {
        LDClient.identify({
          kind: 'user',
          key: identity.id.toString(),
          email: identity.username,
          custom: {
            company_slugs: identity.company_memberships.map(
              (membership) => membership.company_slug,
            ),
            current_company: currentCompany?.company_slug,
          },
        });
      } else {
        LDClient.identify({
          anonymous: true,
        });
      }
    }
  }, [LDClient, currentCompany?.company_slug, identity]);

  return null;
};

export const SentryIdentifyEffect = () => {
  const identity = useIdentity();
  const currentCompany = useCurrentCompanyFromPathOrNull();

  useEffect(() => {
    if (isDefined(Sentry) && isDefined(identity)) {
      Sentry.setUser({
        id: identity.id.toString(),
        email: identity.username,
        email_domain: identity.username.split('@')[1],
        company_slugs: identity.company_memberships.map((membership) => membership.company_slug),
        current_company: currentCompany?.company_slug,
      });
    }
  }, [currentCompany?.company_slug, identity]);

  return null;
};

export const PostHogIdentifyEffect = () => {
  const identity = useIdentity();
  const currentCompany = useCurrentCompanyFromPathOrNull();

  useEffect(() => {
    if (isDefined(identity)) {
      posthog.identify(identity.id.toString(), {
        email: identity.username,
        email_domain: identity.username.split('@')[1],
        current_company: currentCompany?.company_slug,
      });
    }
  }, [currentCompany?.company_slug, identity]);

  return null;
};
