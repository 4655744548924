import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useDebounce } from 'use-debounce';

export const FormikAutoSave = <T extends object = {}>({
  onChange,
}: {
  onChange: (values: T) => void;
}) => {
  const formik = useFormikContext<T>();
  const [debouncedValues] = useDebounce(formik.values, 100);

  useEffect(() => {
    onChange(debouncedValues);
  }, [onChange, debouncedValues]);

  return null;
};
