import React from 'react';

import { styled } from '../../stitches.config';

const Container = styled('div', {
  hStack: '$6',
  height: '100%',
  alignItems: 'stretch',
});

export type Tabs2Props = {
  /**
   * Pass `Tab2` components into this for automatically laid out tabs.
   * */
  children: React.ReactNode;
};

export const Tabs2: React.FunctionComponent<Tabs2Props> = ({ children }) => (
  <Container role="tablist">{children}</Container>
);

export default Tabs2;
