import type { Simplify } from 'type-fest';
import { PSKRotationFrequency, PSKSchemeType } from '@meterup/config';
import { z } from 'zod';

import { validSSID } from './validSSID';
import { validWifiPSK } from './validWifiPSK';

const validServiceSetBase = z.object({
  ssid: validSSID,
  shouldBroadcastSSID: z.boolean(),
  is5GEnabled: z.boolean(),
  is2GEnabled: z.boolean(),
  vlan: z.string().min(1, 'VLAN is required'),
});

export const validServiceSetData = z.intersection(
  validServiceSetBase,
  // TRICKY: Switching on the PSK scheme type to only validate the PSK value
  // when using static PSK.
  z.union([
    z.object({
      pskSchemeType: z.literal(PSKSchemeType.None),
      pskValue: z.string().min(0),
      pskRotationFrequency: z.nativeEnum(PSKRotationFrequency),
    }),
    z.object({
      pskSchemeType: z.literal(PSKSchemeType.Static),
      pskValue: validWifiPSK,
      pskRotationFrequency: z.nativeEnum(PSKRotationFrequency),
    }),
    z.object({
      pskValue: z.string().min(0),
      pskSchemeType: z.literal(PSKSchemeType.Rotating),
      pskRotationFrequency: z.nativeEnum(PSKRotationFrequency),
    }),
  ]),
);

export type ValidServiceSetData = Simplify<z.infer<typeof validServiceSetData>>;
