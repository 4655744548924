import { checkDefinedOrThrow, expectDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';
import {
  Alert,
  Button,
  Drawer,
  DrawerContent,
  DrawerControls,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from '@meterup/metric';
import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { deleteRuleForCompany, getRuleForCompany } from '../../../../../api/api';
import { CloseDrawerButton } from '../../../../../components/CloseDrawerButton';
import { notify } from '../../../../../components/Notifications';
import { paths } from '../../../../../constants';
import { useCloseDrawerCallback } from '../../../../../hooks/useCloseDrawerCallback';
import { Nav } from '../../../../../nav';
import { useCurrentCompany } from '../../../../../providers/CurrentCompanyProvider';
import { formatRuleContentName } from '../../../../../utils/content_filters';

export const Meta = () => ({
  path: '/org/:companyName/controller/:controllerName/content-filters/rules/:id/remove',
});

export default function ContentFilterRuleRemovePage() {
  const companyName = useCurrentCompany();
  const { id } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.ContentFilterRuleRemovePage),
  );

  const rule =
    useQuery(['content_filters', companyName, id], () => getRuleForCompany(companyName, id), {
      suspense: true,
    }).data ?? null;

  expectDefinedOrThrow(rule, new ResourceNotFoundError('Rule not found'));

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const closeDrawer = useCloseDrawerCallback();

  const removeRuleMutation = useMutation(() => deleteRuleForCompany(companyName, id), {
    onSuccess: async () => {
      notify('Removed rule', { variant: 'positive' });
      closeDrawer();
      await queryClient.invalidateQueries(['content_filters', companyName]);
    },
    onError: () => {
      notify('Failed to remove rule', { variant: 'negative' });
    },
  });

  return (
    <Drawer>
      <DrawerHeader>
        <DrawerTitle>Remove rule</DrawerTitle>
        <DrawerControls>
          <CloseDrawerButton />
        </DrawerControls>
      </DrawerHeader>
      <DrawerContent>
        <Alert
          icon="attention"
          heading="Confirmation required"
          copy={`Are you sure you wish to remove the filter rule for "${formatRuleContentName(
            rule,
          )}"?`}
          variant="negative"
        />
      </DrawerContent>
      <DrawerFooter>
        <DrawerControls>
          <Button onClick={() => navigate(-1)} variant="secondary">
            Cancel
          </Button>
          <Button onClick={() => removeRuleMutation.mutate()} variant="destructive">
            Remove rule
          </Button>
        </DrawerControls>
      </DrawerFooter>
    </Drawer>
  );
}
