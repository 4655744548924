import type { ReactNode } from 'react';
import {
  colors,
  expectDefinedOrThrow,
  isDefinedAndNotEmpty,
  ResourceNotFoundError,
  styled,
} from '@meterup/common';
import {
  Alert,
  Badge,
  BasicSelect,
  BasicSelectItem,
  Body2,
  Button,
  DrawerContent,
  DrawerControls,
  DrawerFooter,
  HStack,
  PrimaryField,
  ShortcutKey,
  Small2,
  space,
  TextInput,
  VStack,
} from '@meterup/metric';
import { Form, Formik } from 'formik';
import { get } from 'lodash';
import QRCodeSVG from 'pretty-qrcode';
import { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toFormikValidationSchema } from 'zod-formik-adapter';

import type { FixMeLater } from '../../../../../types/fixMeLater';
import type { ValidVPNDeviceData, ValidVPNEmailData, VPNFormData } from './form_data';
import { fetchCompany, fetchControllerState } from '../../../../../api/api';
import { sendSMS } from '../../../../../api/messaging';
import { createVPNClient, fetchVPNClient } from '../../../../../api/vpn';
import DownloadIOSAppSVG from '../../../../../assets/images/vpn/download_ios';
import DownloadMacAppSVG from '../../../../../assets/images/vpn/download_mac';
import WireGuardLogo from '../../../../../assets/images/vpn/wireguard_logo';
import { CopyableCodeBlock } from '../../../../../components/CopyableCodeBlock';
import { FieldProvider } from '../../../../../components/FieldProvider';
import { FormikAutoSave } from '../../../../../components/FormikAutosave';
import { ReactRouterLink } from '../../../../../components/ReactRouterLink';
import { paths } from '../../../../../constants';
import { useCloseDrawerCallback } from '../../../../../hooks/useCloseDrawerCallback';
import { useCurrentCompany } from '../../../../../providers/CurrentCompanyProvider';
import { useCurrentController } from '../../../../../providers/CurrentControllerProvider';
import { fontWeights } from '../../../../../stitches';
import { makeDrawerLink } from '../../../../../utils/main_and_drawer_navigation';
import { createWGConf, generateKeyPair } from '../../../../../utils/vpn';
import {
  Device,
  devices,
  examples,
  toSMSRequest,
  toVPNClientCreateRequest,
  validVPNDeviceData,
  validVPNEmailData,
} from './form_data';

const StyledForm = styled(Form, {
  display: 'contents',
});

const StepTitle = styled(Body2, {
  fontWeight: fontWeights.medium,
  color: colors['gray-700'],
});

const Paragraph = styled('p', Body2, {
  color: colors['gray-700'],
});

const Strong = styled(Body2, {
  fontWeight: fontWeights.medium,
  color: colors['gray-700'],
});

const FormStepContent = styled(DrawerContent, {});

function updateOwner(
  prevForm: VPNFormData,
  ownerData: ValidVPNEmailData,
  onChange: (values: VPNFormData) => void,
) {
  const updatedForm = prevForm;
  updatedForm.owner = ownerData.email;
  onChange(updatedForm);
}

function updateDevice(
  prevForm: VPNFormData,
  deviceData: ValidVPNDeviceData,
  onChange: (values: VPNFormData) => void,
) {
  const updatedForm = prevForm;
  updatedForm.name = deviceData.name;
  updatedForm.device = deviceData.device;
  onChange(updatedForm);
}

function sendWireGuardSMS(device: Device.IOS | Device.Android, phone: string, countryCode: string) {
  const req = toSMSRequest(device, phone, countryCode);
  sendSMS(req.data);
}

const StepHeader = ({ stepNumber, title }: { stepNumber: ReactNode; title: ReactNode }) => (
  <HStack align="center" spacing={space(6)}>
    <ShortcutKey>{stepNumber}</ShortcutKey>
    <StepTitle>{title}</StepTitle>
  </HStack>
);

export const OwnerStepForm = ({
  formValues,
  defaultEmail,
  onChange,
  goToNextStep,
}: {
  formValues: VPNFormData;
  defaultEmail: string;
  onChange: (values: VPNFormData) => void;
  goToNextStep: () => void;
}) => (
  <Formik<ValidVPNEmailData>
    initialValues={{
      email: defaultEmail,
    }}
    validationSchema={toFormikValidationSchema(validVPNEmailData)}
    onSubmit={(values) => {
      updateOwner(formValues, values, onChange);
      goToNextStep();
    }}
  >
    <StyledForm>
      <FormStepContent>
        <VStack spacing={space(4)}>
          <StepHeader stepNumber="1" title="Owner" />
          <Small2 css={{ color: colors['gray-600'] }}>Who will be using these credentials?</Small2>
        </VStack>

        <FieldProvider name="email">
          <PrimaryField label="Email address" element={<TextInput />} />
        </FieldProvider>
      </FormStepContent>
      <DrawerFooter>
        <DrawerControls>
          <Button type="submit" variant="primary">
            Continue
          </Button>
        </DrawerControls>
      </DrawerFooter>
    </StyledForm>
  </Formik>
);

export const DeviceStepForm = ({
  formValues,
  defaultName,
  defaultDevice,
  onChange,
  goToNextStep,
  goToPreviousStep,
}: {
  formValues: VPNFormData;
  defaultName: string;
  defaultDevice: string;
  onChange: (values: VPNFormData) => void;
  goToNextStep: () => void;
  goToPreviousStep: () => void;
}) => {
  const controllerName = useCurrentController();
  const companyName = useCurrentCompany();
  return (
    <Formik<ValidVPNDeviceData>
      initialValues={{
        name: defaultName,
        device: defaultDevice,
      }}
      validationSchema={toFormikValidationSchema(validVPNDeviceData)}
      onSubmit={(values) => {
        updateDevice(formValues, values, onChange);
        goToNextStep();
      }}
    >
      <StyledForm>
        <FormikAutoSave<ValidVPNDeviceData>
          onChange={(values) => {
            updateDevice(formValues, values, onChange);
          }}
        />
        <FormStepContent>
          <StepHeader stepNumber="2" title="Details" />

          <FieldProvider name="device">
            <PrimaryField
              label="Platform"
              element={
                <BasicSelect defaultValue={Device.MacOS}>
                  {devices.map((c) => (
                    <BasicSelectItem key={c} value={c}>
                      {c} ({examples.get(c)})
                    </BasicSelectItem>
                  ))}
                </BasicSelect>
              }
            />
          </FieldProvider>
          <FieldProvider name="name">
            <PrimaryField label="Name" element={<TextInput />} />
          </FieldProvider>
        </FormStepContent>
        <DrawerFooter>
          <DrawerControls>
            <Button
              type="button"
              variant="secondary"
              as={ReactRouterLink}
              onClick={() => goToPreviousStep()}
              to={makeDrawerLink(window.location, paths.drawers.AddClientForm, {
                controllerName,
                companyName,
              })}
            >
              Back
            </Button>

            <Button type="submit" variant="primary">
              Continue
            </Button>
          </DrawerControls>
        </DrawerFooter>
      </StyledForm>
    </Formik>
  );
};

const DownloadCTAContainer = styled('div', { paddingY: '$12' });

export const DownloadStep = ({
  formValues,
  defaultPhone,
  onChange,
  goToNextStep,
  goToPreviousStep,
}: {
  formValues: VPNFormData;
  defaultPhone: string;
  onChange: (values: VPNFormData) => void;
  goToNextStep: () => void;
  goToPreviousStep: () => void;
}) => {
  const controllerName = useCurrentController();
  const companyName = useCurrentCompany();
  const queryClient = useQueryClient();
  const [keypair] = useState(generateKeyPair());
  const [phoneNumberInput, setPhoneNumberInput] = useState<string>('');

  const companyData = useQuery(['companies', companyName], () => fetchCompany(companyName)).data;

  const createClientMutation = useMutation(
    ['controllers', controllerName, 'vpn-clients'],
    () => {
      const req = toVPNClientCreateRequest(
        formValues.name ?? '',
        formValues.owner ?? '',
        keypair.publicKey,
      );
      return createVPNClient(controllerName, req.vpnClientData);
    },
    {
      onSuccess: (result) => {
        queryClient.invalidateQueries(['controllers', controllerName, 'vpn-clients']);
        const updatedForm = formValues;
        updatedForm.private_key = keypair.privateKey;
        updatedForm.public_key = keypair.publicKey;
        updatedForm.sid = result.client!.sid;
        onChange(updatedForm);
        goToNextStep();
      },
    },
  );

  return (
    <>
      <FormStepContent>
        <StepHeader stepNumber="3" title="Download" />

        <WireGuardLogo style={{ flexShrink: 0 }} />

        <Paragraph>
          <Strong>Meter</Strong> uses <Strong>WireGuard</Strong>'s app to create a secure connection
          between your device and <Strong>{companyData?.name ?? companyName}</Strong>'s network.
          Make sure you have the app installed on your device before continuing.
        </Paragraph>

        {formValues.device === Device.MacOS && (
          <DownloadCTAContainer>
            <a
              href="https://itunes.apple.com/us/app/wireguard/id1451685025"
              target="_blank"
              rel="noreferrer"
            >
              <DownloadMacAppSVG />
            </a>
          </DownloadCTAContainer>
        )}
        {formValues.device === Device.IOS && (
          <DownloadCTAContainer>
            <DownloadIOSAppSVG />
          </DownloadCTAContainer>
        )}
        {(formValues.device === Device.IOS || formValues.device === Device.Android) && (
          <PrimaryField
            label="Phone"
            element={
              <TextInput
                placeholder={defaultPhone ?? ''}
                onChange={(value) => setPhoneNumberInput(value)}
                suffix={
                  <Button
                    size="small"
                    variant="tertiary"
                    icon="phone"
                    onClick={() =>
                      sendWireGuardSMS(
                        formValues.device as FixMeLater,
                        phoneNumberInput,
                        'US', // TODO(angie): populate ISO country strings list
                      )
                    }
                  >
                    Send
                  </Button>
                }
              />
            }
            description={<div>Enter your phone number to receive a download link.</div>}
          />
        )}
        {formValues.device === Device.Other && (
          <DownloadCTAContainer>
            <Button
              as="a"
              href="https://www.wireguard.com/"
              rel="nofollow noreferrer noopener"
              target="_blank"
              arrangement="leading-icon"
              variant="secondary"
              icon="download"
              width="full"
              size="large"
            >
              Download WireGuard
            </Button>
          </DownloadCTAContainer>
        )}
      </FormStepContent>
      <DrawerFooter>
        <DrawerControls>
          <Button
            type="button"
            variant="secondary"
            as={ReactRouterLink}
            onClick={() => goToPreviousStep()}
            to={makeDrawerLink(window.location, paths.drawers.AddClientForm, {
              controllerName,
              companyName,
            })}
          >
            Back
          </Button>

          <Button
            type="submit"
            variant="primary"
            as={ReactRouterLink}
            onClick={() => createClientMutation.mutate()}
            to={makeDrawerLink(window.location, paths.drawers.AddClientForm, {
              controllerName,
              companyName,
            })}
          >
            Continue
          </Button>
        </DrawerControls>
      </DrawerFooter>
    </>
  );
};

export const SetupStep = ({
  formValues,
  goToNextStep,
}: {
  formValues: VPNFormData;
  goToNextStep: () => void;
}) => {
  const controllerName = useCurrentController();
  const companyName = useCurrentCompany();
  const vpnClientResponse = useQuery(
    ['controller', controllerName, 'vpn-clients', formValues.sid],
    async () => fetchVPNClient(controllerName, formValues.sid ?? ''),
    {
      suspense: true,
      refetchInterval: 1000,
    },
  ).data;

  expectDefinedOrThrow(vpnClientResponse, new ResourceNotFoundError('VPN client response missing'));

  const vpnClient = vpnClientResponse.client;
  const vpnServer = vpnClientResponse.server;

  expectDefinedOrThrow(vpnClient, new ResourceNotFoundError('VPN client response missing'));
  expectDefinedOrThrow(vpnServer, new ResourceNotFoundError('VPN client response missing'));

  const wgCfg = createWGConf(
    vpnClient!.ip_address,
    vpnServer!.endpoint,
    vpnServer!.port as FixMeLater,
    vpnServer!.public_key as FixMeLater,
    formValues.private_key ?? '',
  );

  return (
    <>
      <DrawerContent>
        <StepHeader stepNumber="4" title="Setup" />

        {formValues.device !== Device.IOS && formValues.device !== Device.Android && (
          <>
            <Paragraph>
              Now copy and paste the following configuration, making sure to overwrite any existing
              lines in the configuration field that were already in <Strong>WireGuard</Strong>. Then
              click "<Strong>Save</Strong>"
            </Paragraph>

            <Alert icon="information" heading="We do not store your private key" />

            <CopyableCodeBlock value={wgCfg} />
          </>
        )}
        {(formValues.device === Device.IOS || formValues.device === Device.Android) && (
          <>
            <Paragraph>Fantastic! Almost there.</Paragraph>
            <Paragraph>
              Open the <Strong>WireGuard</Strong> app on your phone and tap "
              <Strong>Add a tunnel</Strong>". When presented with options, tap "
              <Strong>Create from QR Code</Strong>" and scan the code below.
            </Paragraph>
            <QRCodeSVG
              style={{ alignSelf: 'center' }}
              value={wgCfg}
              foregroundColor={colors['brand-600'].toString()}
              size="80%"
            />
          </>
        )}
        <Paragraph css={{ color: colors['gray-500'] }}>
          You may be asked if you want to allow <Strong>WireGuard</Strong> to add VPN
          configurations. Make sure you click "<Strong>Allow</Strong>".
        </Paragraph>
      </DrawerContent>
      <DrawerFooter>
        <DrawerControls>
          <Button
            type="submit"
            variant="primary"
            as={ReactRouterLink}
            onClick={() => goToNextStep()}
            to={makeDrawerLink(window.location, paths.drawers.AddClientForm, {
              controllerName,
              companyName,
            })}
          >
            Next
          </Button>
        </DrawerControls>
      </DrawerFooter>
    </>
  );
};

export const ActivationStep = ({
  formValues,
  goToPreviousStep,
}: {
  formValues: VPNFormData;
  goToPreviousStep: () => void;
}) => {
  const controllerName = useCurrentController();
  const companyName = useCurrentCompany();
  const [loading, setLoading] = useState(true);
  const stateData = useQuery(
    ['controller', controllerName, 'state'],
    () => fetchControllerState(controllerName),
    { suspense: true, refetchInterval: !loading ? false : 1000 },
  ).data;

  const closeDrawer = useCloseDrawerCallback();

  expectDefinedOrThrow(stateData, new ResourceNotFoundError('State response not found'));
  expectDefinedOrThrow(stateData.state, new ResourceNotFoundError('State response not found'));
  expectDefinedOrThrow(formValues.public_key, new ResourceNotFoundError('Public key not defined'));

  const peers = get(stateData?.state, ['meter.v1.wg-vpn-client-manager.peers', 'peers']);
  const logs = get(peers, [formValues.public_key.toString()]);

  useEffect(() => {
    if (!loading) {
      return;
    }
    setTimeout(() => {
      setLoading(false);
    }, 20000);
  }, [loading]);

  return (
    <>
      <DrawerContent>
        <StepHeader stepNumber="5" title="Activate" />

        {formValues.device !== Device.IOS && formValues.device !== Device.Android && (
          <>
            <Paragraph>
              You now have successfully configured your VPN to work on your device.
            </Paragraph>
            <Paragraph>
              Lastly, make sure you click "Activate" in WireGuard (you might need to scroll a little
              to find it).
            </Paragraph>
          </>
        )}
        {(formValues.device === Device.IOS || formValues.device === Device.Android) && (
          <>
            <Paragraph>
              You now have successfully configured your VPN to work on your device.
            </Paragraph>
            <Paragraph>Lastly, tap the toggle next to your device's name to connect.</Paragraph>
          </>
        )}

        {isDefinedAndNotEmpty(logs) && (
          <VStack align="center">
            <Badge variant="positive" arrangement="leading-icon" icon="checkmark">
              Activated!
            </Badge>
          </VStack>
        )}

        {loading && !isDefinedAndNotEmpty(logs) && (
          <VStack align="center">
            <Badge>Checking activation...</Badge>
          </VStack>
        )}

        {!loading && !isDefinedAndNotEmpty(logs) && (
          <VStack align="center">
            <Badge variant="negative" arrangement="leading-icon" icon="warning">
              Activation failed
            </Badge>
          </VStack>
        )}
      </DrawerContent>
      <DrawerFooter>
        <DrawerControls>
          <Button
            variant="secondary"
            as={ReactRouterLink}
            onClick={() => goToPreviousStep()}
            to={makeDrawerLink(window.location, paths.drawers.AddClientForm, {
              controllerName,
              companyName,
            })}
          >
            Back
          </Button>
          <Button variant="primary" onClick={() => closeDrawer()}>
            Done
          </Button>
        </DrawerControls>
      </DrawerFooter>
    </>
  );
};
