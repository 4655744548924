import { checkDefinedOrThrow } from '@meterup/common';
import React from 'react';
import { useQuery } from 'react-query';

import { fetchDeviceClients } from '../../../../../api/api';
import { ClientsList } from '../../../../../components/ClientsList';
import { Page } from '../../../../../components/Page/Page';
import { paths } from '../../../../../constants';
import { Nav } from '../../../../../nav';
import { useCurrentController } from '../../../../../providers/CurrentControllerProvider';

export const Meta = () => ({
  path: '/org/:companyName/controller/:controllerName/devices/:deviceName',
});

export default function AccessPointDetailPage() {
  const { deviceName } = checkDefinedOrThrow(
    Nav.useRegionParams('root', paths.pages.AccessPointDetailPage),
  );

  const controller = useCurrentController();

  const clients =
    useQuery(
      ['device_clients', controller, deviceName],
      () => fetchDeviceClients(deviceName, controller),
      { suspense: true },
    ).data ?? [];

  return (
    <Page>
      <ClientsList clients={clients} showTabSwitcher={false} showShowWirelessInfoButton={false} />
    </Page>
  );
}
