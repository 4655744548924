import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import React from 'react';
import { Outlet } from 'react-router';

import { MainAndDrawerLayout } from '../../../components/MainAndDrawerLayout';
import { DrawerRoutes } from '../../../components/route_elements';

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/controller/:controllerName',
  name: 'NetworkLayout',
  layout: 'SidebarLayout',
});

export default function NetworkLayout() {
  return <MainAndDrawerLayout main={<Outlet />} drawer={<DrawerRoutes />} />;
}
