import type { MeterV1NetworkVLAN } from '@meterup/config';
import type { SortingState } from '@tanstack/react-table';
import { NeutralBadge, TaggedOrUntaggedBadge } from '@meterup/common';
import { EmptyState, SmallMono2, TextInput } from '@meterup/metric';
import { orderBy } from 'lodash';
import React, { useMemo } from 'react';

import { Page, PageControls, PageHeader, PageTitle } from '../../../../../components/Page/Page';
import { AutoTable2 } from '../../../../../components/Table/AutoTable2';
import { createColumnBuilder } from '../../../../../components/Table/createColumnBuilder';
import { paths } from '../../../../../constants';
import { useCloseDrawerCallback } from '../../../../../hooks/useCloseDrawerCallback';
import { useControllerConfig } from '../../../../../hooks/useControllerConfig';
import { Nav } from '../../../../../nav';
import { useCurrentCompany } from '../../../../../providers/CurrentCompanyProvider';
import { useCurrentController } from '../../../../../providers/CurrentControllerProvider';
import { useSearchParamsState } from '../../../../../providers/SearchParamsStateProvider';
import { makeDrawerLink } from '../../../../../utils/main_and_drawer_navigation';

const builder = createColumnBuilder<MeterV1NetworkVLAN>();

const columns = [
  builder.data((d) => d.name, {
    header: 'Name',
    meta: {
      isLeading: true,
    },
  }),
  builder.data((d) => d.vlanId.toString(), {
    header: 'ID',
    cell: (p) => <NeutralBadge ends="card">{p.value}</NeutralBadge>,
  }),
  builder.data((d) => d.json.dhcp?.gateway ?? '', {
    header: 'Gateway',
    meta: {
      isCopyable: true,
    },
    cell: (p) => <SmallMono2>{p.value}</SmallMono2>,
  }),
  builder.data((d) => (d.untagged ? 'Untagged' : 'Tagged'), {
    header: 'Tagging',
    cell: (p) => <TaggedOrUntaggedBadge isUntagged={p.row.untagged} />,
  }),
];

export const Meta = () => ({
  path: '/org/:companyName/controller/:controllerName/vlans',
  layout: 'NetworkLayout',
});

export default function VLANListPage() {
  const controllerName = useCurrentController();
  const companyName = useCurrentCompany();
  const model = useControllerConfig(controllerName);
  const onRowDeselect = useCloseDrawerCallback();

  const sortedVLANs = useMemo(
    () => orderBy(model.getCustomerFacingVLANs(), (d) => d.vlanId),
    [model],
  );

  const params = Nav.useRegionParams('drawer', paths.drawers.VLANSummaryPage);
  const [globalFilter, setGlobalFilter] = useSearchParamsState<string>('filter', '');
  const [sortingState, setSortingState] = useSearchParamsState<SortingState>('sort');

  return (
    <Page>
      <PageHeader>
        <PageTitle>VLANs</PageTitle>
        <PageControls>
          <div style={{ minWidth: 56 }}>
            <TextInput
              aria-label="Filter VLANs"
              icon="searchScoped"
              value={globalFilter}
              onChange={setGlobalFilter}
            />
          </div>
        </PageControls>
      </PageHeader>
      {sortedVLANs.length > 0 ? (
        <AutoTable2
          data={sortedVLANs}
          columns={columns}
          sortingState={sortingState}
          globalFilter={globalFilter}
          onChangeSortingState={setSortingState}
          onRowDeselect={onRowDeselect}
          isRowSelected={(d) => d.name === params?.vlanName}
          getLinkTo={(d) =>
            makeDrawerLink(window.location, paths.drawers.VLANSummaryPage, {
              vlanName: d.name,
              controllerName,
              companyName,
            })
          }
        />
      ) : (
        <EmptyState icon="vlan" heading="No VLANs" />
      )}
    </Page>
  );
}
