import { drawers } from './generated/drawers';
import { pages } from './generated/pages';

export const paths = {
  pages,
  drawers,
};

export const DURATION_24H_MS = 24 * 60 * 60 * 1000;
export const DURATION_7D_MS = DURATION_24H_MS * 7;
