import type { api } from '@meterup/proto';
import { expectDefinedOrThrow, isDefinedAndNotEmpty, ResourceNotFoundError } from '@meterup/common';
import { CopyCapsule, Subheading2, VStack } from '@meterup/metric';
import { get } from 'lodash';
import { useQuery } from 'react-query';

import { fetchControllerState, getUser } from '../api/api';
import { useCurrentCompany } from '../providers/CurrentCompanyProvider';
import { useCurrentController } from '../providers/CurrentControllerProvider';
import { UserDetailWidget } from './users';
import { VPNClientDetailWidget, VPNClientLogsWidget } from './vpn';

export default function VPNClientDetailContent({ vpnClient }: { vpnClient: api.VPNClient }) {
  const controller = useCurrentController();
  const company = useCurrentCompany();

  expectDefinedOrThrow(controller, new ResourceNotFoundError('Controller response not found'));

  const user = useQuery(
    ['company', company, 'users', vpnClient.user_sid],
    () => getUser(company, vpnClient.user_sid),
    { suspense: true },
  ).data;

  const stateData = useQuery(
    ['controller', controller, 'state'],
    () => fetchControllerState(controller),
    { suspense: true },
  ).data;

  expectDefinedOrThrow(user, new ResourceNotFoundError('User response not found'));
  expectDefinedOrThrow(stateData, new ResourceNotFoundError('State response not found'));
  expectDefinedOrThrow(stateData.state, new ResourceNotFoundError('State response not found'));

  const peers = get(stateData?.state, ['meter.v1.wg-vpn-client-manager.peers']);
  const peersObj = get(peers, ['peers']);
  const logs = get(peersObj, [vpnClient.public_key.toString()]);

  return (
    <>
      <VStack align="center" spacing={10 as any}>
        <Subheading2>
          <CopyCapsule
            textValue={vpnClient.name}
            aria-label="Copy VPN client name"
            arrangement="leading-icon"
          >
            {vpnClient.name}
          </CopyCapsule>
        </Subheading2>
      </VStack>
      <UserDetailWidget user={user} />
      <VPNClientDetailWidget vpnClient={vpnClient} />
      {isDefinedAndNotEmpty(logs) && <VPNClientLogsWidget logs={logs} peers={peers} />}
    </>
  );
}
