import React from 'react';

import { darkThemeSelector, shadows, styled } from '../stitches';

const Layout = styled('div', {
  height: '100%',
  width: '100%',
  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  '@maxSm': {
    flexDirection: 'column',
  },
});

const SidebarContainer = styled('div', {
  gridArea: 'sidebar',
  display: 'none',
  overflow: 'auto',
  webkitOverflowScrolling: 'touch',
  '@sm': {
    minWidth: 260,
    maxWidth: 260,
    flex: 1,
    display: 'block',
  },
});

const MobileMenuToggleContainer = styled('div', {
  gridArea: 'menu-toggle',
  boxShadow: shadows.fenceBottomLight,
  [darkThemeSelector]: {
    boxShadow: shadows.fenceBottomDark,
  },
  '@sm': {
    display: 'none',
  },
});

export interface SidebarMainDrawerLayoutProps {
  mobileSidebarToggle?: React.ReactNode;
  mobileSidebar?: React.ReactNode;
  desktopSidebar: React.ReactNode;
  main: React.ReactNode;
}

export const SidebarAndMainLayout = ({
  desktopSidebar,
  main,
  mobileSidebarToggle,
  mobileSidebar,
}: SidebarMainDrawerLayoutProps) => (
  <Layout>
    {mobileSidebarToggle && (
      <MobileMenuToggleContainer>{mobileSidebarToggle}</MobileMenuToggleContainer>
    )}
    {mobileSidebar}
    <SidebarContainer>{desktopSidebar}</SidebarContainer>
    {main}
  </Layout>
);
