import { expectDefinedOrThrow, ResourceNotFoundError } from '@meterup/common';

import { useControllerConfig } from './useControllerConfig';

export function useVLANConfigModel(controllerName: string, vlanName: string) {
  const configModel = useControllerConfig(controllerName);

  const vlan = configModel.getVLANByName(vlanName);

  expectDefinedOrThrow(vlan, new ResourceNotFoundError(`VLAN "${vlanName}" not found`));

  return vlan;
}
