import { action, makeObservable } from 'mobx';

import type { State } from '../state';
import type { BaseConfig } from './node';
import { Node } from './node';

export interface ActionConfig extends BaseConfig {
  /**
   * `onSelect` return `true` for an action to prevent
   * closing Command when an action is selected.
   */
  onSelect: () => (void | boolean) | Promise<void | boolean>;
}

export class Action extends Node<ActionConfig> {
  onSelect: ActionConfig['onSelect'];

  constructor(config: ActionConfig, readonly state: State) {
    super(config, state);

    // TODO: Pass instance as a param.
    this.onSelect = async () => {
      const response = await config.onSelect();
      if (response !== true) {
        state.ui.toggle(false);
      }
    };

    makeObservable(this, {
      onSelect: action,
    });
  }
}
