import { ListItem } from '@meterup/metric';
import QRCodeSVG from 'pretty-qrcode';
import React from 'react';

import { colors, styled } from '../../../stitches';

const checkerBackgroundImage = `url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 4 4' fill='none' opacity='0.02' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='2' height='2' fill='black'/%3E%3Crect x='2' width='2' height='2' fill='white'/%3E%3Crect y='2' width='2' height='2' fill='white'/%3E%3Crect x='2' y='2' width='2' height='2' fill='black'/%3E%3C/svg%3E")`;

const QRCodeListItemContainer = styled(ListItem, {
  alignItems: 'center',
  justifyContent: 'center',
  paddingY: '$16',
  backgroundColor: colors['gray-50'],
  backgroundImage: checkerBackgroundImage,
});

const QRCodeContainer = styled('div', {
  width: 120,
  borderRadius: '$8',
  padding: '$8',
  backgroundColor: colors.white,
});

export const QRCodeListItem = ({ text }: { text: string }) => (
  <QRCodeListItemContainer>
    <QRCodeContainer>
      <QRCodeSVG value={text} foregroundColor={colors['brand-600'].toString()} />
    </QRCodeContainer>
  </QRCodeListItemContainer>
);
