import {
  Alert,
  Button,
  Drawer,
  DrawerContent,
  DrawerControls,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  space,
  VStack,
} from '@meterup/metric';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { enableContentFilterForController } from '../../../../../api/api';
import { CloseDrawerButton } from '../../../../../components/CloseDrawerButton';
import { notify } from '../../../../../components/Notifications';
import { useCloseDrawerCallback } from '../../../../../hooks/useCloseDrawerCallback';
import { useCurrentCompany } from '../../../../../providers/CurrentCompanyProvider';
import { useCurrentController } from '../../../../../providers/CurrentControllerProvider';

export const Meta = () => ({
  path: '/org/:companyName/controller/:controllerName/content-filters/enable',
});

export default function ContentFilterEnablePage() {
  const companyName = useCurrentCompany();
  const controllerName = useCurrentController();
  const queryClient = useQueryClient();

  const closeDrawer = useCloseDrawerCallback();

  const enableFilteringMutation = useMutation(
    () => enableContentFilterForController(controllerName),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['content_filters', companyName]);
        closeDrawer();
        notify('Content filtering enabled', { variant: 'positive' });
      },
      onError: () => {
        notify('Failed to enable content filtering', { variant: 'negative' });
      },
    },
  );

  return (
    <Drawer>
      <DrawerHeader>
        <DrawerTitle>Enable content filtering</DrawerTitle>
        <DrawerControls>
          <CloseDrawerButton />
        </DrawerControls>
      </DrawerHeader>
      <DrawerContent>
        <Alert
          icon="information"
          variant="alternative"
          heading="Changing network settings"
          copy={
            <VStack spacing={space(8)}>
              <div>
                Enabling content filtering will update DNS settings and cause the network to
                restart. All clients will lose connectivity for approximately 30 seconds.
              </div>
              <div>Please confirm you would like to enable content filtering now.</div>
            </VStack>
          }
        />
      </DrawerContent>
      <DrawerFooter>
        <DrawerControls>
          <Button onClick={closeDrawer} variant="secondary">
            Cancel
          </Button>
          <Button onClick={() => enableFilteringMutation.mutate()}>Enable</Button>
        </DrawerControls>
      </DrawerFooter>
    </Drawer>
  );
}
