import { useQuery } from 'react-query';

import { fetchInstalledPrimaryControllers } from '../../api/api';
import { useCurrentCompany } from '../../providers/CurrentCompanyProvider';

export const useControllerList = () => {
  const company = useCurrentCompany();

  return (
    useQuery(['controllers_with_status', company], () => fetchInstalledPrimaryControllers(company))
      .data ?? []
  );
};
