import nacl from 'tweetnacl';

export const generateKeyPair = () => {
  const { publicKey, secretKey } = nacl.box.keyPair();
  return {
    publicKey: btoa(String.fromCharCode(...publicKey)),
    privateKey: btoa(String.fromCharCode(...secretKey)),
  };
};

export const createWGConf = (
  ip: string,
  endpoint: string,
  port: number,
  publicKey: string,
  privateKey: string,
) =>
  `
[Interface]
PrivateKey = ${privateKey}
Address = ${ip}/32
DNS = 10.103.0.2

[Peer]
PublicKey = ${publicKey}
AllowedIPs = 10.0.0.0/8, 224.0.0.0/4
Endpoint = ${endpoint}:${port}
PersistentKeepalive = 30`.trim();
