import { checkDefinedOrThrow, expectDefinedOrThrow, isDefined } from '@meterup/common';
import {
  Drawer,
  DrawerContent,
  DrawerControls,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTriggerButton,
} from '@meterup/metric';
import { Form, Formik } from 'formik';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import type { ValidStaticClient } from './form_data';
import { ClientHardwareWidget } from '../../../../../components/clients';
import { CloseDrawerButton } from '../../../../../components/CloseDrawerButton';
import { DiscardChangesButton, DrawerFormSaveButton } from '../../../../../components/form_buttons';
import { FormikConditional } from '../../../../../components/FormikConditional';
import { notify } from '../../../../../components/Notifications';
import { paths } from '../../../../../constants';
import { useControllerConfig } from '../../../../../hooks/useControllerConfig';
import { useVLANClientByMAC } from '../../../../../hooks/useVLANClients';
import { useVLANConfigModel } from '../../../../../hooks/useVLANConfigModel';
import { Nav } from '../../../../../nav';
import { useCurrentCompany } from '../../../../../providers/CurrentCompanyProvider';
import { useCurrentController } from '../../../../../providers/CurrentControllerProvider';
import { styled } from '../../../../../stitches';
import { makeDrawerLink } from '../../../../../utils/main_and_drawer_navigation';
import { withZodSchema } from '../../../../../utils/withZodSchema';
import { validStaticClient } from './form_data';
import { IPField, MACField, NameField } from './form_fields';
import { useUpsertStaticClientMutation } from './mutations';

const StyledForm = styled(Form, {
  display: 'contents',
});

export const Meta = () => ({
  path: '/org/:companyName/controller/:controllerName/vlans/:vlanName/static/:macAddress/edit',
});

export default function VLANStaticIPEditPage() {
  const { vlanName, macAddress } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.VLANStaticIPEditPage),
  );

  const controllerName = useCurrentController();
  const vlan = useVLANConfigModel(controllerName, vlanName);
  const staticClient = vlan.getStaticClientByMacAddress(macAddress);

  expectDefinedOrThrow(staticClient, new Error(`Static assignment for "${macAddress}" not found`));

  const companyName = useCurrentCompany();
  const configModel = useControllerConfig(controllerName);
  const client = useVLANClientByMAC(controllerName, vlanName, macAddress);
  const navigate = useNavigate();
  const mutation = useUpsertStaticClientMutation(controllerName, configModel, vlan);

  return (
    <Formik<ValidStaticClient>
      validate={withZodSchema(validStaticClient)}
      initialValues={{
        existingIPs: vlan.staticClients
          .filter((d) => d.macAddress !== macAddress)
          .map((d) => d.ipAddress),
        existingNames: vlan.staticClients
          .filter((d) => d.macAddress !== macAddress)
          .map((d) => d.name),
        subnet: vlan.subnetString,
        name: staticClient.name,
        mac: staticClient.macAddress,
        ip: staticClient.ipAddress,
      }}
      onSubmit={(values) => {
        mutation.mutate(values, {
          onSuccess: () => {
            notify('Saved', { variant: 'positive' });
          },
          onError: () => {
            notify('Failed to save changes', { variant: 'negative' });
          },
        });
      }}
      enableReinitialize
    >
      <StyledForm>
        <Drawer>
          <DrawerHeader>
            <DrawerTitle>Edit static IP</DrawerTitle>
            <DrawerControls>
              <DropdownMenu>
                <DropdownMenuTriggerButton variant="tertiary" icon="cog" arrangement="hidden-label">
                  Actions
                </DropdownMenuTriggerButton>

                <DropdownMenuContent>
                  <DropdownMenuItem
                    onSelect={() =>
                      navigate(
                        makeDrawerLink(window.location, paths.drawers.VLANStaticIPRemovePage, {
                          controllerName,
                          companyName,
                          vlanName,
                          macAddress,
                        }),
                      )
                    }
                  >
                    Remove
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
              <CloseDrawerButton />
            </DrawerControls>
          </DrawerHeader>
          <DrawerContent>
            {isDefined(client) && <ClientHardwareWidget client={client} shouldLinkToDetails />}
            <NameField />
            <MACField />
            <IPField vlan={vlan} />
          </DrawerContent>
          <FormikConditional<ValidStaticClient> condition={(values, ctx) => ctx.dirty}>
            <DrawerFooter>
              <DrawerControls>
                <DiscardChangesButton />
                <DrawerFormSaveButton />
              </DrawerControls>
            </DrawerFooter>
          </FormikConditional>
        </Drawer>
      </StyledForm>
    </Formik>
  );
}
