interface NetworkSpeedUnit {
  shortText: string;
  longText: string;
}

const sizes: NetworkSpeedUnit[] = [
  { shortText: 'bits/s', longText: 'bits per second' },
  { shortText: 'Kb/s', longText: 'kilobits per second' },
  { shortText: 'Mb/s', longText: 'megabits per second' },
  { shortText: 'Gb/s', longText: 'gigabits per second' },
  { shortText: 'Tb/s', longText: 'terabits per second' },
];

interface FormattedParts {
  scaledValue: number;
  formattedValue: string;
  size: NetworkSpeedUnit;
}

const formatBpsParts = (value: number, scale: number, decimals: number): FormattedParts | null => {
  if (value === 0) return { scaledValue: 0, formattedValue: '0', size: sizes[0] };
  if (value === -1 || Number.isNaN(value)) {
    return null;
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;

  const bps = value * scale;
  const i = Math.floor(Math.log(bps) / Math.log(k));

  const scaledValue = bps / k ** i;
  const formattedValue = scaledValue.toFixed(dm);
  const size = sizes[i];

  return { scaledValue, formattedValue, size };
};

// This same library exists in NOC, keep the two in sync!
const formatBps = (
  value: number,
  scale: number,
  decimals: number = 2,
  expandUnits: boolean = false,
) => {
  const parts = formatBpsParts(value, scale, decimals);
  return parts
    ? `${parts.formattedValue} ${expandUnits ? parts.size.longText : parts.size.shortText}`
    : 'n/a';
};

export const formatKbpsAsBps = (kbps: number, decimals: number = 2) =>
  formatBps(kbps, 1024, decimals);
