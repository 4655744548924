import type * as Polymorphic from '@radix-ui/react-polymorphic';
import React from 'react';

import type { IconName } from '../../assets/Icon/Icon';
import type {
  PolymorphicComponentProps,
  PolymorphicRef,
} from '../../utilities/types/polymorphicAsProp';
import { Icon } from '../../assets/Icon/Icon';
import { FocusRingSelf } from '../../common/focus_rings';
import { colors, darkThemeSelector, fontWeights, styled } from '../../stitches.config';
import { Body2 } from '../../text/Body';
import { useSidebarFormFactor } from './SidebarFormFactorContext';

const Container = styled('a', FocusRingSelf, {
  hStack: '$8',
  width: '100%',
  borderRadius: '$8',
  $$labelColor: colors['gray-600'],
  $$iconColor: colors['gray-500'],
  [darkThemeSelector]: {
    $$labelColor: colors['gray-50'],
    $$iconColor: colors['gray-100'],
  },
  '&:hover': {
    backgroundColor: colors['gray-50'],
    $$labelColor: colors['gray-700'],
    $$iconColor: colors['gray-600'],
    [darkThemeSelector]: {
      backgroundColor: colors['gray-700'],
      $$labelColor: colors.white,
      $$iconColor: colors['gray-50'],
    },
  },
  variants: {
    isSelected: {
      true: {
        '&, &:hover': {
          $$labelColor: colors['brand-800'],
          $$iconColor: colors['brand-700'],
          backgroundColor: colors['brand-50'],
        },
      },
      false: {},
    },
    size: {
      medium: {
        padding: '$4 $8',
      },
      large: {
        padding: '$8 $12',
      },
    },
  },
  defaultVariants: {
    size: 'medium',
  },
});

const StyledIcon = styled(Icon, {
  color: '$$iconColor',
});

const Label = styled(Body2, {
  fontWeight: fontWeights.medium,
  color: '$$labelColor',
  [darkThemeSelector]: {
    color: '$$labelColor',
  },
});

export interface SidebarNavItemProps {
  icon?: IconName;
  label?: React.ReactNode;
  isSelected?: boolean;
  size?: 'medium' | 'large';
}

export const SidebarNavItem = React.forwardRef(
  <Tag extends React.ElementType>(
    {
      as = 'a' as Tag,
      size,
      label,
      icon,
      isSelected,
      ...props
    }: PolymorphicComponentProps<Tag, SidebarNavItemProps>,
    forwardedRef: PolymorphicRef<Tag>,
  ) => {
    const formFactor = useSidebarFormFactor();
    const defaultedSize = size ?? (formFactor === 'mobile' ? 'large' : 'medium');
    return (
      <Container as={as} ref={forwardedRef} {...props} isSelected={isSelected} size={defaultedSize}>
        {icon && <StyledIcon icon={icon} />}
        <Label>{label}</Label>
      </Container>
    );
  },
) as Polymorphic.ForwardRefComponent<React.ElementType, SidebarNavItemProps>;
