import type { MeterV1NetworkVLAN } from '@meterup/config';
import { PrimaryField, TextInput } from '@meterup/metric';
import { useFormikContext } from 'formik';
import React from 'react';

import type { ValidStaticClient } from './form_data';
import { CopyToClipboardButtonMinimal } from '../../../../../components/CopyToClipboardButton';
import { FieldProvider } from '../../../../../components/FieldProvider';

const NamePreview = () => {
  const form = useFormikContext<ValidStaticClient>();
  return <span>{`${form.values.name ? form.values.name : '<name>'}.local.meter`}</span>;
};

export const NameField = () => {
  const form = useFormikContext<ValidStaticClient>();
  return (
    <FieldProvider name="name">
      <PrimaryField
        label="Name"
        element={<TextInput />}
        controls={<CopyToClipboardButtonMinimal text={`${form.values.name}.local.meter`} />}
        description={<NamePreview />}
      />
    </FieldProvider>
  );
};

export const MACField = () => (
  <FieldProvider name="mac">
    <PrimaryField label="MAC address" element={<TextInput />} />
  </FieldProvider>
);

export const IPField = ({ vlan }: { vlan: MeterV1NetworkVLAN }) => (
  <FieldProvider name="ip">
    <PrimaryField
      label="IP assignment"
      element={<TextInput />}
      description={
        vlan.usableRange ? (
          <div>
            Must be in the range {vlan.usableRange.start.address} to {vlan.usableRange.end.address}
          </div>
        ) : undefined
      }
    />
  </FieldProvider>
);
