import React from 'react';

import { Background, Glyph } from '../common';

export const ProductIconVPN = () => (
  <>
    <Background
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16 8C16 10.7289 15.814 13.8076 14.8139 14.8105C13.8139 15.8134 10.2326 16 8.02326 16C5.81394 16 2.23257 15.8134 1.23256 14.8105C0.232559 13.8076 0 10.7289 0 8C0 5.27115 0.232557 2.19243 1.23256 1.1895C2.23257 0.186588 5.81394 0 8.02326 0C10.2326 0 13.814 0.186588 14.814 1.1895C15.814 2.19243 16 5.27115 16 8Z"
    />
    <Glyph
      opacity="0.2"
      d="M8 12.8744C8.0643 12.8744 8.1286 12.86 8.18789 12.8312C10.2711 11.819 11.5552 10.3332 11.8742 8.52985C12.1192 7.14427 12.0614 5.72348 11.9789 4.83344C11.9633 4.66489 11.8514 4.5216 11.6931 4.46181L8.26491 3.16744C8.17955 3.13521 8.08978 3.1191 8 3.11909V12.8744Z"
    />
    <Glyph
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.01326 4.37261C2.91299 5.24537 2.78672 6.98707 3.09191 8.71271C3.50411 11.0434 5.20935 12.8177 7.67088 13.924C7.77601 13.9713 7.88777 13.9966 8.00002 14C8.11228 13.9966 8.22404 13.9713 8.32916 13.924C10.7907 12.8177 12.4959 11.0434 12.9081 8.71271C13.2133 6.98707 13.0871 5.24537 12.9868 4.37261C12.9457 4.01513 12.7083 3.72275 12.3833 3.60006L8.30496 2.0602C8.20633 2.02296 8.10334 2.00289 8.00002 2C7.89671 2.00289 7.79372 2.02296 7.69509 2.0602L3.61675 3.60006C3.29178 3.72275 3.05432 4.01513 3.01326 4.37261ZM8.35326 3.20078C8.12561 3.11483 7.87444 3.11483 7.64679 3.20078L4.39299 4.42932C4.18184 4.50905 4.03242 4.70023 4.01285 4.92508C3.93508 5.81907 3.88906 7.1909 4.12586 8.52985C4.44161 10.3152 5.70325 11.7892 7.74976 12.8006C7.90736 12.8785 8.09268 12.8785 8.25028 12.8006C10.2968 11.7892 11.5584 10.3152 11.8742 8.52985C12.111 7.19089 12.065 5.81907 11.9872 4.92508C11.9676 4.70023 11.8182 4.50905 11.6071 4.42932L8.35326 3.20078Z"
    />
  </>
);

export default ProductIconVPN;
