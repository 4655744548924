export default {
  data: {
    controller_name: 'mc0500051t012206',
    created_at: '2022-12-07T18:38:34.990530Z',
    message_sid: '8262fd98-e6d9-46d4-acde-38f92bea365e',
    request:
      '{"action": "network_info", "module": "wireless", "params": {"time": "2022-12-07T18:38:34.987298173Z"}}',
    response: {
      version: 1,
      status: 'succeeded',
      error_id: '',
      error_text: '',
      data_id: '',
      data: '{"private_ssid":"Meter","private_2g_ssid":"Meter-2G","guest_ssid":"Meter-Guest","private_password":"breakfast-tacos","guest_password":"pinto-beans","guest_strategy":"UNKNOWN","private_auth_method":"psk2","config_json":{"service-sets":{"Meter":{"authentication":"PSK2","bands":["5 GHz"],"enable-80211-k-v":true,"network":"private","psk":"breakfast-tacos"},"Meter-2G":{"authentication":"PSK2","bands":["2.4 GHz"],"network":"private","psk":"tomatillo-salsa"},"Meter-Guest":{"authentication":"PSK2","bands":["2.4 GHz","5 GHz"],"isolate":true,"network":"guest","psk":"hatch-green-chile"}}}}',
    },
    finished_at: '2022-12-07T18:38:34.990530Z',
    links: {
      self: '/v1/controllers/mc0500051t012206/messages/8262fd98-e6d9-46d4-acde-38f92bea365e',
    },
  },
};
