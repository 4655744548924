import type { SortingState } from '@tanstack/react-table';
import { observer, Priority, useCommand, useRegisterCommands } from '@meterup/command';
import { isDefined, PanAndZoomRegion, preloadImage, shadows } from '@meterup/common';
import { Badge, Button, EmptyState, TextInput } from '@meterup/metric';
import { styled } from '@meterup/metric/src/stitches.config';
import { api } from '@meterup/proto';
import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router';

import type { DeviceDataAndRadios } from '../../api/types';
import { fetchDevicesWithRadioData, fetchFloorPlan } from '../../api/api';
import { paths } from '../../constants';
import { useCloseDrawerCallback } from '../../hooks/useCloseDrawerCallback';
import { Nav } from '../../nav';
import { useCurrentCompany } from '../../providers/CurrentCompanyProvider';
import { useCurrentController } from '../../providers/CurrentControllerProvider';
import { useSearchParamsState } from '../../providers/SearchParamsStateProvider';
import { colors } from '../../stitches';
import {
  AccessPointHeadingCell,
  formatAPRadiosForBand,
  getAPNameForDevice,
  isOffline,
  isOnline,
  RadioNumbersOrDisabled,
} from '../../utils/access_point_utils';
import { makeDrawerLink, makeLink } from '../../utils/main_and_drawer_navigation';
import { Box } from '../Box';
import { Page, PageControls, PageHeader, PageSection } from '../Page/Page';
import { ShowWirelessInfoButton } from '../ShowWirelessInfoButton';
import { AutoTable2 } from '../Table/AutoTable2';
import { createColumnBuilder } from '../Table/createColumnBuilder';
import { createFilterEnumeration } from '../Table/createFilterEnumeration';

const PanningContainer = styled('div', {
  position: 'relative',
  width: '100%',
  height: '100%',
  boxShadow: shadows.fenceAllLight,
  padding: 0.5,
});

const ViewMode = createFilterEnumeration<DeviceDataAndRadios>(
  [
    {
      key: 'list',
      label: 'List',
      icon: 'menu',
      predicate: () => true,
    },
    {
      key: 'floor-plan',
      label: 'Floor plan',
      icon: 'floorplan',
      predicate: () => true,
    },
  ],
  {
    urlKey: 'view',
  },
);

const DeviceFilters = createFilterEnumeration<DeviceDataAndRadios>(
  [
    {
      key: 'all',
      label: 'All',
      predicate: () => true,
    },
    {
      key: 'online',
      label: 'Online',
      predicate: isOnline,
    },
    {
      key: 'offline',
      label: 'Offline',
      predicate: isOffline,
    },
  ],
  {
    urlKey: 'tab',
  },
);

const builder = createColumnBuilder<DeviceDataAndRadios>();

const columns = [
  builder.data((d) => d.device.status, {
    header: '',
    minSize: 40,
    maxSize: 40,
    meta: {
      sizingMode: 'fit-min',
      alignment: 'end',
    },
    cell: (p) => (
      <Badge
        arrangement="hidden-label"
        variant={isOnline(p.row) ? 'positive' : 'neutral'}
        icon={isOnline(p.row) ? 'checkmarkCircle' : 'crossCircle'}
        size="small"
        ends="pill"
      >
        {isOnline(p.row) ? 'Online' : 'Offline'}
      </Badge>
    ),
  }),
  builder.data((d) => getAPNameForDevice(d.device), {
    header: 'Name',
    meta: {
      isLeading: true,
    },
    cell: (p) => <AccessPointHeadingCell name={getAPNameForDevice(p.row.device)} />,
  }),
  builder.data((d) => d.device.clients.toFixed(0), {
    header: 'Clients',
  }),
  builder.data((d) => formatAPRadiosForBand(d.apAndRadios, api.RadioBand.RB_5G) ?? '', {
    header: '5 GHz',
    cell: (p) => <RadioNumbersOrDisabled value={p.row.apAndRadios} band={api.RadioBand.RB_5G} />,
  }),
  builder.data((d) => formatAPRadiosForBand(d.apAndRadios, api.RadioBand.RB_2G) ?? '', {
    header: '2.4 GHz',
    cell: (p) => <RadioNumbersOrDisabled value={p.row.apAndRadios} band={api.RadioBand.RB_2G} />,
  }),
];

const EmptyStates = ({
  apCount,
  filteredAPCount,
  onResetFilters,
}: {
  apCount: number;
  filteredAPCount: number;
  onResetFilters: () => void;
}) => {
  if (apCount === 0) {
    return (
      <EmptyState icon="device" heading="There are no access points associated with this network" />
    );
  }

  if (filteredAPCount === 0) {
    return (
      <EmptyState
        icon="filter"
        heading="Your filter returned no results"
        action={
          <Button
            variant="tertiary"
            icon="minusCircle"
            arrangement="leading-icon"
            onClick={onResetFilters}
          >
            Reset filters
          </Button>
        }
      />
    );
  }

  return null;
};

const AccessPoints = observer(() => {
  const companyName = useCurrentCompany();
  const controllerName = useCurrentController();
  const closeDrawer = useCloseDrawerCallback();
  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.AccessPointSummary);

  const devices =
    useQuery(
      ['devices_and_radios', controllerName],
      () => fetchDevicesWithRadioData(controllerName),
      {
        suspense: true,
      },
    ).data ?? [];

  const floorPlanURL = useQuery(['floor_plan', controllerName], async () => {
    const url = await fetchFloorPlan(controllerName);
    return url ? preloadImage(url) : null;
  }).data;

  const filteredDevices = DeviceFilters.useCurrentPredicate(devices);
  const resetFilters = DeviceFilters.useResetCallback();

  const [globalFilter, setGlobalFilter] = useSearchParamsState<string>('filter', '');
  const [sortingState, setSortingState] = useSearchParamsState<SortingState>('sort');

  const currentView = ViewMode.useCurrentFilterKey();

  const navigate = useNavigate();
  const { state } = useCommand();

  const nodes = useMemo(
    () =>
      state.ui.search.length > 0
        ? filteredDevices.map((device, index) =>
            state.nodeFactory.directory({
              id: `${device.device.name}_${index}`,
              display: device.device.physical_location,
              label: device.device.physical_location,
              group: `Search for "${state.ui.search}"`,
              children: [],
              priority: Priority.High,
              onEnter(dir) {
                const viewAccessPoint = dir.state.nodeFactory.action({
                  id: 'view-access-point',
                  display: 'View access point',
                  label: 'View access point',
                  group: device.device.physical_location,
                  onSelect() {
                    navigate(
                      makeLink(paths.pages.AccessPointDetailPage, {
                        deviceName: device.device.name,
                        controllerName,
                        companyName,
                      }),
                    );
                  },
                });

                const viewWirelessInfo = dir.state.nodeFactory.action({
                  id: 'view-viewless-info',
                  display: 'View wireless info',
                  label: 'View wireless info',
                  group: device.device.physical_location,
                  onSelect() {
                    navigate(
                      makeDrawerLink(window.location, paths.drawers.AddClientPage, {
                        companyName,
                        controllerName,
                      }),
                    );
                  },
                });

                const n = [viewAccessPoint, viewWirelessInfo];
                dir.addChildren(n);
                return () => {
                  dir.removeChildren(n);
                };
              },
            }),
          )
        : [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filteredDevices, state.ui.search],
  );

  useRegisterCommands(nodes, [state.ui.search]);

  const listView =
    filteredDevices.length > 0 ? (
      <AutoTable2
        columns={columns}
        data={filteredDevices}
        sortingState={sortingState}
        onChangeSortingState={setSortingState}
        globalFilter={globalFilter}
        getLinkTo={(row) =>
          row.device.physical_location !== 'Wired'
            ? makeDrawerLink(window.location, paths.drawers.AccessPointSummary, {
                deviceName: row.device.name,
                controllerName,
                companyName,
              })
            : null
        }
        onRowDeselect={closeDrawer}
        isRowSelected={(d) => d.device.name === drawerParams?.deviceName}
      />
    ) : (
      <EmptyStates
        apCount={devices.length}
        filteredAPCount={filteredDevices.length}
        onResetFilters={resetFilters}
      />
    );

  const floorPlanView = (
    <PanningContainer>
      <PanAndZoomRegion>
        {isDefined(floorPlanURL) && (
          <img
            src={floorPlanURL}
            alt="Floor plan"
            style={{ maxHeight: '50vh', userSelect: 'none' }}
          />
        )}
      </PanAndZoomRegion>
    </PanningContainer>
  );

  return (
    <Page>
      <PageHeader>
        {floorPlanURL && (
          <>
            <ViewMode.TabSwitcher />
            <Box
              css={{
                width: '2px',
                height: '16px',
                background: colors['gray-100'],
                borderRadius: '8px',
              }}
            />
          </>
        )}
        <DeviceFilters.TabSwitcher data={devices} />
        <PageControls>
          <div style={{ width: 'fit-content' }}>
            <ShowWirelessInfoButton />
          </div>
          <div style={{ minWidth: 56 }}>
            <TextInput
              aria-label="Filter clients"
              icon="searchScoped"
              value={globalFilter}
              onChange={setGlobalFilter}
            />
          </div>
        </PageControls>
      </PageHeader>
      <PageSection>{currentView === 'list' ? listView : floorPlanView}</PageSection>
    </Page>
  );
});

export default AccessPoints;
