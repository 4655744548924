import { isDefined, isIPWithinRange, safeParseAddress4 } from '@meterup/common';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

import type { ClientData } from '../api/types';
import { fetchClients } from '../api/api';
import { useControllerConfigOrNull } from './useControllerConfig';
import { useVLANConfigModel } from './useVLANConfigModel';

function useClientInVLANPredicate(controllerName: string, vlanName: string) {
  const vlan = useVLANConfigModel(controllerName, vlanName);

  return useMemo(() => {
    const range = vlan.dhcpRange;

    return (client: ClientData) => {
      const clientIP = safeParseAddress4(client.ip_address);

      return isDefined(clientIP) && isDefined(range) ? isIPWithinRange(clientIP, range) : false;
    };
  }, [vlan.dhcpRange]);
}

export function useVLANSFromClientMatcher(controllerName: string) {
  const config = useControllerConfigOrNull(controllerName);

  return useMemo(() => {
    if (isDefined(config)) {
      return (client: ClientData) => {
        const clientIP = safeParseAddress4(client.ip_address);

        return isDefined(clientIP)
          ? config.vlans.filter((vlan) =>
              isDefined(vlan.subnet) ? clientIP.isInSubnet(vlan.subnet) : false,
            )
          : [];
      };
    }

    return () => [];
  }, [config]);
}

export const useVLANClients = (controllerName: string, vlanName: string) => {
  const predicate = useClientInVLANPredicate(controllerName, vlanName);

  const clients = useQuery(['clients', controllerName], () => fetchClients(controllerName), {
    suspense: true,
  }).data;

  return useMemo(() => (clients ?? []).filter(predicate), [predicate, clients]);
};

export const useVLANClientByMAC = (controllerName: string, vlanName: string, macAddress: string) =>
  useVLANClients(controllerName, vlanName).find((c) => c.mac_address === macAddress);
