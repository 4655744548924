import { useContext } from 'react';

import {
  useCurrentCompanyFromPath,
  useCurrentCompanyFromPathOrNull,
} from '../hooks/useCurrentCompanyFromPath';
import { DefaultCompanyContext } from './DefaultCompanyProvider';

export const useCurrentCompany = () => {
  const currentCompany = useCurrentCompanyFromPath();

  return currentCompany?.company_slug;
};

export const useDefaultCompany = () => {
  const defaultCompany = useContext(DefaultCompanyContext);

  return defaultCompany.defaultCompany;
};

export const useCurrentCompanyOrDefault = () => {
  const defaultCompany = useContext(DefaultCompanyContext);
  const currentCompany = useCurrentCompanyFromPathOrNull();

  return currentCompany?.company_slug ?? defaultCompany.defaultCompany;
};
