import { isDefined } from '@meterup/common';
import { api } from '@meterup/proto';
import { first } from 'lodash';
import React, { createContext, useContext, useMemo } from 'react';

import { useControllersOnCurrentCompany } from '../hooks/useControllersOnCurrentCompany';
import { useLocalStorage } from '../hooks/useLocalStorage';

export const DefaultControllerContext = createContext<{
  defaultControllerSlug: string | null;
  setDefaultControllerSlug: (slug: string | null) => void;
}>(null as any);

const getInstalledPrimaryControllers = (controllers: api.ControllerResponse[]) =>
  controllers.filter(
    ({ lifecycle_status }) =>
      lifecycle_status === api.LifecycleStatus.LIFECYCLE_STATUS_INSTALLED_PRIMARY,
  );

const pickControllerOrUseFirstAvailable = (
  controllers: api.ControllerResponse[],
  preferredController: string | null,
) => controllers.find((d) => d.name === preferredController) ?? first(controllers) ?? null;

const pickDefaultController = (
  controllers: api.ControllerResponse[],
  preferredController: string | null,
) =>
  pickControllerOrUseFirstAvailable(
    getInstalledPrimaryControllers(controllers),
    preferredController,
  ) ??
  pickControllerOrUseFirstAvailable(controllers, preferredController) ??
  null;

export const DefaultCurrentControllerProvider: React.FC = ({ children }) => {
  const [defaultControllerSlug, setDefaultControllerSlug] = useLocalStorage<string | null>(
    'currentController',
    null,
  );

  const controllers = useControllersOnCurrentCompany();

  const firstPermittedDefaultController = pickDefaultController(
    controllers ?? [],
    defaultControllerSlug,
  );

  const value = useMemo(
    () => ({
      defaultControllerSlug: firstPermittedDefaultController?.name ?? null,
      setDefaultControllerSlug,
    }),
    [firstPermittedDefaultController, setDefaultControllerSlug],
  );

  return (
    <DefaultControllerContext.Provider value={value}>{children}</DefaultControllerContext.Provider>
  );
};

export const useDefaultController = () => {
  const val = useContext(DefaultControllerContext);

  if (!isDefined(val)) {
    throw new Error('useDefaultController must be used within a DefaultControllerContext');
  }

  return val.defaultControllerSlug;
};
