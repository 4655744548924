import { get } from 'lodash';
import { DateTime } from 'luxon';

import type { VPNClientWithUserLogs } from '../api/types';
import { timestampToISO } from './timestamp';

export const isActive = (c: VPNClientWithUserLogs): boolean => {
  const lastHandshake = get(c.logEntry, 'last_handshake_time');
  if (lastHandshake) {
    const handshakeTime = DateTime.fromISO(timestampToISO(lastHandshake));
    const fiveMinsAgo = DateTime.now();
    fiveMinsAgo.minus({ minutes: 3 });
    return fiveMinsAgo.diff(handshakeTime, 'minutes').minutes <= 5;
  }

  return false;
};
