import { Button } from '@meterup/metric';
import React from 'react';

import { paths } from '../constants';
import { useCurrentCompany } from '../providers/CurrentCompanyProvider';
import { useCurrentController } from '../providers/CurrentControllerProvider';
import { makeDrawerLink } from '../utils/main_and_drawer_navigation';
import { ReactRouterLink } from './ReactRouterLink';

export const ShowWirelessInfoButton = () => {
  const companyName = useCurrentCompany();
  const controllerName = useCurrentController();
  return (
    <Button
      as={ReactRouterLink}
      to={makeDrawerLink(window.location, paths.drawers.AddClientPage, {
        companyName,
        controllerName,
      })}
      variant="tertiary"
      icon="information"
      arrangement="leading-icon"
    >
      Show wireless info
    </Button>
  );
};
