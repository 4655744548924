import type { IconName } from '@meterup/metric';
import { Priority, useCommand, useRegisterCommands } from '@meterup/command';
import { Tab2 } from '@meterup/metric';
import Tabs2 from '@meterup/metric/src/components/Tabs/Tabs2';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useSearchParamsState } from '../../providers/SearchParamsStateProvider';
import { ReactRouterLink } from '../ReactRouterLink';

const ALL_FILTER = () => true;

export interface FilterDef<Data extends object, Key extends string = string> {
  key: Key;
  label: React.ReactNode;
  icon?: IconName;
  predicate?: (row: Data) => boolean;
}

export function createFilterEnumeration<Data extends object, Key extends string = string>(
  filters: FilterDef<Data, Key>[],
  {
    defaultKey = filters[0]?.key,
    urlKey = 'filter',
  }: {
    defaultKey?: Key;
    urlKey?: string;
  } = {},
) {
  const useCurrentFilterKey = () => {
    const [currentFilter] = useSearchParamsState<string>(urlKey, defaultKey);
    return currentFilter ?? defaultKey;
  };

  const useCurrentFilter = () => {
    const filterKey = useCurrentFilterKey();
    return filters.find((f) => f.key === filterKey);
  };

  const useCurrentPredicate = (data: Data[]) => {
    const filter = useCurrentFilter();
    return filter ? data.filter(filter.predicate ?? ALL_FILTER) : data;
  };

  const useResetCallback = () => {
    const [, , nextPath] = useSearchParamsState<string>(urlKey, defaultKey);
    const navigate = useNavigate();
    return useCallback(() => {
      navigate(nextPath(defaultKey), { replace: true });
    }, [navigate, nextPath]);
  };

  const TabSwitcher = ({ data }: { data?: Data[] }) => {
    const currentKey = useCurrentFilterKey();
    const [, , nextPath] = useSearchParamsState<string>(urlKey, defaultKey);

    const navigate = useNavigate();

    const { state } = useCommand();

    useRegisterCommands([
      state.nodeFactory.directory({
        id: `filter-by-${urlKey}`,
        display: `Filter by ${urlKey}…`,
        label: `Filter by ${urlKey}…`,
        priority: Priority.High,
        icon: 'filter',
        children: filters.map((filter) =>
          state.nodeFactory.action({
            id: filter.key,
            label: filter.label?.toString() || filter.key,
            display: filter.label,
            priority: Priority.High,
            onSelect() {
              navigate(nextPath(filter.key));
            },
          }),
        ),
      }),
    ]);

    return (
      <Tabs2>
        {filters.map((filter) => (
          <Tab2
            key={filter.key}
            as={ReactRouterLink}
            to={nextPath(filter.key)}
            active={currentKey === filter.key}
            annotation={data?.filter(filter.predicate ?? ALL_FILTER).length}
            icon={filter.icon}
            replace
          >
            {filter.label}
          </Tab2>
        ))}
      </Tabs2>
    );
  };

  return {
    TabSwitcher,
    useCurrentFilterKey,
    useCurrentFilter,
    useCurrentPredicate,
    useResetCallback,
  };
}
