export const joinPath = (path: string[], separator = '/') =>
  path.reduce((acc, segment) => {
    if (acc === '') {
      return segment;
    }
    if (segment === '') {
      return acc;
    }
    if (acc.endsWith('/') && segment.startsWith('/')) {
      return `${acc}${segment.slice(1)}`;
    }
    if (acc.endsWith('/') || segment.startsWith('/')) {
      return `${acc}${segment}`;
    }
    return `${acc}${separator}${segment}`;
  }, '/');
