/* eslint-disable max-classes-per-file */
import type { DisplayableError } from './isDisplayableError';
import type { WrappedError } from './isWrappedError';

export class ResourceNotFoundError extends Error implements DisplayableError {
  // This used to say "Resource not found", but we display this and "Resource" is usually
  // not what we want to say.
  readonly displayTitle = 'Not found';

  constructor(readonly displayMessage: string) {
    super(displayMessage);
    this.name = 'ResourceNotFoundError';
  }
}

export class UnexpectedError extends Error implements DisplayableError, WrappedError {
  readonly displayTitle = 'Unexpected error';

  constructor(readonly displayMessage: string, readonly originalError?: unknown) {
    super(displayMessage);
    this.name = 'UnexpectedError';
  }
}

export class ApplicationError extends Error implements DisplayableError, WrappedError {
  readonly displayTitle = 'Application error';

  constructor(readonly displayMessage: string, readonly originalError?: unknown) {
    super(displayMessage);
    this.name = 'UnexpectedError';
  }
}
export class ControllerRequestFailedError extends Error implements DisplayableError, WrappedError {
  readonly displayTitle = 'Controller request failed';

  readonly displayMessage = `The controller request ${this.requestName} for controller ${this.controllerName} failed. The controller may be offline.`;

  constructor(
    readonly controllerName: string,
    readonly requestName: string,
    readonly originalError?: unknown,
  ) {
    super('Controller request failed');
    this.name = 'ControllerRequestFailedError';
  }
}

export class NoConnectionError extends Error implements DisplayableError, WrappedError {
  readonly displayTitle = 'No connection to server';

  readonly displayMessage = 'Request was sent but no response was received from server';

  constructor(readonly originalError?: unknown) {
    super('Request was sent but no response was received from server');
    this.name = 'NoConnectionError';
  }
}
