import type { SingleSelection } from '@react-types/shared/src/selection';
import type { Key } from 'react';

type ValueProps = {
  value?: Key | null;
  defaultValue?: Key;
  onValueChange?: (key: Key) => any;
};

type ToSingleSelectionProps<T> = Omit<T, 'value' | 'defaultValue' | 'onValueChange'> &
  SingleSelection;

export type FromSingleSelectionProps<T> = Omit<
  T,
  'selectedKey' | 'defaultSelectedKey' | 'onSelectionChange'
> &
  ValueProps;

export const fromSingleSelectionProps = <T extends SingleSelection>({
  selectedKey,
  defaultSelectedKey,
  onSelectionChange,
  ...rest
}: T): FromSingleSelectionProps<T> => ({
  ...rest,
  value: selectedKey,
  defaultValue: defaultSelectedKey,
  onValueChange: onSelectionChange,
});

export const toSingleSelectionProps = <T extends ValueProps>({
  value,
  defaultValue,
  onValueChange,
  ...rest
}: T): ToSingleSelectionProps<T> => ({
  ...rest,
  selectedKey: value,
  defaultSelectedKey: defaultValue,
  onSelectionChange: onValueChange,
});
