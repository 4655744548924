import { api } from '@meterup/proto';
import { z } from 'zod';

import type { FixMeLater } from '../../../../../types/fixMeLater';
import { NO_CATEGORY_ID } from './fields';

export const validContentFilterRuleData = z.object({
  content: z.union([
    z.object({
      type: z.literal('domain'),
      domain: z.string().min(1, 'Required'),
      categoryId: z.coerce.number().optional(),
    }),
    z.object({
      type: z.literal('category'),
      domain: z.string().optional(),
      categoryId: z.coerce.number().min(0, 'Required'),
    }),
  ]),
  precedence: z.coerce.number().min(0).max(100),
  action: z.nativeEnum(api.ContentFilterAction),
});

export type ValidContentFilterRuleData = z.infer<typeof validContentFilterRuleData>;

export const toAPIData = (data: ValidContentFilterRuleData): api.UpsertContentFilterRuleRequest => {
  const {
    action,
    content: { categoryId, domain, type },
    precedence,
  } = validContentFilterRuleData.parse(data);

  return {
    description: '',
    action,
    domain: type === 'domain' ? domain : undefined,
    category_id: type === 'category' ? categoryId : undefined,
    precedence,
  };
};

export const fromAPIData = (rule: api.ContentFilterRule): ValidContentFilterRuleData => ({
  content: {
    type: rule.domain ? 'domain' : 'category',
    domain: rule.domain ?? '',
    categoryId: (rule.category?.id.toFixed() as FixMeLater) ?? NO_CATEGORY_ID,
  },
  action: rule.action,
  precedence: rule.precedence,
});
