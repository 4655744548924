import type { AriaSwitchProps } from '@react-types/switch';
import cx from 'classnames';
import React, { forwardRef, useRef } from 'react';
import { useSwitch } from 'react-aria';
import { useToggleState } from 'react-stately';

import type { RenameKeys } from '../../types/rename_keys';
import type { Simplify } from '../../types/simplify';
import { VisuallyHidden } from '../../common/visually_hidden';
import Box from '../../utilities/Box/Box';
import { space } from '../../utilities/shared/sizes';

export type ToggleInputSize = 'small' | 'large';

export type ToggleInputProps = Simplify<
  RenameKeys<
    AriaSwitchProps,
    {
      /**
       * Set if the component is selected.
       */
      isSelected: 'selected';
      /**
       * Set if the component is disabled.
       */
      isDisabled: 'disabled';
    }
  >
> & {
  /**
   * Set the size utilizing the [`controlSize` API](/?path=/docs/handlers-providers--use-control-size).
   */
  controlSize?: ToggleInputSize;
  disabled?: boolean;
};

const ToggleInput = forwardRef<HTMLLabelElement, ToggleInputProps>(
  ({ controlSize = 'large', ...props }, forwardedRef) => {
    const { disabled = false, selected } = props;
    const ariaProps = {
      ...props,
      isSelected: selected,
      isDisabled: disabled,
    };

    const ref = useRef<HTMLInputElement>(null);
    const state = useToggleState(ariaProps);
    const { inputProps } = useSwitch(ariaProps, state, ref);

    const classes = cx('m-group');

    const switchClasses = cx(
      `m-transform m-transition m-ease-in-out m-duration-200`,

      // State
      {
        'm-shadow-controlSecondaryInitialLight dark:m-shadow-controlSecondaryInitialDark':
          !state.isSelected,
        'group-hover:m-shadow-controlSecondaryHoveredLight dark:group-hover:m-shadow-controlSecondaryHoveredDark':
          !state.isSelected,
        'group-focus:m-shadow-controlSecondaryFocusedLight dark:group-focus:m-shadow-controlSecondaryFocusedDark':
          !state.isSelected,
        'm-origin-right m-shadow-controlPrimaryInitialLight': state.isSelected,
        'group-hover:m-shadow-controlPrimaryHoveredLight dark:group-hover:m-shadow-controlPrimaryHoveredDark':
          state.isSelected,
        'group-focus:m-shadow-controlPrimaryHoveredLight dark:group-focus:m-shadow-controlPrimaryHoveredDark':
          state.isSelected,
      },
    );

    return (
      <Box
        ref={forwardedRef}
        className={classes}
        as="label"
        display="inline-flex"
        align="center"
        width={controlSize === 'small' ? space(28) : space(32)}
        height={controlSize === 'small' ? space(16) : space(20)}
        padding={{ x: space(4) }}
        bg={{
          light: state.isSelected ? 'brand-600' : 'gray-100',
          dark: state.isSelected ? 'brand-600' : 'gray-600',
        }}
        cursor="pointer"
        radius={{ all: 'full' }}
        focus="within"
        role="checkbox"
        aria-checked={state.isSelected}
      >
        <VisuallyHidden>
          <input {...inputProps} ref={ref} />
        </VisuallyHidden>
        <Box
          aria-hidden="true"
          display="flex"
          width={controlSize === 'small' ? space(10) : space(14)}
          height={controlSize === 'small' ? space(10) : space(14)}
          bg={{ light: 'white', dark: 'white' }}
          pointerEvents="none"
          radius={{ all: 'full' }}
          translateX={state.isSelected ? space(10) : 0}
          className={switchClasses}
        />
      </Box>
    );
  },
);

export default ToggleInput;
