import { Body2, darkThemeSelector } from '@meterup/metric';

import { colors, fontWeights, shadows, styled } from '../../stitches';

export const Page = styled('div', {
  vStack: 0,
  alignItems: 'stretch',
  height: '100%',
  width: '100%',
  overflow: 'auto',
});

export const PageHeader = styled('div', {
  flexShrink: 0,
  hStack: '$12',
  paddingX: '$20',
  height: '$56',
  boxShadow: shadows.fenceBottomLight,
  minWidth: 'fit-content',
  position: 'sticky',
  left: 0,
});

export const PageTitle = styled('h1', Body2, {
  fontWeight: fontWeights.medium,
  color: colors['gray-800'],
  [darkThemeSelector]: {
    color: colors['gray-100'],
  },
});

export const PageSection = styled('div', {
  width: '100%',
  flex: 1,
});

export const PageControls = styled('div', {
  hStack: '$8',
  marginLeft: 'auto',
});
