import { Address4 } from 'ip-address';
import * as z from 'zod';

import { isDefined } from '../helpers/isDefined';

export const safeParseAddress4 = (addr: any): Address4 | null => {
  try {
    return new Address4(addr);
  } catch (e) {
    return null;
  }
};

/**
 * If the IP range of the subnet contains only one address (i.e. is /32), returns the address.
 * Otherwise, returns the second address in the range.
 */
export const getFirstUsableAddress = (addr: Address4) =>
  addr.subnet === '/32' ? addr.endAddress() : addr.startAddressExclusive();

/**
 * If the IP range of the subnet contains only one address, returns that
 * address. If it contains two addresses, returns the second address. Otherwise,
 * returns the second-to-last address in the range.
 */
export const getLastUsableAddress = (addr: Address4) =>
  ['/31', '/32'].includes(addr.subnet) ? addr.endAddress() : addr.endAddressExclusive();

export const validIPv4String = () =>
  z.string().superRefine((val, ctx) => {
    const address = safeParseAddress4(val);
    if (!isDefined(address)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Must be a valid IP address like xx.xx.xx.xx',
        fatal: true,
      });
    }
  });

export const validIPv4Range = () =>
  validIPv4String()
    .refine(
      (val) => {
        const address = safeParseAddress4(val)!;
        return address.correctForm() === address.startAddress().correctForm();
      },
      (val) => {
        const address = safeParseAddress4(val)!;
        return {
          message: `Must be the lowest IP represented by the range. Expected ${address
            .startAddress()
            .correctForm()}.`,
        };
      },
    )
    .refine(
      (val) => safeParseAddress4(val)!.subnet !== '/32',
      'Must not be a single IP address or /32 subnet',
    );

export interface Address4Range {
  start: Address4;
  end: Address4;
}

export const isIPWithinRange = (ip: Address4, range: Address4Range) =>
  ip.bigInteger() >= range.start.bigInteger() && ip.bigInteger() <= range.end.bigInteger();
