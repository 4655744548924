import type * as Polymorphic from '@radix-ui/react-polymorphic';
import type { ReactNode } from 'react';
import React from 'react';

import type { IconName } from '../../assets/Icon/Icon';
import type {
  PolymorphicComponentProps,
  PolymorphicRef,
} from '../../utilities/types/polymorphicAsProp';
import Icon from '../../assets/Icon/Icon';
import { colors, darkThemeSelector, fontWeights, shadows, styled } from '../../stitches.config';
import { Body2 } from '../../text/Body';
import { isDefined } from '../../utilities/isDefined';

export type ToastVariant = 'alternative' | 'brand' | 'negative' | 'neutral' | 'positive';

const Container = styled('div', {
  position: 'relative',
  hStack: '$8',
  width: 'max-content',
  backgroundColor: '$$backgroundColor',
  padding: '$8 $12',
  boxShadow: shadows.overlayLight,
  borderRadius: 100,
  [darkThemeSelector]: {
    boxShadow: shadows.overlayDark,
  },
  '&:before': {
    content: '',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: 100,
    boxShadow: 'inset 0 0 0 1px $$strokeColor',
    opacity: 0.5,
  },
  variants: {
    variant: {
      alternative: {
        $$backgroundColor: colors['blue-50'],
        $$headingColor: colors['blue-800'],
        $$iconColor: colors['blue-700'],
        $$strokeColor: colors['blue-500'],
        [darkThemeSelector]: {
          $$backgroundColor: colors['blue-900'],
          $$headingColor: colors['blue-50'],
          $$iconColor: colors['blue-100'],
          $$strokeColor: colors['blue-300'],
        },
      },
      brand: {
        $$backgroundColor: colors['brand-50'],
        $$headingColor: colors['brand-800'],
        $$iconColor: colors['brand-700'],
        $$strokeColor: colors['brand-500'],
        [darkThemeSelector]: {
          $$backgroundColor: colors['brand-900'],
          $$headingColor: colors['brand-50'],
          $$iconColor: colors['brand-100'],
          $$strokeColor: colors['brand-300'],
        },
      },
      negative: {
        $$backgroundColor: colors['red-50'],
        $$headingColor: colors['red-800'],
        $$iconColor: colors['red-700'],
        $$strokeColor: colors['red-600'],
        [darkThemeSelector]: {
          $$backgroundColor: colors['red-900'],
          $$headingColor: colors['red-50'],
          $$iconColor: colors['red-100'],
          $$strokeColor: colors['red-300'],
        },
      },
      neutral: {
        $$backgroundColor: colors['gray-50'],
        $$headingColor: colors['gray-800'],
        $$iconColor: colors['gray-900'],
        $$strokeColor: colors['gray-500'],
        [darkThemeSelector]: {
          $$backgroundColor: colors['gray-700'],
          $$headingColor: colors['gray-50'],
          $$iconColor: colors['gray-100'],
          $$strokeColor: colors['gray-200'],
        },
      },
      positive: {
        $$backgroundColor: colors['green-50'],
        $$headingColor: colors['green-900'],
        $$iconColor: colors['green-800'],
        $$strokeColor: colors['green-700'],
        [darkThemeSelector]: {
          $$backgroundColor: colors['green-900'],
          $$headingColor: colors['green-50'],
          $$iconColor: colors['green-100'],
          $$strokeColor: colors['green-500'],
        },
      },
    },
  },
});

const StyledIcon = styled(Icon, {
  color: '$$iconColor',
});

const Heading = styled(Body2, {
  fontWeight: fontWeights.medium,
  color: '$$headingColor',
});

export interface ToastProps {
  heading: ReactNode;
  icon?: IconName;
  variant?: ToastVariant;
}

export const Toast = React.forwardRef(
  <Tag extends React.ElementType>(
    { heading, icon, variant = 'neutral', ...props }: PolymorphicComponentProps<Tag, ToastProps>,
    forwardedRef: PolymorphicRef<Tag>,
  ) => (
    <Container {...props} ref={forwardedRef} variant={variant}>
      {isDefined(icon) && <StyledIcon icon={icon} />}
      <Heading>{heading}</Heading>
    </Container>
  ),
) as Polymorphic.ForwardRefComponent<'div', ToastProps>;

export default Toast;
