import { isDefined, safeParseAddress4, validIPv4String } from '@meterup/common';
import { z } from 'zod';

const MAC_ADDRESS_REGEX = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/;

const validMacAddress = z.string().regex(MAC_ADDRESS_REGEX, 'Must be a valid MAC address');

export const validStaticClient = z
  .object({
    existingIPs: z.custom<string[]>(),
    existingNames: z.custom<string[]>(),
    subnet: z.custom<string | null>(),
    name: z
      .string()
      .regex(/^[a-zA-Z0-9-]+$/, 'Must contain only letters, numbers, and hyphens')
      .min(1, 'Required'),
    mac: validMacAddress.min(1, 'Required'),
    ip: validIPv4String(),
  })
  .refine(
    (val) => {
      const clientAddr = safeParseAddress4(val.ip);
      const subnet = val.subnet ? safeParseAddress4(val.subnet) : null;

      if (isDefined(subnet) && isDefined(clientAddr)) {
        return clientAddr.isInSubnet(subnet);
      }

      return true;
    },
    {
      message: 'Must belong to the network subnet',
      path: ['ip'],
    },
  )
  .refine((val) => !val.existingIPs.includes(val.ip), {
    message: 'IP already assigned',
    path: ['ip'],
  })
  .refine((val) => !val.existingNames.includes(val.name), {
    message: 'Name already in use',
    path: ['name'],
  });

export type ValidStaticClient = z.infer<typeof validStaticClient>;
