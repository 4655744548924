import type { Paths } from '@meterup/common';
import { createCustomFieldPropsProvider } from '@meterup/metric';
import { useFormikContext } from 'formik';

import { getFieldProps, getInputProps } from '../utils/inputs';

export const FieldProvider = createCustomFieldPropsProvider(({ name }: { name: string }) => {
  const form = useFormikContext<any>();
  return {
    inputProps: getInputProps(form, name),
    fieldProps: getFieldProps(form, name),
  };
});

export const createFieldProvider = <T extends object>() =>
  FieldProvider as React.FC<{ name: Paths<T> }>;
