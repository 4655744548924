import { colors, shadows, styled } from '../stitches';

const HeaderContainer = styled('div', {
  padding: '$12 $20',
  boxShadow: shadows.fenceAllLight,
  overflow: 'auto',
});

const Container = styled('div', {
  boxShadow: shadows.fenceAllLight,
  overflow: 'auto',
  padding: 0,
});

const ControllerEphemera = styled('div', {
  marginLeft: '$20',
  marginRight: '$20',
  hStack: '$8',
  paddingTop: '$12',
  paddingBottom: 11,
  borderBottom: `1px solid ${colors['gray-100']}`,
});

const HeaderContent = styled('div', {
  hStack: '$12',
  overflow: 'auto',
  padding: '$12 $20',
});

const HeadingAndTabs = styled('div', {
  vStack: '$4',
  alignItems: 'stretch',
  justifyContent: 'center',
  minHeight: 60,
});

export const DetailPageLayouts = {
  HeaderContainer,
  Container,
  ControllerEphemera,
  HeaderContent,
  HeadingAndTabs,
};
