import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

interface ReadonlySize {
  readonly width: number;
  readonly height: number;
}

const ViewportContext = createContext<ReadonlySize>({ height: 0, width: 0 });

const useViewportSizeMonitor = (): ReadonlySize => {
  const [size, setSize] = useState<ReadonlySize>({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const handleResize = useCallback(() => {
    setSize({ width: window.innerWidth, height: window.innerHeight });
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  return size;
};

/**
 * Measures the inner width and height of the window element and makes the dimensions available to
 * the {@link useViewport} hook through context.
 */
export const ViewportProvider: React.FC = ({ children }) => {
  const size = useViewportSizeMonitor();
  return <ViewportContext.Provider value={size}>{children}</ViewportContext.Provider>;
};

/**
 * @returns The inner width and height of the window element, representing the dimensions of the
 * user's viewport for use in responsive layouts.
 */
export const useViewport = (): ReadonlySize => useContext(ViewportContext);
