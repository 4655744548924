import { Tab2, Tabs2 } from '@meterup/metric';

import { ReactRouterLink } from '../../../../components/ReactRouterLink';
import { paths } from '../../../../constants';
import { useIsActiveMatcher } from '../../../../hooks/useIsActive';
import { useCurrentCompany } from '../../../../providers/CurrentCompanyProvider';
import { useCurrentController } from '../../../../providers/CurrentControllerProvider';
import { makeDrawerLink } from '../../../../utils/main_and_drawer_navigation';

export const VPNSummaryTabs = ({ sid }: { sid: string }) => {
  const companyName = useCurrentCompany();
  const controllerName = useCurrentController();
  const isActiveTest = useIsActiveMatcher();
  return (
    <Tabs2>
      <Tab2
        icon="information"
        as={ReactRouterLink}
        to={makeDrawerLink(window.location, paths.drawers.VPNClientSummaryPage, {
          sid,
          controllerName,
          companyName,
        })}
        active={isActiveTest({ path: paths.drawers.VPNClientSummaryPage, end: true })}
      >
        Details
      </Tab2>
      <Tab2
        icon="wrench"
        as={ReactRouterLink}
        to={makeDrawerLink(window.location, paths.drawers.VPNClientConfigSummary, {
          sid,
          controllerName,
          companyName,
        })}
        active={isActiveTest({ path: paths.drawers.VPNClientConfigSummary, end: true })}
      >
        Config
      </Tab2>
    </Tabs2>
  );
};
