import {
  BasicSelect,
  BasicSelectItem,
  CompositeField,
  FieldContainer,
  PrimaryField,
  PrimaryFieldComposite,
  SelectGroup,
  SelectLabel,
  SelectSeparator,
  TextInput,
} from '@meterup/metric';
import { api } from '@meterup/proto';
import { partition } from 'lodash';
import React, { useMemo } from 'react';
import { useQuery } from 'react-query';

import type { FixMeLater } from '../../../../../types/fixMeLater';
import type { ValidContentFilterRuleData } from './form_data';
import { getRuleCategories } from '../../../../../api/api';
import { Box } from '../../../../../components/Box';
import { createFieldProvider } from '../../../../../components/FieldProvider';
import { FormikConditional } from '../../../../../components/FormikConditional';
import { useCurrentCompany } from '../../../../../providers/CurrentCompanyProvider';

export const NO_CATEGORY_ID = -1;

const FieldProvider = createFieldProvider<ValidContentFilterRuleData>();

const stable: FixMeLater = [];

const ContentTypeField = () => (
  <FieldProvider name="content.type">
    <CompositeField
      element={
        <BasicSelect>
          <BasicSelectItem value="domain">Domain</BasicSelectItem>
          <BasicSelectItem value="category">Category</BasicSelectItem>
        </BasicSelect>
      }
      label="Type"
    />
  </FieldProvider>
);

const DomainField = () => (
  <FieldProvider name="content.domain">
    <CompositeField element={<TextInput />} label="Type" />
  </FieldProvider>
);

function useSortedContentCategories() {
  const company = useCurrentCompany();

  const contentCategories =
    useQuery(['content_filter_rule_categories'], () => getRuleCategories(company), {
      suspense: true,
    }).data ?? stable;

  const sortedContentCategories = useMemo(() => {
    const [categoriesWithChildren, categoriesWithoutChildren] = partition(
      contentCategories,
      (c) => c.subcategories.length > 0,
    ).map((arr) => arr.sort((a, b) => a.name.localeCompare(b.name)));

    return [...categoriesWithChildren, ...categoriesWithoutChildren];
  }, [contentCategories]);
  return sortedContentCategories;
}

const CategoryField = ({
  contentCategories,
}: {
  contentCategories: api.ContentFilterRuleCategory[];
}) => (
  <FieldProvider name="content.categoryId">
    <CompositeField
      element={
        <BasicSelect>
          <BasicSelectItem value={NO_CATEGORY_ID as FixMeLater} disabled>
            Choose category
          </BasicSelectItem>

          <SelectSeparator />

          {contentCategories.map((category) => (
            <React.Fragment key={category.id}>
              {category.subcategories.length > 0 ? (
                <SelectGroup>
                  <SelectLabel>{category.name}</SelectLabel>
                  <BasicSelectItem value={category.id.toFixed()}>
                    All "{category.name}"
                  </BasicSelectItem>
                  {category.subcategories.map((child: api.ContentFilterRuleCategory) => (
                    <BasicSelectItem key={child.id} value={child.id.toFixed()}>
                      {child.name}
                    </BasicSelectItem>
                  ))}
                </SelectGroup>
              ) : (
                <BasicSelectItem value={category.id.toFixed()}>{category.name}</BasicSelectItem>
              )}

              {category.subcategories.length > 0 && <SelectSeparator />}
            </React.Fragment>
          ))}
        </BasicSelect>
      }
      label="Type"
    />
  </FieldProvider>
);

export const ContentField = () => {
  const contentCategories = useSortedContentCategories();
  return (
    <FieldContainer>
      <PrimaryFieldComposite
        label="Content"
        fields={
          <Box css={{ hStack: '$8' }}>
            <ContentTypeField />
            <Box css={{ flex: 1 }}>
              <FormikConditional<ValidContentFilterRuleData>
                condition={(values) => values.content.type === 'domain'}
              >
                <DomainField />
              </FormikConditional>
              <FormikConditional<ValidContentFilterRuleData>
                condition={(values) => values.content.type === 'category'}
              >
                <CategoryField contentCategories={contentCategories} />
              </FormikConditional>
            </Box>
          </Box>
        }
      />
    </FieldContainer>
  );
};

export const ActionField = () => (
  <FieldContainer>
    <FieldProvider name="action">
      <PrimaryField
        label="Action"
        element={
          <BasicSelect>
            <BasicSelectItem value={api.ContentFilterAction.CONTENTFILTER_ACTION_ALLOW}>
              Allow
            </BasicSelectItem>
            <BasicSelectItem value={api.ContentFilterAction.CONTENTFILTER_ACTION_BLOCK}>
              Block
            </BasicSelectItem>
          </BasicSelect>
        }
      />
    </FieldProvider>
  </FieldContainer>
);

export const PriorityField = () => (
  <FieldContainer>
    <FieldProvider name="precedence">
      <PrimaryField label="Priority" element={<TextInput />} />
    </FieldProvider>
  </FieldContainer>
);
