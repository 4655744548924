import type { api } from '@meterup/proto';
import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { ContentFilterActionBadge, isDefinedAndNotEmpty } from '@meterup/common';
import { Button, EmptyState } from '@meterup/metric';
import { DateTime } from 'luxon';
import React from 'react';
import { useQuery } from 'react-query';

import { getRulesForCompany, listEnabledContentFiltersForCompany } from '../../../../api/api';
import { NoValue } from '../../../../components/NoValue';
import { Page, PageControls, PageHeader, PageTitle } from '../../../../components/Page/Page';
import { ReactRouterLink } from '../../../../components/ReactRouterLink';
import { AutoTable2 } from '../../../../components/Table/AutoTable2';
import { createColumnBuilder } from '../../../../components/Table/createColumnBuilder';
import { paths } from '../../../../constants';
import { Nav } from '../../../../nav';
import { useCurrentCompany } from '../../../../providers/CurrentCompanyProvider';
import { useCurrentController } from '../../../../providers/CurrentControllerProvider';
import { formatRuleContentName } from '../../../../utils/content_filters';
import { makeDrawerLink } from '../../../../utils/main_and_drawer_navigation';

const builder = createColumnBuilder<api.ContentFilterRule>();

const columns = [
  builder.data((d) => formatRuleContentName(d), {
    header: 'Content',
    meta: {
      isLeading: true,
    },
  }),
  builder.data((d) => d.action, {
    header: 'Action',
    cell: ContentFilterActionBadge,
  }),
  builder.data((d) => d.precedence.toFixed(0), {
    header: 'Priority',
  }),
  builder.data((d) => d.created_at ?? '', {
    header: `Created`,
    cell: (d) =>
      isDefinedAndNotEmpty(d.value) ? (
        <>{DateTime.fromISO(d.value).toLocaleString(DateTime.DATETIME_MED)}</>
      ) : (
        <NoValue />
      ),
  }),
];

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/controller/:controllerName/content-filters',
});

export default function ContentFilterListPage() {
  const companyName = useCurrentCompany();
  const controllerName = useCurrentController();

  const rules =
    useQuery(
      ['content_filters', companyName, 'rules'],
      async () => getRulesForCompany(companyName),
      {
        suspense: true,
      },
    ).data ?? [];

  const status = useQuery(
    ['content_filters', companyName, 'status'],
    async () => listEnabledContentFiltersForCompany(companyName),
    {
      suspense: true,
    },
  ).data;

  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.ContentFilterRuleEditPage);

  const enableFilteringDrawerLink = makeDrawerLink(
    window.location,
    paths.drawers.ContentFilterEnablePage,
    {
      companyName,
      controllerName,
    },
  );
  const createRuleDrawerLink = makeDrawerLink(
    window.location,
    paths.drawers.ContentFilterRuleCreatePage,
    {
      companyName,
      controllerName,
    },
  );

  const isEnabled = status?.enabled.some((d) => d.controller_name === controllerName) ?? false;

  const enabledContent = (
    <>
      <PageHeader>
        <PageTitle>Content filtering</PageTitle>
        <PageControls>
          <Button
            as={ReactRouterLink}
            to={createRuleDrawerLink}
            variant="tertiary"
            icon="plusCircle"
            arrangement="leading-icon"
          >
            Create rule
          </Button>
        </PageControls>
      </PageHeader>
      {rules.length > 0 ? (
        <AutoTable2
          columns={columns}
          data={rules}
          getLinkTo={(row) =>
            makeDrawerLink(window.location, paths.drawers.ContentFilterRuleEditPage, {
              id: row.id,
              companyName,
              controllerName,
            })
          }
          isRowSelected={(row) => row.id === drawerParams?.id}
        />
      ) : (
        <EmptyState
          icon="contentFilter"
          heading="No filter rules"
          action={
            <Button
              as={ReactRouterLink}
              to={createRuleDrawerLink}
              variant="tertiary"
              icon="plusCircle"
              arrangement="leading-icon"
              size="small"
            >
              Create a new rule
            </Button>
          }
        />
      )}
    </>
  );

  return (
    <Page>
      {isEnabled ? (
        enabledContent
      ) : (
        <EmptyState
          icon="contentFilter"
          heading="Content filtering not enabled"
          action={
            <Button
              as={ReactRouterLink}
              to={enableFilteringDrawerLink}
              variant="primary"
              arrangement="leading-icon"
              size="medium"
            >
              Enable content filtering
            </Button>
          }
        />
      )}
    </Page>
  );
}
