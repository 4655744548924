import type { ReactNode } from 'react';
import React from 'react';

import type { IconName } from '../../assets/Icon/Icon';
import { Icon } from '../../assets/Icon/Icon';
import { ControlSizeProvider } from '../../common/control_size';
import { colors, fontWeights, styled } from '../../stitches.config';
import { Body2 } from '../../text/Body';

const Container = styled('div', {
  vStack: '$12',
  flex: 1,
  height: '100%',
  width: '100%',
  '&:before': {
    display: 'block',
    content: '',
    height: '30%',
  },
});

const Heading = styled(Body2, {
  fontWeight: fontWeights.medium,
  color: colors['gray-700'],
});

const StyledIcon = styled(Icon, {
  width: '$32',
  height: '$32',
  color: colors['gray-400'],
});

export interface EmptyStateProps {
  heading: ReactNode;
  icon?: IconName;
  action?: ReactNode;
}

const EmptyState = ({ heading, icon, action }: EmptyStateProps) => (
  <Container>
    {icon && <StyledIcon icon={icon} />}
    <Heading>{heading}</Heading>
    {action && <ControlSizeProvider value="small">{action}</ControlSizeProvider>}
  </Container>
);

export default EmptyState;
