import type { BadgeProps } from '@meterup/metric';
import { Badge } from '@meterup/metric';
import React from 'react';
import { match } from 'ts-pattern';

import type { ConnectionStatus } from '../utils/clientLists';

export const OnlineBadge = () => (
  <Badge ends="pill" size="small" variant="positive">
    Online
  </Badge>
);

export const OfflineBadge = () => (
  <Badge ends="pill" size="small" variant="neutral">
    Offline
  </Badge>
);

export const WiredBadge = () => (
  <Badge ends="pill" size="small" variant="neutral">
    Wired
  </Badge>
);

export const ConnectionStatusBadge = ({ status }: { status: ConnectionStatus }) =>
  match(status)
    .with('online', () => <OnlineBadge />)
    .with('offline', () => <OfflineBadge />)
    .with('wired', () => <WiredBadge />)
    .exhaustive();

type CurriedBadgeProps = Partial<BadgeProps>;

export const NeutralBadge = ({
  ends = 'pill',
  size = 'small',
  variant = 'neutral',
  children,
  ...props
}: CurriedBadgeProps) => (
  <Badge {...props} ends={ends} size={size} variant={variant}>
    {children}
  </Badge>
);
