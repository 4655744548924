import {
  checkDefinedOrThrow,
  DeviceStatusBadge,
  DeviceStatusIcon,
  expectDefinedOrThrow,
  getEnabledRadioForBandOrNull,
  isGuest,
  isOnline,
  OnlineOfflineClientCountBadge,
  WirelessBandDetails,
} from '@meterup/common';
import {
  Badge,
  BodyMono2,
  Drawer,
  DrawerContent,
  DrawerControls,
  DrawerHeader,
  DrawerTitle,
  Heading2,
  MinList,
  MinListItemFooterLink,
  MinListItemHeader,
  MinListItemLabel,
  MinListItemPair,
  MinListItemValue,
  MinListTitle,
} from '@meterup/metric';
import { api } from '@meterup/proto';
import React from 'react';
import { useQuery } from 'react-query';

import type { DeviceDataAndRadios } from '../../api/types';
import { fetchDeviceClients, fetchDeviceWithRadioData } from '../../api/api';
import { paths } from '../../constants';
import { Nav } from '../../nav';
import { useCurrentCompany } from '../../providers/CurrentCompanyProvider';
import { useCurrentController } from '../../providers/CurrentControllerProvider';
import { styled } from '../../stitches';
import { getAPNameForDevice } from '../../utils/access_point_utils';
import { makeLink } from '../../utils/main_and_drawer_navigation';
import { CloseDrawerButton } from '../CloseDrawerButton';
import { NoValue } from '../NoValue';
import { ReactRouterLink } from '../ReactRouterLink';

const DeviceName = styled('div', {
  vStack: '$16',
  marginBottom: '$16',
});

const AccessPointDetailsWidget = ({
  data,
  shouldLinkToDetails,
}: {
  data: DeviceDataAndRadios;
  shouldLinkToDetails?: boolean;
}) => {
  const controllerName = useCurrentController();
  const companyName = useCurrentCompany();
  return (
    <MinList>
      <MinListItemHeader icon="device">
        <MinListTitle>Details</MinListTitle>
      </MinListItemHeader>
      <MinListItemPair>
        <MinListItemLabel>Status</MinListItemLabel>
        <MinListItemValue>
          <DeviceStatusBadge status={data.device.status} />
        </MinListItemValue>
      </MinListItemPair>
      <MinListItemPair>
        <MinListItemLabel>Version</MinListItemLabel>
        <MinListItemValue>
          <Badge size="small">{data.apAndRadios?.access_point?.version}</Badge>
        </MinListItemValue>
      </MinListItemPair>
      <MinListItemPair>
        <MinListItemLabel>IP</MinListItemLabel>
        <MinListItemValue>
          {data.device.ip_address ? <BodyMono2>{data.device.ip_address}</BodyMono2> : <NoValue />}
        </MinListItemValue>
      </MinListItemPair>
      <MinListItemPair>
        <MinListItemLabel>MAC</MinListItemLabel>
        <MinListItemValue>
          {data.device.mac_address ? <BodyMono2>{data.device.mac_address}</BodyMono2> : <NoValue />}
        </MinListItemValue>
      </MinListItemPair>
      {shouldLinkToDetails && (
        <MinListItemFooterLink
          as={ReactRouterLink}
          to={makeLink(paths.pages.AccessPointDetailPage, {
            deviceName: data.device.name,
            controllerName,
            companyName,
          })}
          // TODO: Figure out why replace={false} is needed here. This should be the default value.
          replace={false}
        >
          View access point
        </MinListItemFooterLink>
      )}
    </MinList>
  );
};

const AccessPointDetail = () => {
  const controller = useCurrentController();
  const { deviceName } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.AccessPointSummary),
  );

  const { data: result } = useQuery(
    ['device_with_radio', controller, deviceName],
    () => fetchDeviceWithRadioData(controller, deviceName),
    {
      suspense: true,
    },
  );

  const clients =
    useQuery(
      ['clients', deviceName, controller],
      () => fetchDeviceClients(deviceName, controller),
      {
        suspense: true,
      },
    ).data ?? [];

  const onlineClients = clients.filter(isOnline);
  const userClients = onlineClients.filter((c) => !isGuest(c));
  const guestClients = onlineClients.filter(isGuest);

  expectDefinedOrThrow(result);

  const accessPoint = result.apAndRadios?.access_point;
  const { device } = result;
  const radios = result.apAndRadios;

  expectDefinedOrThrow(accessPoint);

  return (
    <DrawerContent>
      <DeviceName>
        <DeviceStatusIcon value={device.status} />
        <Heading2>{getAPNameForDevice(device)}</Heading2>
      </DeviceName>
      <AccessPointDetailsWidget data={result} shouldLinkToDetails />

      <MinList>
        <MinListItemHeader icon="client">
          <MinListTitle>Clients</MinListTitle>
        </MinListItemHeader>
        <MinListItemPair>
          <MinListItemLabel>Online</MinListItemLabel>
          <MinListItemValue>
            <OnlineOfflineClientCountBadge icon="wifi" value={onlineClients.length} />
          </MinListItemValue>
        </MinListItemPair>
        <MinListItemPair>
          <MinListItemLabel>Users</MinListItemLabel>
          <MinListItemValue>
            <OnlineOfflineClientCountBadge icon="wifi" value={userClients.length} />
          </MinListItemValue>
        </MinListItemPair>
        <MinListItemPair>
          <MinListItemLabel>Guests</MinListItemLabel>
          <MinListItemValue>
            <OnlineOfflineClientCountBadge icon="wifi" value={guestClients.length} />
          </MinListItemValue>
        </MinListItemPair>
      </MinList>

      <WirelessBandDetails
        title="5 GHz band"
        radio={getEnabledRadioForBandOrNull(radios, api.RadioBand.RB_5G)}
      />
      <WirelessBandDetails
        title="2.4 GHz band"
        radio={getEnabledRadioForBandOrNull(radios, api.RadioBand.RB_2G)}
      />
    </DrawerContent>
  );
};

export const AccessPointSummaryDrawer = () => (
  <Drawer>
    <DrawerHeader>
      <DrawerTitle>Access point</DrawerTitle>
      <DrawerControls>
        <CloseDrawerButton />
      </DrawerControls>
    </DrawerHeader>
    <AccessPointDetail />
  </Drawer>
);
