import {
  CloseDrawerButton,
  Drawer,
  DrawerControls,
  DrawerHeader,
  DrawerTitle,
} from '@meterup/metric';
import { useState } from 'react';

import type { VPNFormData } from './form_data';
import { useCloseDrawerCallback } from '../../../../../hooks/useCloseDrawerCallback';
import { AddClientFormStep, Device } from './form_data';
import { ActivationStep, DeviceStepForm, DownloadStep, OwnerStepForm, SetupStep } from './Steps';

export const Meta = () => ({
  path: '/org/:companyName/controller/:controllerName/vpn/onboarding/add',
});

export const RenderStep = ({
  step,
  values,
  goToNextStep,
  goToPreviousStep,
  onChange,
}: {
  step: AddClientFormStep;
  values: VPNFormData;
  goToNextStep: () => void;
  goToPreviousStep: () => void;
  onChange: (values: VPNFormData) => void;
}) => {
  switch (step) {
    case AddClientFormStep.Owner:
      return (
        <OwnerStepForm
          defaultEmail={values.owner ?? ''}
          formValues={values}
          goToNextStep={goToNextStep}
          onChange={onChange}
        />
      );
    case AddClientFormStep.Device:
      return (
        <DeviceStepForm
          defaultName={values.name ?? ''}
          defaultDevice={values.device ?? Device.MacOS}
          formValues={values}
          goToNextStep={goToNextStep}
          goToPreviousStep={goToPreviousStep}
          onChange={onChange}
        />
      );
    case AddClientFormStep.Download:
      return (
        <DownloadStep
          defaultPhone={values.phone ?? ''}
          formValues={values}
          goToNextStep={goToNextStep}
          goToPreviousStep={goToPreviousStep}
          onChange={onChange}
        />
      );
    case AddClientFormStep.Config:
      return <SetupStep formValues={values} goToNextStep={goToNextStep} />;
    case AddClientFormStep.Activate:
      return <ActivationStep formValues={values} goToPreviousStep={goToPreviousStep} />;
    default:
      throw new Error('unexpected state transition');
  }
};

export default function AddClientForm() {
  const closeDrawer = useCloseDrawerCallback();

  const [step, setStep] = useState(AddClientFormStep.Owner);
  const [values, setValues] = useState<VPNFormData>({
    owner: '',
    device: Device.MacOS,
    name: '',
    public_key: '',
    private_key: '',
    sid: '',
    activated: false,
  });

  const goToNextStep = () => {
    setStep((prevStep) => {
      if (prevStep === AddClientFormStep.Owner) {
        return AddClientFormStep.Device;
      }
      if (prevStep === AddClientFormStep.Device) {
        return AddClientFormStep.Download;
      }
      if (step === AddClientFormStep.Download) {
        return AddClientFormStep.Config;
      }
      if (prevStep === AddClientFormStep.Config) {
        return AddClientFormStep.Activate;
      }
      throw new Error('unexpected state transition');
    });
  };

  const goToPreviousStep = () => {
    setStep((currentStep) => {
      if (currentStep === AddClientFormStep.Config) {
        return AddClientFormStep.Download;
      }
      if (currentStep === AddClientFormStep.Device) {
        return AddClientFormStep.Owner;
      }
      if (currentStep === AddClientFormStep.Download) {
        return AddClientFormStep.Device;
      }
      if (currentStep === AddClientFormStep.Allow) {
        return AddClientFormStep.Config;
      }
      if (currentStep === AddClientFormStep.Activate) {
        return AddClientFormStep.Config;
      }
      throw new Error('unexpected state transition');
    });
  };

  return (
    <Drawer>
      <DrawerHeader>
        <DrawerTitle>Add client</DrawerTitle>
        <DrawerControls>
          <CloseDrawerButton onClick={closeDrawer} />
        </DrawerControls>
      </DrawerHeader>

      <RenderStep
        step={step}
        values={values}
        goToNextStep={goToNextStep}
        goToPreviousStep={goToPreviousStep}
        onChange={setValues}
      />
    </Drawer>
  );
}
