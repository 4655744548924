/* eslint-disable no-param-reassign */
import { action, makeObservable, observable, runInAction } from 'mobx';

import type { State } from '../state';
import type { BaseConfig } from './node';
import { Node } from './node';

export interface DirectoryConfig extends BaseConfig {
  children: Node[];
  /**
   * `onEnter` return a list of nodes that exist only when navigated into the directory
   */
  onEnter?: (dir: Directory) => any | (() => any) | Promise<any> | Promise<() => any>;
}

export class Directory extends Node<DirectoryConfig> {
  children: DirectoryConfig['children'] = [];

  onEnter;

  disposeDynamic;

  constructor(config: DirectoryConfig, readonly state: State) {
    super(config, state);

    this.onEnter = async () => {
      if (config.onEnter) {
        this.state.ui.update((ui) => {
          ui.loading = true;
        });

        try {
          const cleanup = await config.onEnter(this);

          this.state.ui.update((ui) => {
            ui.loading = false;
          });

          if (cleanup) {
            this.disposeDynamic = () => cleanup();
          }
        } catch {
          // Handle by the consumer
        }
      }
    };

    this.disposeDynamic = () => {};

    this.addChildren(config.children);

    makeObservable(this, {
      children: observable,
      addChildren: action,
      removeChildren: action,
    });
  }

  addChildren(nodes: Node[]) {
    runInAction(() => {
      for (let i = 0; i < nodes.length; i += 1) {
        const node = nodes[i];
        node.update((n) => {
          n.parent = this;
        });
        this.children.push(node);
      }
    });
  }

  removeChildren(nodes: Node[]) {
    runInAction(() => {
      this.children = this.children.filter((child) => !nodes.includes(child));
    });
  }
}
