import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { Header, HeaderLogo, HeaderNavItem, HStack, space } from '@meterup/metric';
import { ErrorBoundary } from '@sentry/react';
import React, { Suspense } from 'react';
import { useFocusVisible } from 'react-aria';
import { Outlet } from 'react-router';

import { Container, ContentContainer, HeaderContainer } from '../../components/app_layout';
import { ErrorFallback } from '../../components/ErrorFallback/ErrorFallback';
import { HeaderDropdownMenu } from '../../components/HeaderDropdownMenu';
import { LoadingFallback } from '../../components/LoadingFallback';
import { LocationSwitcher } from '../../components/LocationSwitcher';
import { ReactRouterLink } from '../../components/ReactRouterLink';
import { paths } from '../../constants';
import { useCurrentControllerOrNull } from '../../hooks/useCurrentControllerOrNull';
import { useCurrentCompany } from '../../providers/CurrentCompanyProvider';
import { SearchParamsStateProvider } from '../../providers/SearchParamsStateProvider';
import { focusVisibleClassName } from '../../stitches';
import { makeLink } from '../../utils/main_and_drawer_navigation';

export const Meta: PagefileMetaFn = () => ({
  name: 'AppLayout',
});

export default function AppLayout() {
  const { isFocusVisible } = useFocusVisible();
  const controllerName = useCurrentControllerOrNull();
  const companyName = useCurrentCompany();

  return (
    <SearchParamsStateProvider>
      <Container className={isFocusVisible ? focusVisibleClassName : ''}>
        <HeaderContainer>
          <Header
            navigation={
              <HStack align="center" spacing={space(8)}>
                <LocationSwitcher />
                <HeaderNavItem
                  as={ReactRouterLink}
                  to={
                    controllerName
                      ? makeLink(paths.pages.OverviewPage, {
                          controllerName,
                          companyName,
                        })
                      : makeLink(paths.pages.CompanyRootPage, { companyName })
                  }
                  label="Network"
                  isSelected
                  icon="network"
                />
              </HStack>
            }
            logo={<HeaderLogo />}
            controls={<HeaderDropdownMenu />}
          />
        </HeaderContainer>
        <ContentContainer>
          <ErrorBoundary fallback={ErrorFallback}>
            <Suspense fallback={<LoadingFallback />}>
              <Outlet />
            </Suspense>
          </ErrorBoundary>
        </ContentContainer>
      </Container>
    </SearchParamsStateProvider>
  );
}
