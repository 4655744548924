import React from 'react';

import type { Node } from '../../core/nodes/node';
import { useCommand } from '../Root';

export function useRegisterCommands(nodes: Node[], deps: React.DependencyList = []) {
  const { state } = useCommand();

  React.useEffect(() => {
    state.root.addChildren(nodes);
    return () => {
      state.root.removeChildren(nodes);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
