import type { ReactNode } from 'react';
import { isEmpty } from 'lodash';
import posthog from 'posthog-js';
import { createContext, useContext, useEffect, useRef, useState } from 'react';

import type { MeterPHFlags } from '../feature_flags';
import { LOCAL_PH_FLAG_VALUES, PH_FEATURE_FLAGS } from '../feature_flags';
import { getTabularErrorData } from '../utils/getTabularErrorData';
import { logError } from '../utils/logError';

const PostHogFeatureFlagsContext = createContext<MeterPHFlags>({} as any);

export const PosthogFeatureFlagsProvider = ({
  children,
}: {
  children: ReactNode | ReactNode[];
}) => {
  const [flags, setFlags] = useState<Record<string, string | boolean>>(() =>
    posthog.feature_flags.getFlagVariants(),
  );

  const intervalRef = useRef<NodeJS.Timer | number | null>(null);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      posthog.reloadFeatureFlags();
    }, 1000 * 60 * 5);

    return () => {
      const id = intervalRef.current;
      if (id) {
        clearInterval(id);
      }
    };
  }, []);

  useEffect(() => {
    posthog.onFeatureFlags(() => {
      const flagVariants = posthog.feature_flags.getFlagVariants();

      setFlags(flagVariants);

      if (!isEmpty(flagVariants)) {
        const result = PH_FEATURE_FLAGS.safeParse(flagVariants);

        if (!result.success) {
          if (import.meta.env.DEV) {
            /* eslint-disable no-console */
            console.warn('Feature flags from PostHog do not match the expected schema. ');
            console.table(getTabularErrorData(result.error));
            /* eslint-enable no-console */
          } else {
            logError(result.error);
          }
        }
      }
    });
  }, []);

  return (
    <PostHogFeatureFlagsContext.Provider value={flags}>
      {children}
    </PostHogFeatureFlagsContext.Provider>
  );
};

export const usePHFeatureFlags = () => {
  const flags = useContext(PostHogFeatureFlagsContext);

  if (import.meta.env.DEV) {
    return LOCAL_PH_FLAG_VALUES;
  }

  return flags;
};
