import { isDefinedAndNotEmpty, RotationStrategyBadge } from '@meterup/common';
import { Button, ListItemLabel, ListItemPair, ListItemValue } from '@meterup/metric';
import React from 'react';
import { Link } from 'react-router-dom';

import type { SSIDData } from '../api/types';
import { paths } from '../constants';
import { useCurrentCompany } from '../providers/CurrentCompanyProvider';
import { useCurrentController } from '../providers/CurrentControllerProvider';
import { getWiFiQRCodeConnectionString } from '../utils/getWiFiQRCodeConnectionString';
import { makeLink } from '../utils/main_and_drawer_navigation';
import { CollapsibleList } from './CollapsibleList';
import { PasswordViewerListItem } from './Drawers/AddClientDrawer/PasswordViewerListItem';
import { QRCodeListItem } from './Drawers/AddClientDrawer/QRCodeListItem';

export const NetworkJoinWidget = ({
  ssid,
  title,
  defaultOpen = 'guest-only',
  shouldAlwaysShowJoinInstructionsButton = false,
}: {
  ssid: SSIDData;
  defaultOpen?: 'guest-only' | 'always' | 'never';
  title?: string;
  shouldAlwaysShowJoinInstructionsButton?: boolean;
}) => {
  const isGuest = ssid.ssid.toLowerCase().includes('guest');
  const isDefaultOpen = defaultOpen === 'always' || (defaultOpen === 'guest-only' && isGuest);
  const companyName = useCurrentCompany();
  const controllerName = useCurrentController();
  return (
    <CollapsibleList title={title ?? ssid.ssid} defaultOpen={isDefaultOpen}>
      <QRCodeListItem text={getWiFiQRCodeConnectionString(ssid.ssid, ssid.password?.value ?? '')} />
      <ListItemPair>
        <ListItemLabel>SSID</ListItemLabel>
        <ListItemValue>{ssid.ssid}</ListItemValue>
      </ListItemPair>
      {isDefinedAndNotEmpty(ssid.password) && (
        <PasswordViewerListItem label="Password" password={ssid.password.value} />
      )}
      {ssid.password?.type === 'rotating' && ssid.password.rotation_interval_name && (
        <ListItemPair>
          <ListItemLabel>Password rotation</ListItemLabel>
          <ListItemValue>
            <RotationStrategyBadge value={ssid.password.rotation_interval_name} />
          </ListItemValue>
        </ListItemPair>
      )}
      {(isGuest || shouldAlwaysShowJoinInstructionsButton) && (
        <ListItemPair>
          <ListItemLabel>Tablet join instructions</ListItemLabel>
          <ListItemValue>
            <Button
              as={Link}
              to={makeLink(paths.pages.TabletJoinInstructionsPage, {
                ssid: ssid.ssid,
                companyName,
                controllerName,
              })}
              variant="secondary"
              icon="phone"
              size="small"
              arrangement="leading-icon"
            >
              View
            </Button>
          </ListItemValue>
        </ListItemPair>
      )}
    </CollapsibleList>
  );
};
