import {
  expectDefinedOrThrow,
  isDefined,
  isDefinedAndNotEmpty,
  ResourceNotFoundError,
} from '@meterup/common';
import {
  Drawer,
  DrawerContent,
  DrawerControls,
  DrawerHeader,
  DrawerTitle,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTriggerButton,
  ManufacturerIcon,
  Subheading2,
} from '@meterup/metric';
import React from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { fetchDevice } from '../../../../api/api';
import { ConnectionStatusBadge } from '../../../../components/badges';
import { BandIconAndBadge } from '../../../../components/BandIconAndBadge';
import {
  ClientHardwareWidget,
  WiredConnectionWidget,
  WirelessConnectionWidget,
  WirelessQualityWidget,
} from '../../../../components/clients';
import { CloseDrawerButton } from '../../../../components/CloseDrawerButton';
import { paths } from '../../../../constants';
import { useClientDataByMAC } from '../../../../hooks/useClientDataByMAC';
import { useVLANSFromClientMatcher } from '../../../../hooks/useVLANClients';
import { Nav } from '../../../../nav';
import { useCurrentCompany } from '../../../../providers/CurrentCompanyProvider';
import { useCurrentController } from '../../../../providers/CurrentControllerProvider';
import { usePHFeatureFlags } from '../../../../providers/PostHogFeatureFlagsProvider';
import { styled } from '../../../../stitches';
import {
  clientNameOrNull,
  getClientConnectionStatus,
  isWired,
  isWireless,
  manufacturerIconName,
} from '../../../../utils/clientLists';
import { makeDrawerLink, makeLink } from '../../../../utils/main_and_drawer_navigation';

const ClientName = styled('div', {
  vStack: '$8',
  alignItems: 'center',
});

export const Meta = () => ({
  path: '/org/:companyName/controller/:controllerName/clients/:macAddress',
});

export default function ClientSummaryPage() {
  const controllerName = useCurrentController();
  const companyName = useCurrentCompany();
  const { macAddress } = Nav.useRegionParams('drawer', paths.drawers.ClientSummaryPage)!;

  const client = useClientDataByMAC(controllerName, macAddress);

  const navigate = useNavigate();

  expectDefinedOrThrow(client, new ResourceNotFoundError('Client not found'));

  const { data: device } = useQuery(
    ['device', controllerName, client.apname],
    () => fetchDevice(controllerName, client.apname),
    {
      suspense: true,
      enabled: isDefinedAndNotEmpty(client.apname),
    },
  );

  const vlanMatcher = useVLANSFromClientMatcher(controllerName);

  const vlans = vlanMatcher(client);
  const vlan = vlans.length === 1 ? vlans?.[0] : null;

  const phFlags = usePHFeatureFlags();

  return (
    <Drawer>
      <DrawerHeader>
        <DrawerTitle>Client</DrawerTitle>
        <DrawerControls>
          <DrawerControls>
            <DropdownMenu>
              <DropdownMenuTriggerButton variant="tertiary" icon="cog" arrangement="hidden-label">
                Actions
              </DropdownMenuTriggerButton>

              <DropdownMenuContent>
                <DropdownMenuItem
                  onSelect={() =>
                    navigate(
                      makeDrawerLink(window.location, paths.drawers.ClientRenamePage, {
                        macAddress,
                        controllerName,
                        companyName,
                      }),
                    )
                  }
                >
                  Rename client
                </DropdownMenuItem>
                {phFlags.config10 && isDefined(vlan) && (
                  <DropdownMenuItem
                    onSelect={() => {
                      navigate(
                        Nav.makeTo({
                          root: {
                            pathname: window.location.pathname,
                            search: `mac=${macAddress}&name=${client.name}&ip=${client.ip_address}`,
                          },
                          drawer: makeLink(paths.drawers.VLANStaticIPCreatePage, {
                            vlanName: vlan.name,
                            controllerName,
                            companyName,
                          }),
                        }),
                      );
                    }}
                  >
                    Pin IP address
                  </DropdownMenuItem>
                )}
              </DropdownMenuContent>
            </DropdownMenu>
            <CloseDrawerButton />
          </DrawerControls>
        </DrawerControls>
      </DrawerHeader>
      <DrawerContent>
        <ClientName>
          <BandIconAndBadge
            size="medium"
            variant={getClientConnectionStatus(client) === 'online' ? 'positive' : 'neutral'}
            badge={<ConnectionStatusBadge status={getClientConnectionStatus(client)} />}
            icon={<ManufacturerIcon icon={manufacturerIconName(client)} size="medium" />}
          />
          <Subheading2>{clientNameOrNull(client) ?? '-'}</Subheading2>
        </ClientName>
        <ClientHardwareWidget client={client} shouldLinkToDetails />
        {isWired(client) && <WiredConnectionWidget client={client} />}
        {isWireless(client) && (
          <>
            <WirelessConnectionWidget client={client} device={device} />
            <WirelessQualityWidget client={client} />
          </>
        )}
      </DrawerContent>
    </Drawer>
  );
}
