import { checkDefinedOrThrow } from '@meterup/common';
import React from 'react';

import { ClientsList } from '../../../../../components/ClientsList';
import { Page } from '../../../../../components/Page/Page';
import { paths } from '../../../../../constants';
import { useVLANClients } from '../../../../../hooks/useVLANClients';
import { useVLANConfigModel } from '../../../../../hooks/useVLANConfigModel';
import { Nav } from '../../../../../nav';
import { useCurrentController } from '../../../../../providers/CurrentControllerProvider';

export const Meta = () => ({
  path: '/org/:companyName/controller/:controllerName/vlans/:vlanName/clients',
});

export default function VLANClientListPage() {
  const { vlanName } = checkDefinedOrThrow(
    Nav.useRegionParams('root', paths.pages.VLANClientListPage),
  );

  const controllerName = useCurrentController();

  useVLANConfigModel(controllerName, vlanName);

  const vlanClients = useVLANClients(controllerName, vlanName);

  return (
    <Page>
      <ClientsList
        clients={vlanClients}
        showTabSwitcher={false}
        showShowWirelessInfoButton={false}
      />
    </Page>
  );
}
