import {
  BasicSelectItem,
  darkThemeSelector,
  FocusRingSelf,
  Icon,
  Select,
  SelectContent,
  SelectScrollDownButton,
  SelectScrollUpButton,
  SelectTrigger,
  SelectValue,
  SelectViewport,
  Small2,
} from '@meterup/metric';
import React from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router';

import { fetchInstalledPrimaryControllers } from '../api/api';
import { paths } from '../constants';
import { useCurrentControllerOrNull } from '../hooks/useCurrentControllerOrNull';
import { useCurrentCompany } from '../providers/CurrentCompanyProvider';
import { colors, fontWeights, styled } from '../stitches';
import { makeLink } from '../utils/main_and_drawer_navigation';

const StyledIcon = styled(Icon, {
  color: colors['gray-600'],
  width: '$12',
  height: '$12',
  [darkThemeSelector]: {
    color: colors['gray-200'],
  },
});

const MenuArrow = styled(StyledIcon, {
  color: colors['gray-500'],
  [darkThemeSelector]: {
    color: colors['gray-300'],
  },
});

const ValueContainer = styled('div', Small2, {
  fontWeight: fontWeights.medium,
  truncate: true,
  color: colors['gray-700'],
  [darkThemeSelector]: {
    color: colors['gray-100'],
  },
});

const Trigger = styled('button', FocusRingSelf, {
  maxWidth: 236,
  borderRadius: '$6',
  hStack: '$6',
  padding: '$6 $8',
});

export const LocationSwitcher = () => {
  const companyName = useCurrentCompany();
  const navigate = useNavigate();

  const controllers =
    useQuery(
      ['controllers_with_status', companyName],
      () => fetchInstalledPrimaryControllers(companyName),
      { suspense: true },
    ).data ?? [];

  const currentController = useCurrentControllerOrNull();

  return controllers.length > 0 ? (
    <Select
      value={currentController ?? undefined}
      onValueChange={(controllerName) => {
        navigate(makeLink(paths.pages.OverviewPage, { controllerName, companyName }));
      }}
    >
      <SelectTrigger asChild>
        <Trigger type="button">
          <StyledIcon icon="location" />
          <ValueContainer>
            <SelectValue />
          </ValueContainer>
          <MenuArrow icon="arrowsVertical" />
        </Trigger>
      </SelectTrigger>
      <SelectContent>
        <SelectScrollUpButton />
        <SelectViewport>
          {controllers.map((controller) => (
            <BasicSelectItem key={controller.name} value={controller.name}>
              {controller.address}
            </BasicSelectItem>
          ))}
        </SelectViewport>
        <SelectScrollDownButton />
      </SelectContent>
    </Select>
  ) : null;
};
