import type { ColumnMeta } from '@tanstack/react-table';
import type {
  AccessorFnColumnDef,
  ColumnSizingColumnDef,
  DisplayColumnDef,
  SortingColumnDef,
  VisibilityColumnDef,
} from '@tanstack/table-core';
import React from 'react';

interface SharedColumnDef<RowType, ValueType>
  extends VisibilityColumnDef,
    SortingColumnDef<RowType>,
    ColumnSizingColumnDef {
  id?: string;
  header?: string;
  meta?: ColumnMeta<RowType, ValueType>;
  enableGlobalFilter?: boolean;
}

export function createColumnBuilder<RowType>() {
  let nextId = 0;
  return {
    data: <ValueType extends string>(
      accessorFn: (row: RowType) => ValueType,
      {
        id,
        cell,
        ...attrs
      }: SharedColumnDef<RowType, ValueType> & {
        header: string;
        cell?: (props: { value: ValueType; row: RowType }) => JSX.Element | null;
      },
    ): AccessorFnColumnDef<RowType, ValueType> => ({
      // eslint-disable-next-line no-plusplus
      id: id ?? `c${nextId++}`,
      accessorFn,
      ...attrs,
      ...(cell
        ? {
            cell: (props) =>
              React.createElement(cell, {
                row: props.row.original,
                value: props.getValue(),
              }),
          }
        : undefined),
    }),
    display: ({
      id,
      cell,
      ...attrs
    }: SharedColumnDef<RowType, never> & {
      cell?: (props: { row: RowType }) => JSX.Element | null;
    }): DisplayColumnDef<RowType> => ({
      // eslint-disable-next-line no-plusplus
      id: id ?? `c${nextId++}`,
      ...attrs,
      ...(cell
        ? {
            cell: (props) =>
              React.createElement(cell, {
                row: props.row.original,
              }),
          }
        : undefined),
    }),
  };
}
