import { CloseDrawerButton as CDB } from '@meterup/metric';
import React from 'react';

import { useCloseDrawerCallback } from '../hooks/useCloseDrawerCallback';

export const CloseDrawerButton = (props: { 'aria-label'?: string }) => (
  <CDB
    aria-label={props['aria-label'] ?? 'Close drawer'}
    onClick={useCloseDrawerCallback()}
    type="button"
  />
);
