import { expectDefinedOrThrow, getControllerVersion, isDefined } from '@meterup/common';
import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router';

import { NoHardwareError } from '../errors';
import {
  matchPathWithCompanyAndControllerParams,
  useControllerDataFromPath,
} from '../hooks/useControllerDataFromPath';
import { logError } from '../utils/logError';

// TRICKY: This hook is used to validate that the useCurrentControllerData hook is only
// rendered on paths that include the company and controller names in the URL. It throws an error in
// dev mode or logs it in production if that requirement isn't met.
const useValidatePathStructure = () => {
  const location = useLocation();
  const match = matchPathWithCompanyAndControllerParams(location);

  const error = useMemo(
    () =>
      !isDefined(match) ? new Error('useCurrentControllerData mounted on invalid path') : null,
    [match],
  );

  if (import.meta.env.DEV && error) {
    throw error;
  }

  useEffect(() => {
    if (error && !import.meta.env.DEV) {
      logError(error);
    }
  }, [error]);
};

export const useCurrentControllerData = () => {
  const { currentController, controllerNameFromPath } = useControllerDataFromPath();

  useValidatePathStructure();

  expectDefinedOrThrow(
    currentController,
    new NoHardwareError(
      'Controller not found',
      `The controller "${controllerNameFromPath}" was not found. Please contact support.`,
    ),
  );

  return currentController;
};

export const useCurrentController = () => useCurrentControllerData().name;

export const useCurrentControllerVersion = () => getControllerVersion(useCurrentControllerData());
