import type { Node } from '@react-types/shared';
import type { ListState } from 'react-stately';
import cx from 'classnames';
import React, { useRef } from 'react';
import { useOption } from 'react-aria';

import type { ColorProps } from '../../utilities/shared/Color';
import type { ControlSize } from '../Control/types';
import Body from '../../text/Body';
import Small from '../../text/Small';

const getColor = (isFocused: boolean, isSelected: boolean, isDisabled: boolean): ColorProps => {
  if (isSelected) {
    return { light: 'white', dark: 'white' };
  }
  if (isFocused) {
    return { light: 'gray-600', dark: 'gray-100' };
  }
  if (isDisabled) {
    return { light: 'gray-300', dark: 'gray-300' };
  }
  return { light: 'gray-500', dark: 'gray-100' };
};

function ListBoxOption<T>({
  item,
  state,
  size = 'medium',
}: {
  item: Node<T>;
  state: ListState<T>;
  size?: ControlSize;
}) {
  const ref = useRef<HTMLLIElement>(null);
  const { optionProps, isSelected, isFocused, isDisabled } = useOption(
    { key: item.key },
    state,
    ref,
  );

  const classes = cx(
    'm-outline-none m-flex',
    {
      'm-cursor-pointer': !isDisabled,
      'm-cursor-not-allowed': isDisabled,
    },
    {
      'm-bg-gray-50 dark:m-bg-gray-600': !isSelected && isFocused,
      'm-bg-brand-600': isSelected,
    },
    {
      'm-rounded-4 m-px-1.5 m-py-0.5': size === 'small',
      'm-rounded-6 m-px-2 m-py-1': size === 'medium',
      'm-rounded-6 m-px-3 m-py-2': size === 'large' || size === 'x-large',
    },
  );

  const Content = size === 'small' ? Small : Body;

  return (
    <li {...optionProps} ref={ref} className={classes}>
      <Content color={getColor(isFocused, isSelected, isDisabled)}>{item.rendered}</Content>
    </li>
  );
}

export default ListBoxOption;
