import { useCallback } from 'react';
import { useCookies } from 'react-cookie';

import { logoutUser } from '../api/api';
import { COOKIE_NAME } from '../utils/cookies';

export const useLogoutHandler = () => {
  const [, , removeCookie] = useCookies([COOKIE_NAME]);

  return useCallback(async () => {
    localStorage.clear();
    removeCookie(COOKIE_NAME, { domain: '.meter.website' });
    removeCookie(COOKIE_NAME, { domain: '.meter.dev' });
    removeCookie(COOKIE_NAME, { domain: '.meter.com' });
    await logoutUser();
    window.location.href = 'https://www.meter.com';
  }, [removeCookie]);
};
