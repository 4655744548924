/* eslint-disable no-param-reassign */
import { action, computed, makeObservable, observable, runInAction } from 'mobx';

import type { State } from './state';

export enum VisualState {
  Hidden = 'hidden',
  Hiding = 'hiding',
  Entering = 'entering',
  Entered = 'entered',
}

export class Ui {
  loading: boolean = false;

  visualState: VisualState = VisualState.Hidden;

  activeIndex: number = 0;

  search: string = '';

  constructor(readonly state: State) {
    makeObservable(this, {
      loading: observable,
      visualState: observable,
      activeIndex: observable,
      search: observable,
      update: action,
      toggle: action,
      activeNode: computed,
      visible: computed,
    });
  }

  get activeNode() {
    const activeItem = this.state.filter.ordered[this.activeIndex];
    if (typeof activeItem === 'string') {
      return null;
    }
    return activeItem;
  }

  get visible() {
    return this.visualState === VisualState.Entered || this.visualState === VisualState.Entering;
  }

  update(mutator: (ui: Ui) => void) {
    runInAction(() => {
      mutator(this);
    });
  }

  toggle(show?: boolean) {
    let next = [VisualState.Entered].includes(this.visualState)
      ? VisualState.Hidden
      : VisualState.Entered;

    if (show !== undefined) {
      if (show) {
        next = VisualState.Entered;
      } else {
        next = VisualState.Hidden;
      }
    }

    this.update((ui) => {
      ui.visualState = next;
    });
  }
}
