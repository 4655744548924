import type { BadgeVariant, IconName } from '@meterup/metric';
import { Badge } from '@meterup/metric';
import { zxcvbn, ZxcvbnOptions } from '@zxcvbn-ts/core';
import zxcvbnCommonPackage from '@zxcvbn-ts/language-common';
import zxcvbnEnPackage from '@zxcvbn-ts/language-en';
import React from 'react';
import { useQuery } from 'react-query';

const options = {
  translations: zxcvbnEnPackage.translations,
  graphs: zxcvbnCommonPackage.adjacencyGraphs,
  dictionary: {
    ...zxcvbnCommonPackage.dictionary,
    ...zxcvbnEnPackage.dictionary,
  },
};

ZxcvbnOptions.setOptions(options);

enum PasswordStrength {
  Pending,
  VeryWeak,
  Weak,
  Good,
  Strong,
  VeryStrong,
}

const getPasswordStrengthData = (
  strength: PasswordStrength,
  // eslint-disable-next-line consistent-return
): { label: string; variant: BadgeVariant; icon?: IconName } => {
  // eslint-disable-next-line default-case
  switch (strength) {
    case PasswordStrength.Pending:
      return { label: 'Pending', variant: 'neutral' };
    case PasswordStrength.VeryWeak:
      return { label: 'Very weak', variant: 'negative' };
    case PasswordStrength.Weak:
      return { label: 'Weak', variant: 'negative' };
    case PasswordStrength.Good:
      return { label: 'Good', variant: 'neutral' };
    case PasswordStrength.Strong:
      return { label: 'Strong', variant: 'positive' };
    case PasswordStrength.VeryStrong:
      return { label: 'Very strong', variant: 'positive', icon: 'secure' };
  }
};

export const PasswordStrengthIndicator: React.FC<{ password: string }> = ({ password }) => {
  const result =
    useQuery(['passwordStrength', password], async () => (await zxcvbn(password)).score + 1).data ??
    PasswordStrength.Pending;

  const { variant, label, icon } = getPasswordStrengthData(result);

  return (
    <Badge variant={variant} icon={icon} size="small" ends="pill" arrangement="leading-icon">
      {label}
    </Badge>
  );
};
