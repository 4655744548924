import type { DisplayableError } from './isDisplayableError';
import type { WrappedError } from './isWrappedError';

export interface HTTPError extends DisplayableError, WrappedError, Error {
  readonly statusCode: number;
  readonly isHTTPError: true;
  readonly isClientError: boolean;
  readonly isServerError: boolean;
  readonly displayTitle: string;
  readonly displayMessage?: string;
  readonly originalError?: unknown;
  readonly request?: unknown;
  readonly response?: unknown;
}

export const isHTTPError = (error: unknown): error is HTTPError =>
  typeof error === 'object' &&
  error !== null &&
  'isHTTPError' in error &&
  (error as HTTPError).isHTTPError;
