import type { AdhocControllerEvent } from '@meterup/proto/esm/api';
import type { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { isDefinedAndNotEmpty } from '@meterup/common';
import { EmptyState } from '@meterup/metric';
import { DateTime } from 'luxon';
import React from 'react';
import { useQuery } from 'react-query';

import { fetchControllerEvents } from '../../../../api/api';
import { NoValue } from '../../../../components/NoValue';
import { Page } from '../../../../components/Page/Page';
import { AutoTable2 } from '../../../../components/Table/AutoTable2';
import { createColumnBuilder } from '../../../../components/Table/createColumnBuilder';
import { useCurrentController } from '../../../../providers/CurrentControllerProvider';

const builder = createColumnBuilder<AdhocControllerEvent>();

const columns = [
  builder.data((d) => d.event_type, {
    header: 'Event',
    meta: {
      isLeading: true,
    },
  }),
  builder.data((d) => d.message, {
    header: 'Message',
  }),
  builder.data((d) => d.created_at ?? '', {
    header: `Time (${DateTime.local().zoneName})`,
    cell: (d) =>
      isDefinedAndNotEmpty(d.value) ? (
        <>{DateTime.fromISO(d.value).toLocaleString(DateTime.DATETIME_MED)}</>
      ) : (
        <NoValue />
      ),
  }),
];

export const Meta: PagefileMetaFn = () => ({
  path: '/org/:companyName/controller/:controllerName/events',
});

export default function EventsListPage() {
  const controllerName = useCurrentController();

  const events =
    useQuery(
      ['controller', controllerName, 'logs'],
      async () => fetchControllerEvents(controllerName),
      {
        suspense: true,
      },
    ).data ?? [];

  return (
    <Page>
      {events.length > 0 ? (
        <AutoTable2 columns={columns} data={events} />
      ) : (
        <EmptyState icon="log" heading="No recent events" />
      )}
    </Page>
  );
}
