import React from 'react';

import PagefilesImport0 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/hardware/AccessPointSummary.page.tsx";
import PagefilesImport1 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/hardware/AddClient.page.tsx";
import PagefilesImport2 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/hardware/ClientRename.page.tsx";
import PagefilesImport3 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/hardware/ClientSummary.page.tsx";
import PagefilesImport4 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/settings/VLANSummary.page.tsx";
import PagefilesImport5 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/vpn/VPNClientConfigSummary.page.tsx";
import PagefilesImport6 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/vpn/VPNClientSummary.page.tsx";
import PagefilesImport7 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/settings/content_filters/ContentFilterEnable.page.tsx";
import PagefilesImport8 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/settings/content_filters/ContentFilterRuleCreate.page.tsx";
import PagefilesImport9 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/settings/content_filters/ContentFilterRuleEdit.page.tsx";
import PagefilesImport10 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/settings/content_filters/ContentFilterRuleRemove.page.tsx";
import PagefilesImport11 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/settings/ssids/LegacySSIDDetails.page.tsx";
import PagefilesImport12 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/settings/ssids/SSIDCreate.page.tsx";
import PagefilesImport13 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/settings/ssids/SSIDEdit.page.tsx";
import PagefilesImport14 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/settings/ssids/SSIDRemove.page.tsx";
import PagefilesImport15 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/settings/static_clients/VLANStaticIPCreate.page.tsx";
import PagefilesImport16 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/settings/static_clients/VLANStaticIPEdit.page.tsx";
import PagefilesImport17 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/settings/static_clients/VLANStaticIPRemove.page.tsx";
import PagefilesImport18 from "/vercel/path0/apps/dashboard/src/routes/drawers/network/vpn/onboarding/AddClientForm.page.tsx";

const routes = [
{path: "/org/:companyName/controller/:controllerName/clients/:macAddress",element: React.createElement(PagefilesImport3)},
{path: "/org/:companyName/controller/:controllerName/clients/:macAddress/rename",element: React.createElement(PagefilesImport2)},
{path: "/org/:companyName/controller/:controllerName/clients/add",element: React.createElement(PagefilesImport1)},
{path: "/org/:companyName/controller/:controllerName/content-filters/enable",element: React.createElement(PagefilesImport7)},
{path: "/org/:companyName/controller/:controllerName/content-filters/rules/:id",element: React.createElement(PagefilesImport9)},
{path: "/org/:companyName/controller/:controllerName/content-filters/rules/:id/remove",element: React.createElement(PagefilesImport10)},
{path: "/org/:companyName/controller/:controllerName/content-filters/rules/create",element: React.createElement(PagefilesImport8)},
{path: "/org/:companyName/controller/:controllerName/devices/:deviceName",element: React.createElement(PagefilesImport0)},
{path: "/org/:companyName/controller/:controllerName/ssid/:ssidName",element: React.createElement(PagefilesImport11)},
{path: "/org/:companyName/controller/:controllerName/ssids/:id/edit",element: React.createElement(PagefilesImport13)},
{path: "/org/:companyName/controller/:controllerName/ssids/:id/remove",element: React.createElement(PagefilesImport14)},
{path: "/org/:companyName/controller/:controllerName/ssids/create",element: React.createElement(PagefilesImport12)},
{path: "/org/:companyName/controller/:controllerName/vlans/:vlanName",element: React.createElement(PagefilesImport4)},
{path: "/org/:companyName/controller/:controllerName/vlans/:vlanName/static/:macAddress/edit",element: React.createElement(PagefilesImport16)},
{path: "/org/:companyName/controller/:controllerName/vlans/:vlanName/static/:macAddress/remove",element: React.createElement(PagefilesImport17)},
{path: "/org/:companyName/controller/:controllerName/vlans/:vlanName/static/create",element: React.createElement(PagefilesImport15)},
{path: "/org/:companyName/controller/:controllerName/vpn/:sid",element: React.createElement(PagefilesImport6)},
{path: "/org/:companyName/controller/:controllerName/vpn/:sid/config",element: React.createElement(PagefilesImport5)},
{path: "/org/:companyName/controller/:controllerName/vpn/onboarding/add",element: React.createElement(PagefilesImport18)},
];

export default routes;