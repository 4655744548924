import type { Address4 } from 'ip-address';
import { safeParseAddress4 } from '@meterup/common';
import { freeze, immerable } from 'immer';

import type { ct } from '../schema/config';

export class MeterV1NetworkVLANStaticClient {
  [immerable] = true;

  constructor(
    public macAddress: string,
    public ipAddress: string,
    public name: string,
    public json: ct.MeterV1NetworkVLANStaticClient,
  ) {}

  static fromJSON(
    vlan: string,
    macAddress: string,
    json: ct.MeterV1NetworkVLANStaticClient,
  ): MeterV1NetworkVLANStaticClient {
    return freeze(
      new MeterV1NetworkVLANStaticClient(
        macAddress,
        json['ip-address'] ?? '',
        json.name ?? '',
        json,
      ),
      true,
    );
  }

  static fromProps(
    macAddress: string,
    ipAddress: string,
    name: string,
    json: ct.MeterV1NetworkVLANStaticClient = {},
  ): MeterV1NetworkVLANStaticClient {
    return freeze(new MeterV1NetworkVLANStaticClient(macAddress, ipAddress, name, json), true);
  }

  get ip(): Address4 | null {
    return safeParseAddress4(this.ipAddress);
  }

  toJSON(): ct.MeterV1NetworkVLANStaticClient {
    return {
      ...this.json,
      'ip-address': this.ipAddress,
      name: this.name,
    };
  }
}
