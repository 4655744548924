import { observer } from 'mobx-react-lite';
import ReactDOM from 'react-dom';

import { VisualState } from '../core/ui';
import { useCommand } from './Root';

interface Props {
  children: React.ReactNode;
}

function InternalPortal(props: Props) {
  const container = globalThis?.document?.body;
  return container ? ReactDOM.createPortal(<div>{props.children}</div>, container) : null;
}

export const Portal = observer((props: Props) => {
  const ctx = useCommand();

  if (ctx.state.ui.visualState === VisualState.Hidden) {
    return null;
  }

  return <InternalPortal {...props} />;
});
