import type { MeterControllerConfig, MeterV2WirelessServiceSet } from '@meterup/config';
import produce from 'immer';
import { useMutation, useQueryClient } from 'react-query';

import type { ValidServiceSetData } from '../../../../../validations/validServiceSetData';
import { saveConfigDiff } from '../../../../../api/saveConfigDiff';
import { produceConfigWithNewServiceSet } from './produceConfigWithNewServiceSet';

export function useUpdateServiceSetMutation(
  controller: string,
  configModel: MeterControllerConfig,
  serviceSetModel: MeterV2WirelessServiceSet,
  onSuccess?: () => void,
  onError?: (error: Error) => void,
) {
  const queryClient = useQueryClient();

  return useMutation(
    async (values: ValidServiceSetData) => {
      const updatedModel = produceConfigWithNewServiceSet(configModel, serviceSetModel, values);

      await saveConfigDiff(controller, updatedModel.toJSON(), configModel.toJSON());
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['controller', controller, 'config']);
        onSuccess?.();
      },
      onError,
    },
  );
}

export function useRemoveServiceSetMutation(
  controller: string,
  configModel: MeterControllerConfig,
  serviceSetId: string,
  onSuccess?: () => void,
) {
  const queryClient = useQueryClient();

  return useMutation(
    async () => {
      const updatedModel = produce(configModel, (draft) => {
        draft.removeServiceSetById(serviceSetId);
      });

      await saveConfigDiff(controller, updatedModel.toJSON(), configModel.toJSON());
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['controller', controller, 'config']);
        onSuccess?.();
      },
    },
  );
}
