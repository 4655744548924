import { Box, Icon, ShortcutKey, space, Text } from '@meterup/metric';
import { styled } from '@meterup/metric/src/stitches.config';
import { observer } from 'mobx-react-lite';
import React from 'react';

import type { ScoredNode } from '../core/filter';
import { ROOT_ID } from '../core';
import { useCommandItemEvents } from './hooks/useCommandItemEvents';
import { useCommand } from './Root';

const StyledLabel = styled('div', {
  display: 'flex',
  padding: '$8',
  color: '$gray-400',
});

const StyledNode = styled('div', {
  padding: '$12 $16',
  borderRadius: '$8',
  display: 'flex',
  gap: '$8',
  alignItems: 'center',
  cursor: 'pointer',

  '[data-ancestor]': {
    display: 'flex',
    alignItems: 'center',
    fontSize: '$13',
    fontWeight: '$medium',
    flexShrink: 0,
  },

  '*:not([data-shortcut])': {
    truncate: true,
  },

  variants: {
    active: {
      true: {
        background: '$brand-50',
        color: '$brand-800',
        '[data-ancestor]': {
          color: '$brand-600',
        },
      },
    },
  },
});

const StyledShortcuts = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '$2',
});

const isMac = window.navigator.platform === 'MacIntel';

const getFormattedKey = (key: string) => {
  switch (key) {
    case '$mod': {
      if (isMac) {
        return '⌘';
      }
      return 'Ctrl';
    }
    default: {
      return key;
    }
  }
};

const RenderShortcut = observer((props: { shortcut: string }) => {
  const shortcuts = React.useMemo(() => props.shortcut.split(/[+, ]+/), [props.shortcut]);

  return (
    <StyledShortcuts>
      {shortcuts.map((s) => (
        <ShortcutKey key={s}>{getFormattedKey(s)}</ShortcutKey>
      ))}
    </StyledShortcuts>
  );
});

const RenderNode = observer((props: { scoredNode: ScoredNode; index: number }) => {
  const rSelf = React.useRef<HTMLDivElement>(null);

  const { state } = useCommand();

  const getEventProps = useCommandItemEvents();

  const { node } = props.scoredNode;

  const active = state.ui.activeNode?.node === node;

  const block =
    props.index === 0 ||
    // Only apply `end` if the previous item is a group name
    (props.index === 1 && typeof state.filter.ordered[0] === 'string')
      ? 'end'
      : 'nearest';

  React.useEffect(() => {
    const el = rSelf.current;
    if (active && el) {
      el.scrollIntoView({
        behavior: 'auto',
        block,
      });
    }
  }, [active, props.index, block]);

  if (node === state.currentRoot) {
    return null;
  }

  // TODO: If we allow custom rendering via `display`, we probably don't need to render ancestors.
  const renderAncestors = () => {
    const index = node.ancestors.findIndex((n) => n === state.currentRoot);
    const ancestors = node.ancestors.slice(index + 1).filter((a) => a.id !== ROOT_ID);

    const str = ancestors.map((a) => a.display).join(' → ');

    if (!str) {
      return null;
    }

    return <Box data-ancestor>{str}</Box>;
  };

  return (
    <StyledNode ref={rSelf} active={active} {...getEventProps(node, props.index)}>
      {node.icon ? <Icon icon={node.icon} size={14} /> : null}
      {renderAncestors()}
      {typeof node.display === 'string' ? (
        <Text size={13} weight="medium">
          {node.display}
        </Text>
      ) : (
        node.display
      )}
      {node.shortcut ? (
        <Box style={{ marginLeft: 'auto' }} data-shortcut>
          <RenderShortcut shortcut={node.shortcut} />
        </Box>
      ) : null}
    </StyledNode>
  );
});

const Result = observer((props: { result: string | ScoredNode; index: number }) => {
  if (typeof props.result === 'string') {
    return (
      <StyledLabel>
        <Text size={11} transform="uppercase" weight="medium">
          {props.result}
        </Text>
      </StyledLabel>
    );
  }

  return <RenderNode scoredNode={props.result} index={props.index} />;
});

function useAnimator() {
  const rPrevHeight = React.useRef<number | null>(null);
  const outer = React.useRef<HTMLDivElement | null>(null);
  const inner = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    const outerEl = outer.current;
    const innerEl = inner.current;

    if (outerEl && innerEl) {
      const obs = new ResizeObserver((entries) => {
        const entry = entries[0];

        if (entry.contentBoxSize) {
          const contentBoxSize = entry.contentBoxSize[0];

          if (rPrevHeight.current !== null) {
            outerEl.animate(
              [
                {
                  height: `${rPrevHeight.current}px`,
                },
                {
                  height: `${contentBoxSize.blockSize}px`,
                },
              ],
              {
                duration: 75,
              },
            );
          }

          rPrevHeight.current = contentBoxSize.blockSize;
        }
      });

      obs.observe(innerEl);
      return () => {
        obs.unobserve(innerEl);
      };
    }

    return undefined;
  }, []);

  return { outer, inner };
}

export const Results = observer(() => {
  const { state } = useCommand();

  const results = state.filter.ordered;

  const { outer, inner } = useAnimator();

  return (
    <Box
      ref={outer}
      style={{
        overflow: 'hidden',
      }}
    >
      <Box ref={inner}>
        {results.length > 0 ? (
          <Box
            padding={{
              x: space(12),
              bottom: space(12),
            }}
            style={{
              maxHeight: 300,
              overflow: 'auto',
              scrollPaddingBottom: 12,
            }}
          >
            {results.map((result, index) => (
              <Result
                key={typeof result === 'string' ? result : result.node.id}
                result={result}
                // TODO: We'll need to virtualize this list.
                index={index}
              />
            ))}
          </Box>
        ) : null}
      </Box>
    </Box>
  );
});
