import { HStack } from '@meterup/metric';
import React from 'react';

const Stars = () => (
  <svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.58064 4C9.58064 3.44772 10.0284 3 10.5806 3C11.1329 3 11.5806 3.44772 11.5806 4V8.26795L15.2768 6.13397C15.7551 5.85783 16.3667 6.0217 16.6428 6.5C16.919 6.97829 16.7551 7.58988 16.2768 7.86602L12.5807 9.99999L16.2768 12.134C16.7551 12.4101 16.919 13.0217 16.6428 13.5C16.3667 13.9783 15.7551 14.1421 15.2768 13.866L11.5806 11.732V16C11.5806 16.5523 11.1329 17 10.5806 17C10.0284 17 9.58064 16.5523 9.58064 16V11.732L5.88449 13.866C5.40619 14.1422 4.7946 13.9783 4.51846 13.5C4.24232 13.0217 4.40619 12.4101 4.88449 12.134L8.58065 9.99999L4.88449 7.86601C4.40619 7.58986 4.24232 6.97827 4.51846 6.49998C4.7946 6.02169 5.40619 5.85781 5.88449 6.13396L9.58064 8.26793V4Z"
      fill="currentcolor"
    />
  </svg>
);

export const HiddenPassword = () => (
  <HStack>
    {[...Array(7)].map((d, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <Stars key={i} />
    ))}
  </HStack>
);
