import { expectDefinedOrThrow, isDefined, ResourceNotFoundError } from '@meterup/common';
import { MeterControllerConfig } from '@meterup/config';
import { useQuery } from 'react-query';

import { fetchControllerConfig } from '../api/api';

export const useControllerConfig = (controller: string) => {
  const model = useQuery(
    ['controller', controller, 'config'],
    async () => {
      const response = await fetchControllerConfig(controller);
      return isDefined(response) ? MeterControllerConfig.fromJSON(response.config as any) : null;
    },
    {
      suspense: true,
    },
  ).data;

  expectDefinedOrThrow(model, new ResourceNotFoundError(`Controller ${controller} not found`));

  return model;
};

export const useControllerConfigOrNull = (controller: string) =>
  useQuery(
    ['controller', controller, 'config'],
    async () => {
      const response = await fetchControllerConfig(controller);
      return isDefined(response) ? MeterControllerConfig.fromJSON(response.config as any) : null;
    },
    {
      suspense: true,
    },
  ).data;
