import { Box } from '@meterup/metric';
import { colors, darkThemeSelector, shadows, styled } from '@meterup/metric/src/stitches.config';
import { observer } from 'mobx-react-lite';

import { VisualState } from '../core';
import { useCommand } from './Root';

interface Props {
  children: React.ReactNode;
}

// FIXME: Using `styled` without referencing an atto component
// will result in strange css behavior; ie `reset.css` overrides
// local styles
const Box2 = styled('div', {});

export const Content = observer((props: Props) => {
  const { state } = useCommand();

  if (state.ui.visualState === VisualState.Hidden) {
    return null;
  }

  return (
    <Box
      style={{
        position: 'fixed',
        inset: 0,
        pointerEvents: 'none',
        zIndex: 3,
      }}
    >
      <Box
        style={{
          padding: '$20',
          position: 'absolute',
          top: '15%',
          left: '50%',
          width: '100%',
          maxWidth: '650px',
          transform: 'translateX(-50%)',
        }}
      >
        <Box2
          css={{
            boxSizing: 'border-box',
            borderRadius: '12px',
            background: '$white',
            pointerEvents: 'initial',
            boxShadow: shadows.modalLight,
            [darkThemeSelector]: {
              boxShadow: shadows.modalDark,
              background: colors['gray-800'],
            },
          }}
        >
          {props.children}
        </Box2>
      </Box>
    </Box>
  );
});
