import { colors } from '@meterup/common';
import { BodyMono2 } from '@meterup/metric';

import { shadows, styled } from '../stitches';
import { CopyToClipboardButtonMinimal } from './CopyToClipboardButton';

const Container = styled('div', {
  hStack: '$8',
  padding: '$8 $12',
  borderRadius: '$8',
  backgroundColor: colors['gray-50'],
  alignItems: 'flex-start',
  boxShadow: shadows.controlTertiaryInitialLight,
});

const CodeBlock = styled('pre', BodyMono2, {
  wordBreak: 'break-all',
  whiteSpace: 'pre-wrap',
  display: 'block',
  color: colors['gray-600'],
});

export const CopyableCodeBlock = ({ value }: { value: string }) => (
  <Container>
    <CodeBlock>{value}</CodeBlock>
    <div style={{ flexShrink: 0 }}>
      <CopyToClipboardButtonMinimal text={value} />
    </div>
  </Container>
);
